import React from "react";
import Page from "components/Core/Page";
import Title from "components/Core/Title";
import Content from "components/Core/Content";
import ProductFileUploadModal from "./ProductFileUploadModal";
import SearchBox from "components/Core/SearchBox";
import ProductFilter from "./ProductFilter";
import ProductDetails from "./ProductDetails";
import ProductList from "./ProductList";
import ProductEditModal from "./ProductEditModal";
import ProductVenusModal from "./ProductVenusModal";

class Products extends React.Component {
  componentDidMount = () => {
    this.props.fetchOnLoad();
  };

  openUploadModal = (product, uploadType) => {
    this.uploadModal.open(product, uploadType);
  };

  onSubmit = (file, product, uploadType) => {
    if (uploadType === "blattschnitt")
      return this.props.uploadGeoJson(product.id, file);
    if (uploadType === "gpx")
      return this.props.uploadGpx(product.id, file);
    return Promise.resolve();
  };

  onEditProduct = (product, updateModel) => {
    return this.props.editProduct(product.id, updateModel);
  };

  onEditPublicationPlanner = (product, updateModel) => {
    return this.props.editProduct(product.id, updateModel);
  };

  onUpdateProduct = (updateModel) => {
    this.detailsOverlay.onUpdateProduct(updateModel);
  }

  onSubmitSearch = (query) => {
    this.props.search({
      ...this.props.filter,
      query,
    });
  };

  onSearch = (filter) => {
    this.props.search({
      ...this.props.filter,
      ...filter,
    });
  };

  onClickProduct = (product) => {
    this.detailsOverlay.open(product);
  };

  openEditProductModal = (product) => {
    this.editModal.open(product)
  }

  openVenusModal = (product) => {
    this.venusModal.open(product)
  }


  render() {
    return (
      <Page>
        <Title
          renderSearch={() => (
            <SearchBox
              query={this.props.filter.query}
              placeholder="Produkte durchsuchen..."
              onSubmit={this.onSubmitSearch}
              autoFocus
              isFetching={this.props.isLoading}
            />
          )}
        >
          Produkte
        </Title>
        <Content>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ProductFilter 
            filter={this.props.filter} 
            onSearch={this.onSearch} 
            onResetFilter={this.props.onResetFilter}
            user={this.props.user}/>
            <div style={{ paddingLeft: '320px', flex: 1 }}>
              <ProductList
                owner={this.props.user.company}
                user={this.props.user}
                filter={this.props.filter}
                onCardClick={this.onClickProduct}
                openEditProductModal={this.openEditProductModal}
                openUploadModal={this.openUploadModal}
                paginate={this.props.paginate}
                pagination={this.props.pagination}
                isLoading={this.props.isLoading}
                products={this.props.products} />
              </div>
          </div>
          <ProductEditModal
            onSubmit={this.onEditProduct}
            ref={(c) => (this.editModal = c)} />
          <ProductVenusModal
            onSubmit={this.onEditPublicationPlanner}
            onUpdateProduct={this.onUpdateProduct}
            ref={(c) => (this.venusModal = c)} />
          <ProductFileUploadModal
            onSubmit={this.onSubmit}
            ref={(c) => (this.uploadModal = c)}
          />
        </Content>
        <ProductDetails
          ref={(c) => (this.detailsOverlay = c)}
          fetchVenusChannels={this.props.fetchVenusChannels}
          openUploadModal={this.openUploadModal}
          openVenusModal={this.openVenusModal}
        />
      </Page>
    );
  }
}

export default Products;
