/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { Component } from 'react';
import { Button } from 'react-materialize';
import Modal from 'react-modal';
import styles from './UserDeleteModal.module.css';
import { toast } from 'react-toastify';
import Spinner from 'components/Core/Spinner';
import UserApi from 'api/UserApi';

class UserDeleteModal extends Component {

  state = {
    open: false,
    isSubmitting: false,
    guard: '',
    user: {},
  }

  open = (user) => {
    this.setState({
      open: true,
      user,
    })
  }

  close = () => {
    // Don't close the modal, while saving
    if (this.state.isSubmitting) return false;

    this.setState({
      open: false
    }, () => {
      this.setState({
        user: {},
        guard: ''
      })
    })
  }

  submit = async () => {
    this.setState({ isSubmitting: true });

    try {
      await UserApi.deleteUser(this.state.user.ssoId, this.props.user.company)
      setTimeout(() => {
        toast('Benutzer gelöscht', { type: 'success', autoClose: 2000 })
        this.setState({ isSubmitting: false });
        if (this.props.onUserDeleted) {
          this.props.onUserDeleted(this.state.user.ssoId)
        }
        this.close();
      }, 5000)

    } catch (e) {
      this.setState({ isSubmitting: false });
      toast(e.message || 'Unerwarteter Fehler', { type: 'error' })
    }

  }

  onChange = (e) => {
    const { value } = e.currentTarget;
    this.setState({
      guard: value
    })
  }

  render() {
    const { open, user, guard, isSubmitting } = this.state;
    if (!user) {
      return null;
    }

    const emStyle = {
      fontStyle: 'normal',
      background: '#d2d7dd',
      fontFamily: 'monospace',
      padding: '0 4px',
      borderRadius: '2px'
    }

    return (
      <Modal
        isOpen={open}
        className={`${styles.modal} modal`}
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={!isSubmitting}
        bodyClassName="modal-open"
        contentLabel="Benutzer löschen">
        <>
          <div className={styles.modalHeader}>
            <div className={styles.content}>
              <div></div>
              Benutzer löschen
            </div>
          </div>
          <div className="modal-content">
            <div>
              <p>Möchtest du diesen Benutzer wirklich löschen? <b>Dabei werden alle Userdaten unwiderruflich gelöscht!</b></p>
              <p style={{ margin: '16px 0 8px' }}>Bitte gib zur Bestätigung den Text <em style={emStyle}>löschen</em> ein.</p>
              <input type="text" className={styles.textInput} value={guard} onChange={this.onChange} />
            </div>
            {
              isSubmitting && (
                <div className={styles.overlay}>
                  <Spinner size="large" className={styles.overlaySpinner} />
                </div>
              )
            }

          </div>
          <div className={styles.modalFooter}>
            <div className={styles.content}>
              <Button waves="light" onClick={this.submit} className={styles.submitButton} disabled={isSubmitting || guard !== 'löschen'}>Benutzer löschen</Button>
              &nbsp;&nbsp;
              <Button waves="light" flat onClick={this.close} disabled={isSubmitting}>Abbrechen</Button>
            </div>
          </div>
        </>


      </Modal >
    )
  }
}

export default UserDeleteModal;