import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/Core/Security/PrivateRoute';
import PublicRoute from 'components/Core/Security/PublicRoute';
import LoginPage from './LoginPage';
import SupportPage from './SupportPage';
import ProductPage from './ProductPage';
import RegionPage from './destination/RegionPage';
import RegionsPage from './destination/RegionsPage';
import ProductTypesPage from './ProductTypesPage';
import ApiDocsPage from './ApiDocsPage';


export default ({ authenticated, user }) => (
    <Switch>
        <PublicRoute authenticated={authenticated} path='/login' exact component={LoginPage} />
        <PrivateRoute authenticated={authenticated} path='/user' user={user} access="support" exact component={SupportPage} />
        <PrivateRoute authenticated={authenticated} path='/product' user={user} access="products" exact component={ProductPage} />
        <PrivateRoute authenticated={authenticated} path='/product-types' user={user} access="products" exact component={ProductTypesPage} />
        <PrivateRoute authenticated={authenticated} path="/region" user={user} access="regions" component={RegionsPage} exact />
        <PrivateRoute authenticated={authenticated} path="/region/:regionId" user={user} access="regions" component={RegionPage} />
        <PrivateRoute authenticated={authenticated} path="/apidocs" user={user} access="regions" component={ApiDocsPage} />
        {
            user && user.roles.support && (
                <Redirect from="/" to="/user" />
            )
        }
        {
            user && user.roles.products && (
                <Redirect from="/" to="/product" />
            )
        }
        {
            !user && (
                <Redirect from="/" to="/login" />
            )
        }
    </Switch>
)
