import {
    FETCH_SUBREGION,
    FETCH_SUBREGION_SUCCESS,
    RESET_SUBREGION,
    UPDATE_SUBREGION,
    UPDATE_SUBREGION_SUCCESS,
    UPDATE_SUBREGION_FAILED,
    ADD_PRODUCT_TO_SUBREGION,
    ADD_PRODUCT_TO_SUBREGION_SUCCESS,
    ADD_PRODUCT_TO_SUBREGION_FAILED,
    REMOVE_PRODUCT_FROM_SUBREGION,
    REMOVE_PRODUCT_FROM_SUBREGION_SUCCESS,
    REMOVE_PRODUCT_FROM_SUBREGION_FAILED,
} from './actions';
import { LOGOUT } from 'redux/app/actions';

const _initialState = {

}

const subRegion = (state = _initialState, action) => {
    switch (action.type) {
        case FETCH_SUBREGION:
            return Object.assign({}, state, {
                isLoading: true
            })
        case FETCH_SUBREGION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                ...action.payload
            })
        case UPDATE_SUBREGION:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: true
            });
        case UPDATE_SUBREGION_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false,
                subRegion: {
                    ...state.subRegion,
                    ...action.payload
                }
            });
        case UPDATE_SUBREGION_FAILED:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false
            });
        case REMOVE_PRODUCT_FROM_SUBREGION:
        case ADD_PRODUCT_TO_SUBREGION:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: true
            });
        case ADD_PRODUCT_TO_SUBREGION_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false,
                products: [
                    action.payload.product,
                    ...(state.products || [])
                ]
            });
        case REMOVE_PRODUCT_FROM_SUBREGION_FAILED:
        case ADD_PRODUCT_TO_SUBREGION_FAILED:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false
            });
        case REMOVE_PRODUCT_FROM_SUBREGION_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false,
                products: (state.products || []).filter(product => product.product_id !== action.payload.product.product_id)
            });
        case RESET_SUBREGION:
        case LOGOUT:
            return _initialState;
        default:
            return state;
    }
}

export default subRegion;