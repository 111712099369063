import Quill from 'quill';
const Embed = Quill.import('blots/block/embed');


class Readmore extends Embed {
    static create(value) {
        let node = super.create(value);
        return node;
    }
}

Readmore.blotName = 'readmore'; //now you can use .ql-hr classname in your toolbar
Readmore.className = 'readmore';
Readmore.tagName = 'hr';

Quill.register({
    'formats/readmore': Readmore
});

export default Readmore;