import React from 'react';
import styles from './UserCard.module.css';
import WarningCircleIcon from 'mdi-react/WarningCircleIcon';
import { ProfileImage } from "./ProfileImage"
import { Badges, Badge, ListCard } from 'components/Core/ListCard';
import moment from 'moment';

class UserCard extends React.Component {

  today = new Date();

  onClick = () => {
    console.log('onlcik')
    this.props.onClick(this.props.user)
  }

  render() {
    const { user } = this.props;

    return (
      <ListCard onClick={this.onClick}>

        <div className={styles.left}>
          <div className={styles.coverImage}>
            <ProfileImage url={user.image} />
          </div>

        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  {user.userName}
                </div>
              </div>
              <Badges className={styles.badges}>
                {
                  user.renewalFailed && (
                    <Badge type="warning">
                      <WarningCircleIcon />
                      <div>Renewal</div>
                    </Badge>
                  )
                }
              </Badges>
            </div>
          </div>
          <div className={styles.rightBottom}>
            <div className={`${styles.emailElement}`}>
              <div className={styles.label}>
                E-Mail
              </div>
              <div className={styles.valueTwoItems}>
                {user.email}
              </div>
            </div>
            <div className={`${styles.isbnElement}`}>
              <div className={styles.label}>
                Registriert am
              </div>
              <div className={styles.valueTwoItems}>
                {moment(user.created).format("DD.MM.YYYY")}
              </div>
            </div>
            <div className={`${styles.isbnElement}`}>
              <div className={styles.label}>
                Via
              </div>
              <div className={styles.valueTwoItems}>
                {user.registeredVia}
              </div>
            </div>
            <div className={`${styles.isbnElement}`}>
              <div className={styles.label}>
                Status
              </div>
              <div className={styles.valueTwoItems}>
                {
                  user.isPro ? 'PRO User' : 'Basic User'
                }
              </div>
            </div>
            {
              user.isPro && (
                <div className={`${styles.isbnElement}`}>
                  <div className={styles.label}>
                    PRO bis
                  </div>
                  <div className={styles.valueTwoItems}>
                    {
                      user.isPro ? moment(user.premiumTill).format("DD.MM.YYYY") : 'Basic User'
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </ListCard>
    )
  }
}

export default UserCard;