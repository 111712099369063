/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Box from "components/Core/Box";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableCell,
} from "components/Core/Table";
import "./ProductTable.css";
import AmazonLogo from "./amazon-icon.svg";
import CartOutlineIcon from "mdi-react/CartOutlineIcon";
import PhoneCheckIcon from "mdi-react/SmartphoneIcon";
import UnpublishedIcon from "mdi-react/CalendarRemoveOutlineIcon";
import PublishedIcon from "mdi-react/CalendarCheckIcon";
import LockOpenVariantOutlineIcon from "mdi-react/LockOpenVariantOutlineIcon";
import ProductFileUploadLink from "./ProductFileUploadLink";
import SearchBox from "components/Core/SearchBox";
import ProductImage from "../ProductImage/ProductImage";
import moment from "moment";
import ReactPaginate from "react-paginate";

class ProductTable extends React.Component {
  today = new Date();

  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter || {
        keyword: "",
        includeUnpublished: true,
        withoutGeoData: false,
        withoutGpxUrl: false,
      },
    };
  }

  onChangeFilter = (e) => {
    const { name, checked } = e.currentTarget;
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: checked,
        },
      },
      () => this.props.onSearch(this.state.filter)
    );
  };

  onSubmitSearch = (keyword) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          keyword,
        },
      },
      () => {
        this.props.onSearch(this.state.filter);
      }
    );
  };

  renderPublishedLabel = (product) => {
    const publicationDate = new Date(product.product_publication_date);
    if (product.product_publication_date && publicationDate <= this.today) {
      return (
        <small className="unpublished">
          <PublishedIcon />
          <span>Veröffentlicht</span>
        </small>
      );
    }
    return (
      <small className="unpublished">
        <UnpublishedIcon />
        <span>Nicht veröffentlicht</span>
      </small>
    );
  };

  renderLabel = (product, key, title, icon) => {
    if (product[key]) {
      return (
        <small className="unpublished">
          {icon}
          <span>{title}</span>
        </small>
      );
    }
    return null;
  };

  paginate = ({ selected }) => {
    this.props.paginate(selected);
  };

  renderTableRows() {
    const {
      products,
      hideFiles,
      hideLinks,
      placeholder,
      onRowClick,
      isLoading,
    } = this.props;
    if (products && products.length) {
      return products.map((el) => (
        <TableRow
          key={el.product_id}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => (onRowClick && !isLoading ? onRowClick(el) : null)}
        >
          <TableCell
            width="44px"
            style={{ paddingRight: "0px", verticalAlign: "top" }}
          >
            {el.product_cover_url && (
              <a href={el.product_cover_url} target="_blank">
                <ProductImage url={el.product_cover_url} />
              </a>
            )}
            {!el.product_cover_url && (
              <ProductImage url={el.product_cover_url} />
            )}
          </TableCell>
          <TableCell style={{ verticalAlign: "top" }}>
            {el.product_key}
            <br />
            <small>{el.product_type_description}</small>
          </TableCell>
          <TableCell
            style={{
              maxWidth: "300px",
              verticalAlign: "top",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {el.product_name}
            <br />
            <small>{el.product_isbn || "Keine ISBN"} • </small>
            <small>
              {el.product_publication_date
                ? moment(el.product_publication_date).format("DD.MM.YYYY")
                : null}
            </small>
            <div className="status-labels">
              {this.renderPublishedLabel(el)}
              {this.renderLabel(
                el,
                "visibleInApp",
                "In App sichtbar",
                <PhoneCheckIcon />
              )}
              {this.renderLabel(
                el,
                "unlockable",
                "Freischaltbar",
                <LockOpenVariantOutlineIcon />
              )}
            </div>
          </TableCell>
          {!hideLinks && (
            <TableCell style={{ verticalAlign: "top" }}>
              {el.amazon_link && (
                <a
                  className="shop-link"
                  href={el.amazon_link}
                  target="_blank"
                  title="Amazon"
                  rel="noopener noreferrer"
                >
                  <img src={AmazonLogo} alt="Amazon" />
                </a>
              )}
              {el.shop_link && (
                <a
                  className="shop-link"
                  href={el.shop_link}
                  target="_blank"
                  title="KOMPASS Shop"
                  rel="noopener noreferrer"
                >
                  <CartOutlineIcon />
                </a>
              )}
            </TableCell>
          )}
          {!hideFiles && (
            <TableCell style={{ verticalAlign: "top" }}>
              <div style={{ display: "flex" }}>
                <ProductFileUploadLink
                  title="Blattschnitt"
                  tooltip="Blattschnitt hochladen"
                  hasValue={el.geo_data}
                  onClick={() => this.props.openUploadModal(el, "blattschnitt")}
                />
                &nbsp;&nbsp;
                <ProductFileUploadLink
                  title="Tracks"
                  tooltip="GPX-Tracks hochladen"
                  hasValue={el.product_gpx_url}
                  onClick={() => this.props.openUploadModal(el, "gpx")}
                />
              </div>
            </TableCell>
          )}
          {this.props.renderRowActions && (
            <TableCell width="1px" style={{ verticalAlign: "top" }}>
              {this.props.renderRowActions(el)}
            </TableCell>
          )}
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell colSpan="6" empty>
          {products
            ? placeholder || "Keine Produkte gefunden."
            : "Nutze das Suchfeld und die weiteren Filtermöglichkeiten, um die Produkte zu durchsuchen."}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const {
      filter: { keyword },
    } = this.state;
    const {
      isLoading,
      pagination,
      hideFiles,
      hideLinks,
      selectable,
      products,
      withSearch,
      renderActions,
      renderRowActions,
      title,
    } = this.props;
    return (
      <Box
        title={title}
        withTable
        renderActions={renderActions}
        renderSearch={
          withSearch
            ? () => {
                return (
                  <SearchBox
                    query={keyword}
                    placeholder="Produkte durchsuchen..."
                    onSubmit={this.onSubmitSearch}
                    autoFocus
                    isFetching={isLoading}
                  />
                );
              }
            : null
        }
      >
        <Table className="product-table" selectable={selectable}>
          <TableHeader>
            <TableHeaderItem width="44px" style={{ paddingRight: "0px" }} />
            <TableHeaderItem width="70px">
              Verlagsnummer
              <br />
              <small>Produkttyp</small>
            </TableHeaderItem>
            <TableHeaderItem>
              Name
              <br />
              <small>ISBN, Veröffentlichung</small>
            </TableHeaderItem>
            {!hideLinks && <TableHeaderItem width="70px">Shop</TableHeaderItem>}
            {!hideFiles && (
              <TableHeaderItem width="180px">Dateien</TableHeaderItem>
            )}
            {renderRowActions && (
              <TableHeaderItem width="1px"></TableHeaderItem>
            )}
          </TableHeader>
          <tbody>{this.renderTableRows()}</tbody>
          {products && products.length > 0 && pagination && pagination.resultSize > 0 && (
            <tfoot>
              <TableRow>
                <TableCell colSpan={6} style={{ padding: "16px" }}>
                  <div style={{ display: 'flex'}}>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="&#8594;"
                      onPageChange={this.paginate}
                      pageRangeDisplayed={5}
                      pageCount={pagination.totalPages}
                      previousLabel="&#8592;"
                      className="pagination"
                      renderOnZeroPageCount={null}
                    />
                    <div className="result-size">
                      {
                        pagination.resultSize > 500 ? 'Mehr als 500 Produkte gefunden' : `${pagination.resultSize < 2 ? 'Ein' : `${pagination.resultSize}`} Produkt${pagination.resultSize < 2 ? '' : 'e'} gefunden`
                      }
                    </div>
                  </div>
                  
                  
                </TableCell>
              </TableRow>
            </tfoot>
          )}
        </Table>
      </Box>
    );
  }
}

export default ProductTable;
