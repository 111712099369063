import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCountries } from "redux/destination/country/actions";
import SubRegionPage from "./SubRegionPage";
import RegionDetails from "components/Destinations/RegionDetails";
import {
  fetchRegion,
  updateRegion,
} from "redux/destination/region/details/actions";

class RegionPage extends Component {
  state = {
    tab: "general",
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.fetchRegion(this.props.match.params.regionId);
    this.props.fetchCountries();
  };

  onChangeTab = (tab) => {
    this.setState({
      tab,
    });
  };

  render() {
    const { region, countries, subRegions, isLoading, user } = this.props;

    const { tab } = this.state;
    return (
      <Switch>
        <Route
          path="/region/:regionId/:subRegionId"
          exact
          access="regions"
          user={user}
          component={SubRegionPage}
        />

        <Route
          path="/region/:regionId"
          exact
          access="regions"
          user={user}
          render={(props) => {
            return (
              <RegionDetails
                region={region}
                countries={countries}
                subRegions={subRegions}
                tab={tab}
                onChangeTab={this.onChangeTab}
                onUpdateRegion={this.props.onUpdateRegion}
                isLoading={isLoading}
                editMode
                {...props}
              />
            );
          }}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.destination.region.details.isLoading,
  region: state.destination.region.details.region || {},
  subRegions: state.destination.region.details.subRegions,
  countries: state.destination.country.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountries()),
  fetchRegion: (id) => dispatch(fetchRegion(id)),
  onUpdateRegion: (regionId, model) => dispatch(updateRegion(regionId, model)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionPage);
