import VenusApi from "api/VenusApi";

export const RESET =
  "kompass/crm/venus/RESET";
export const FETCH_CHANNELS_STARTED =
  "kompass/crm/venus/FETCH_CHANNELS_STARTED";
export const FETCH_CHANNELS_FAILED =
  "kompass/crm/venus/FETCH_CHANNELS_FAILED";
export const FETCH_CHANNELS_FINISHED =
  "kompass/crm/venus/FETCH_CHANNELS_FINISHED";

export const fetchChannels = (forceRefetch) => {
  return (dispatch, getState) => {
    if (!forceRefetch) {
      const { venus } = getState()
      if (venus.channels && venus.channels.length > 0) {
        return Promise.resolve()
      }
    }
    dispatch(fetchChannelsStarted());
    
    return VenusApi.findChannels()
      .then((result) => {
        return dispatch(fetchChannelsFinished(result));
      })
      .catch((err) => {
        if (!VenusApi.isCanceled(err)) {
          return dispatch(fetchChannelsFailed());
        }
      });
  };
};

export const fetchChannelsStarted = () => ({
  type: FETCH_CHANNELS_STARTED
});

export const fetchChannelsFinished = (result) => ({
  type: FETCH_CHANNELS_FINISHED,
  payload: {
    ...result,
  },
});

export const fetchChannelsFailed = () => ({
  type: FETCH_CHANNELS_FAILED,
});

export const resetChannels = () => ({
  type: RESET,
});
