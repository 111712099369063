import AxiosRequest from "./AxiosRequest";

class VenusApi extends AxiosRequest {
  findChannels = async () => {
    if (this.findChannelsAbort) {
      this.findChannelsAbort.abort();
    }
    this.findChannelsAbort = new AbortController();
    const result = await this.get(`/venus/pp/channel`, {
      signal: this.findChannelsAbort.signal,
    });
    return result;
  };

  findEditions = async (channelId) => {
    if (this.findEditionsAbort) {
      this.findEditionsAbort.abort();
    }
    this.findEditionsAbort = new AbortController();
    const result = await this.get(`/venus/pp/channel/${channelId}`, {
      signal: this.findEditionsAbort.signal,
    });
    return result;
  };
}

export default new VenusApi();
