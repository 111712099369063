import config from "lib/config";
import AxiosRequest from "./AxiosRequest";

class UserApi extends AxiosRequest {
  find = async (filter, page, company) => {
    if (this.findAbort) {
      this.findAbort.abort();
    }
    this.findAbort = new AbortController();
    const result = await this.get(`/manage/user`, {
      baseURL: config.coreApiServicelUrl,
      params: {
        owner: company,
        ...filter,
        page,
      },
      signal: this.findAbort.signal,
    });
    return result;
  };

  deleteUser = async (ssoId, owner) => {
    const result = await this.delete(`/manage/account/${ssoId}`, {
      params: {
        owner
      },
      baseURL: config.coreApiServicelUrl,
    });
    return result;
  }

  updateUser = async (ssoId, model) => {
    const result = await this.put(`/manage/user/${ssoId}`, {
      baseURL: config.coreApiServicelUrl,
      data: model,
    });
    return result;
  }

  addDays = async (ssoId, days, owner) => {
    const result = await this.put(`/manage/account/${ssoId}/subscription`, {
      data: {
        days,
        client: owner
      },
      baseURL: config.coreApiServicelUrl,
    });
    return result;
  }

  findHistory = async (ssoId) => {
    const result = await this.get(`/manage/account/${ssoId}/subscription/history`, {
      baseURL: config.coreApiServicelUrl,
    });
    return result;
  }
}

export default new UserApi();