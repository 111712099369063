import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById('root')

const render = (Component) => {
  ReactDOM.render(<Component />, rootElement);
}

render(Root);

if (module.hot) {
  module.hot.accept('./Root', () => {
      const NextRoot = require('./Root').default;
      render(NextRoot);
  });
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
