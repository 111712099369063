import { createSelector } from "reselect";

const productTypes = (state) => state.productType.data;
const filter = (state) => state.productType.filter;
const currentUser = (state) => state.app.user;

export const getProductTypes = createSelector(
  productTypes,
  filter,
  currentUser,
  (types, filter, user) => {
    if (!types) {
      return null;
    }
    if (!types.length) {
      return [];
    }

    const { query, providesGpxData, appVisibility, downloadType } = filter;

    let result = [...types];
    result = result.filter(t => t.owner === user.company)
    
    if (query?.length) {
      result = result.filter((t) => {
        return (
          t.description?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          t.vlbShortcode?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          t.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
    }
    
    result = result.filter((t) => {
      let result = true;
      if (providesGpxData) {
        result = result && t.providesGpxData;
      }
      if (appVisibility) {
        result = result && t.appCategory;
      }
      if (downloadType === 1) {
        result = result && t.downloadType === 'map'
      }
      if (downloadType === 2) {
        result = result && t.downloadType === 'trails'
      }
      if (downloadType === 3) {
        result = result && t.downloadType === 'none'
      }
      return result;
    });
  
    return result;
  }
);
