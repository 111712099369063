import React from "react";
import styles from './ProductImage.module.css'
import noProfileImage from './noProfileImage.svg'

const ProductImage = ({ url, isPro, warning, className = '' }) => {
  return (
    <div className={`${styles.container} ${className} ${isPro ? styles.isPro : ''}`}>
      <div style={{
        background: `url(${url || noProfileImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...(warning ? { 
          boxShadow: 'rgb(255 255 255) 0px 0px 0px 2px, rgb(255 113 58) 0px 0px 0px 5px'
        } : {})
      }} />
    </div>
  )
};

export default ProductImage;
