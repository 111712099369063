import { combineReducers } from 'redux'
import country from './country';
import subRegion from './subRegion';
import region from './region';



export default combineReducers({
    country,
    region,
    subRegion
})
