import React from "react";
import { useImage } from "react-image";
import ErrorBoundary from "./ErrorBoundary";
import styles from './ProfileImage.module.css'
import noProfileImage from './noProfileImage.svg'
import loadingProfileImage from './loadingProfileImage.svg'

const ProfileImage = ({ url, className = '' }) => {
  const { src, isLoading, error } = useImage({
    srcList: url ? [url] : null,
    useSuspense: false
  });
  return (
    <div className={`${styles.container} ${className}`}>
      {
        isLoading && <img src={loadingProfileImage} alt="Laden..."/>
      }
      {
        !isLoading && !url && !error && <img src={noProfileImage} alt="Kein Profilbild"/>
      }
      {
        !isLoading && error && <img src={noProfileImage} alt="Fehler beim Laden des Profilbilds"/>
      }
      {
        !isLoading && !error && <img src={src} alt="Cover" title={url ? (error ? 'Nicht gefunden' : 'Cover-Bild') : 'Nicht verfügbar'} />
      }
    </div>
  )
};

export default ({ url, className = '' }) => {
  return (
    <ErrorBoundary>
      <ProfileImage url={url} className={className} />
    </ErrorBoundary>
  )
};
