import {
  FETCH_PRODUCT_TYPES_STARTED,
  FETCH_PRODUCT_TYPES_FINISHED,
  FETCH_PRODUCT_TYPES_FAILED,
  UPDATE_FILTER,
  UPDATE_PRODUCT_TYPE_FINISHED
} from "./actions";
import { LOGOUT } from "../app/actions";

const STORAGE_KEY = 'kompass_crm_product_types'

const fetchFromStorage = () => {
  try {
    const item = localStorage.getItem(STORAGE_KEY);
    const data = JSON.parse(item);
    return data?.length ? data : null
  } catch (e) {
    // ignored
  }
  return null
}

const defaultState = {
  data: fetchFromStorage(),
  isLoading: true,
  filter: {
    query: ''
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_TYPES_FINISHED:
      localStorage.setItem(STORAGE_KEY, JSON.stringify(action.payload.productTypes))
      return Object.assign({}, state, {
        ...state,
        fetchOnLoad: false,
        isLoading: false,
        data: action.payload.productTypes
      });
    case FETCH_PRODUCT_TYPES_FAILED:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
      });
    case FETCH_PRODUCT_TYPES_STARTED:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
      });
    case UPDATE_FILTER:
      return Object.assign({}, state, {
        ...state,
        filter: {
          ...action.payload.filter
        }
      });
      case UPDATE_PRODUCT_TYPE_FINISHED:
        return Object.assign({}, state, {
          ...state,
          data: state.data.map((productType) => {
            if (productType.id === action.payload.productType.id) {
              return action.payload.productType
            }
            return productType;
          }),
        });
    case LOGOUT: {
      return defaultState;
    }
    default:
      return state;
  }
};
