import {
  FETCH_CHANNELS_FINISHED,
  FETCH_CHANNELS_FAILED,
  FETCH_CHANNELS_STARTED
} from "./actions";

const defaultState = {
  channels: null,
  isLoading: true,
  hasError: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CHANNELS_FINISHED:
      return Object.assign({}, state, {
        ...state,
        hasError: false,
        isLoading: false,
        channels: action.payload.channels
      });
    case FETCH_CHANNELS_FAILED:
      return Object.assign({}, state, {
        ...state,
        hasError: true,
        isLoading: false,
        channels: null
      });
    case FETCH_CHANNELS_STARTED:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        hasError: false,
        channels: null
      });
    default:
      return state;
  }
};
