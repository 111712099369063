import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import Page from "components/Core/Page";
import Title from "components/Core/Title";
import BreadCrumbLink from "components/Core/Title/BreadCrumbLink";
import Content from "components/Core/Content";
import Tabs from "react-materialize/lib/Tabs";
import Tab from "react-materialize/lib/Tab";
import General from "./tab-general";
import Products from "./tab-products";
import Alert from "components/Core/Alert";
import ScrollToTopOnMount from "components/Core/ScrollToTop";
import { toast } from "react-toastify";

class SubRegionDetails extends React.Component {
  onBackClick = () => {
    this.onClose();
  };

  componentWillUnmount = () => {
    this.props.reset();
  };

  onClose = () => {
    if (this.props.history.canGoBack) {
      this.props.history.goBack();
    } else {
      this.props.history.replace("/region");
    }
  };

  addProductToSubregion = (product) => {
    return this.props.addProductToSubregion(this.props.subRegion, product);
  };

  removeProductFromSubregion = (product) => {
    this.props
      .removeProductFromSubregion(this.props.subRegion, product)
      .then(() => {
        toast("Zuordnung entfernt", { type: "success", autoClose: 2000 });
      })
      .catch((e) => {
        toast("Beim Entfernen der Zuordnung ist ein Fehler aufgetreten.", {
          type: "error",
        });
        console.log(e);
      });
  };

  renderContent = () => {
    const { editMode, subRegion, products } = this.props;
    return (
      <Content>
        {!editMode && (
          <Alert type="info" style={{ marginBottom: "0px" }}>
            Du kannst Produkte nach dem Speichern hinzufügen.
          </Alert>
        )}
        {editMode && (
          <Tabs>
            <Tab title="Allgemein">
              <General
                subRegion={subRegion}
                onSubmit={this.props.onUpdateSubRegion}
                editMode={editMode}
              />
            </Tab>
            <Tab title="Produkte">
              <Products
                subRegion={subRegion}
                products={products}
                addProductToSubregion={this.addProductToSubregion}
                removeProductFromSubregion={this.removeProductFromSubregion}
              />
            </Tab>
          </Tabs>
        )}
        {!editMode && (
          <Fragment>
            <br />
            <General
              subRegion={subRegion}
              editMode={editMode}
              onClose={this.onClose}
            />
          </Fragment>
        )}
      </Content>
    );
  };

  render() {
    const { editMode, region, subRegion, isLoading } = this.props;
    return (
      <Page>
        <ScrollToTopOnMount />
        <Title
          onBackClick={this.onBackClick}
          renderBreadCrumbs={() => {
            return (
              <Fragment>
                <BreadCrumbLink to="/region">Regionen</BreadCrumbLink>
                <BreadCrumbLink to={`/region/${region.regionID}`}>
                  {region.name}
                </BreadCrumbLink>
              </Fragment>
            );
          }}
        >
          {!editMode && "Neue Subregion erstellen"}
          {editMode && !isLoading && subRegion.name}
          { editMode && isLoading && <span style={{ background: '#eee', width: '200px', height: '20px', display: 'inline-block', borderRadius: '2px'}}></span> }
        </Title>
        {(!editMode || !isLoading) && this.renderContent()}
      </Page>
    );
  }
}

export default withRouter(SubRegionDetails);
