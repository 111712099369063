import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
import styles from "./Header.module.css";

const Header = ({ productType, user, onOpenEditDialog }) => (
  <div className={styles.container}>
    <div className={styles.productName}>{productType.name}・{productType.description}</div>
    {
      (user?.roles.admin || user?.roles.productAdmin) && (
        <button className={styles.editButton} onClick={onOpenEditDialog}>
          <PencilIcon className={styles.pencilIcon} />
          <div>Bearbeiten</div>
        </button>
      )
    }
  </div>
);

export default Header;
