import { toast } from "react-toastify";
import ProductApi from "../../../api/ProductApi";

export const RESET =
  "kompass/crm/product/RESET";
export const FETCH_PRODUCTS_STARTED =
  "kompass/crm/product/FETCH_PRODUCTS_STARTED";
export const FETCH_PRODUCTS_FINISHED =
  "kompass/crm/product/FETCH_PRODUCTS_FINISHED";
export const FETCH_PRODUCTS_FAILED =
  "kompass/crm/product/FETCH_PRODUCTS_FAILED";
export const UPLOAD_GEOJSON_FINISHED =
  "kompass/crm/product/UPLOAD_GEOJSON_FINISHED";
export const UPDATE_PRODUCT_FINISHED =
  "kompass/crm/product/UPDATE_PRODUCT_FINISHED";
export const UPLOAD_GPX_FINISHED = "kompass/crm/product/UPLOAD_GPX_FINISHED";
export const PAGINATE = "kompass/crm/product/PAGINATE";

const htmlToString = (string) => {
  return (string + "").replace(/&#\d+;/gm, function (s) {
    return String.fromCharCode(s.match(/\d+/gm)[0]);
  })
}
/**
 * Checks if the product filter has been changed by the user
 * @param {*} param0
 * @returns
 */
 export const isFilterDirty = ({
  productType,
  keyword,
  includeUnpublished,
  withoutGeoData,
  withoutGpxUrl,
}) => {
  return (
    productType ||
    (keyword && keyword.length > 0) ||
    !includeUnpublished ||
    withoutGeoData ||
    withoutGpxUrl
  );
};

export const resetFilter = () => {
  return (dispatch, getState) => {
    const { company } = getState().app.user;
    const { query } = getState().product.filter;

    const filter = {
      query,
      isPublic: 1,
      typeId: null,
      appVisibility: null,
      unlockable: null,
      publishedState: null,
      providesGpxData: null,
      providesGeoData: null,
      page: 0,
      limit: 30,
      orderBy: 'name_asc',
    }

    
    dispatch(fetchProductsStarted(filter, 0));
    return ProductApi.find(filter, 0, company)
      .then((r) => {
        return dispatch(fetchProductsFinished(r));
      })
      .catch((err) => {
        if (!ProductApi.isCanceled(err)) {
          toast(htmlToString(err.message) || 'Ein unerwarter Fehler ist aufgetreten.', { type: 'error' })
          return dispatch(fetchProductsFailed());
        }
      });
  };
}


export const fetchProducts = (filter) => {
  return (dispatch, getState) => {
    const dirty = isFilterDirty(filter)
    if (!dirty) {
      return dispatch(resetProductsSearch());
    }
    const { company } = getState().app.user;
    
    dispatch(fetchProductsStarted(filter, 0));
    return ProductApi.find(filter, 0, company)
      .then((r) => {
        return dispatch(fetchProductsFinished(r));
      })
      .catch((err) => {
        if (!ProductApi.isCanceled(err)) {
          toast(htmlToString(err.message) || 'Ein unerwarter Fehler ist aufgetreten.', { type: 'error' })
          return dispatch(fetchProductsFailed());
        }
      });
  };
};

const refetchProducts = () => {
  return (dispatch, getState) => {
    const { company } = getState().app.user;
    const { filter, pagination } = getState().product;
    
    dispatch(fetchProductsStarted(filter, pagination.page));
    return ProductApi.find(filter, pagination.page, company)
      .then((r) => {
        return dispatch(fetchProductsFinished(r));
      })
      .catch((err) => {
        if (!ProductApi.isCanceled(err)) {
          toast(htmlToString(err.message) || 'Ein unerwarter Fehler ist aufgetreten.', { type: 'error' })
          return dispatch(fetchProductsFailed());
        }
      });
  };
}

export const paginate = (page) => {
  return (dispatch, getState) => {
    const { company } = getState().app.user;
    const { filter } = getState().product;
    dispatch(fetchProductsStarted(filter, page));
    return ProductApi.find(filter, page, company)
      .then((r) => {
        return dispatch(fetchProductsFinished(r));
      })
      .catch((err) => {
        if (!ProductApi.isCanceled(err)) {
          toast(htmlToString(err.message) || 'Ein unerwarter Fehler ist aufgetreten.', { type: 'error' })
          return dispatch(fetchProductsFailed());
        }
      });
  }
};

export const fetchOnLoad = () => {
  return (dispatch, getState) => {
    const { fetchOnLoad, filter } = getState().product;
    if (fetchOnLoad) {
      return dispatch(fetchProducts(filter))
    }
  }
}

export const fetchProductsStarted = (filter, page) => ({
  type: FETCH_PRODUCTS_STARTED,
  filter,
  page,
});

export const fetchProductsFinished = (result) => ({
  type: FETCH_PRODUCTS_FINISHED,
  payload: {
    ...result,
  },
});

export const resetProductsSearch = () => ({
  type: RESET,
});

export const fetchProductsFailed = () => ({
  type: FETCH_PRODUCTS_FAILED,
});

export const editProduct = (id, model) => {
  return (dispatch) => {
    return ProductApi.update(id, model)
      .then((result) => dispatch(editProductFinished(result.product)))
      .then(() => dispatch(refetchProducts()))
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export const uploadGeoJson = (id, file) => {
  return (dispatch) => {
    return ProductApi.uploadProductFile(id, file, "geojson")
      .then((result) => dispatch(uploadGeoJsonFinished(result.product)))
      .then(() => dispatch(refetchProducts()))
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

const uploadGeoJsonFinished = (product) => ({
  type: UPLOAD_GEOJSON_FINISHED,
  payload: {
    product
  },
});

const editProductFinished = (product) => ({
  type: UPDATE_PRODUCT_FINISHED,
  payload: {
    product
  },
});

const uploadGpxFinished = (product) => ({
  type: UPLOAD_GPX_FINISHED,
  payload: {
    product
  },
});

export const uploadGpx = (id, file) => {
  return (dispatch) => {
    return ProductApi.uploadProductFile(id, file, "gpx")
      .then((result) => dispatch(uploadGpxFinished(result.product)))
      .then(() => dispatch(refetchProducts()))
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
