import Section from "components/Core/Section";
import SectionElement from "components/Core/SectionElement";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import WarningCircleIcon from "mdi-react/WarningCircleIcon";
import moment from "moment";
import React from "react";
import styles from "./OverviewTab.module.css";

const OverviewTab = ({ product, openUploadModal }) => {
  const publicationInfo = product.publicationDate
    ? moment(product.publicationDate).format("DD.MM.YYYY")
    : "Kein Veröffentlichungsdatum";
  const gpxFileElements = product.gpxUrl
    ? product.gpxUrl.split("/")
    : null;
  const gpxFile = gpxFileElements?.length
    ? gpxFileElements[gpxFileElements.length - 1]
    : null;
  return (
    <div>
      <Section title="Auf einen Blick">
        <SectionElement label="ISBN" value={product.isbn} copyable />
        <SectionElement label="ID" value={product.id} copyable />
        <SectionElement
          label="Verlagsnummer"
          value={product.key}
          copyable
        />
        <SectionElement
          label="Produkttyp"
          value={`${product.key} · ${product.typeDescription}`}
        />
        <SectionElement label="Veröffentlichung" value={publicationInfo} />
        <SectionElement
          label="Zuletzt aktualisiert"
          value={moment(product.updated).format("DD.MM.YYYY HH:mm")}
        />
        <SectionElement
          label="Status"
          status={product.public.value ? "success" : "inactive"}
          renderValue={() => (
            <>
              {product.public.default && !product.public.enforced && (
                <div style={{ display: "flex" }}>
                  <CheckCircleIcon />
                  <span>Aktiv</span>
                </div>
              )}
              {product.public.enforced && (
                <div style={{ display: "flex" }}>
                  <WarningCircleIcon />
                  <span>Erzwungen aktiv</span>
                </div>
              )}
              {!product.public.default && !product.public.enforced && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Inaktiv</span>
                </div>
              )}
            </>
          )}
        />
        <SectionElement
          label="Bietet GPX-Downloads"
          fixedWidth
          status={product.providesGpxData.value ? "success" : "inactive"}
          renderValue={() => (
            <>
              {product.providesGpxData.value && (
                <div style={{ display: "flex" }}>
                  <CheckCircleIcon />
                  <span>Ja</span>
                </div>
              )}
              {!product.providesGpxData.value && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Nein</span>
                </div>
              )}
            </>
          )}
        />
        <SectionElement
          label="Produkt soll in der App angezeigt werden"
          fixedWidth
          status={product.appVisibility.value ? "success" : "inactive"}
          renderValue={() => (
            <>
              {product.appVisibility.value && (
                <div style={{ display: "flex" }}>
                  <CheckCircleIcon />
                  <span>Ja</span>
                </div>
              )}
              {!product.appVisibility.value && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Nein</span>
                </div>
              )}
            </>
          )}
        />
        <SectionElement
          label="Freischaltung"
          fixedWidth={product.downloadType.value === 'none'}
          status={product.downloadType.value === 'none' ? 'inactive' : null}
          renderValue={() => (
            <>
              {product.downloadType.value === 'none' && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Nein</span>
                </div>
              )}
              {product.downloadType.value === 'map' && <div>Blattschnitt</div>}
              {product.downloadType.value === 'trails' && <div>Touren</div>}
            </>
          )}
        />
      </Section>
      <Section title="Blattschnitt und GPX-Daten">
        <SectionElement
          label="Blattschnitt"
          status={product.geoData ? "" : "warning"}
          renderAction={() => (
            <button
              className={styles.uploadButton}
              onClick={() => openUploadModal(product, "blattschnitt")}
            >
              Upload
            </button>
          )}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {product.geoData && (
                <>
                  <CheckCircleIcon className="success" />
                  <span>Vorhanden</span>
                </>
              )}
              {!product.geoData && (
                <>
                  <WarningCircleIcon />
                  <span>Nicht vorhanden</span>
                </>
              )}
            </div>
          )}
        />
        <SectionElement
          label="GPX-Daten"
          status={gpxFile ? "" : "warning"}
          copyable
          renderAction={() => (
            <button
              className={styles.uploadButton}
              onClick={() => openUploadModal(product, "gpx")}
            >
              Upload
            </button>
          )}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {gpxFile && (
                <>
                  <CheckCircleIcon className="success" />
                  <a
                    href={product.gpxUrl}
                    target="blank"
                    className="external-link"
                  >
                    {gpxFile}
                  </a>
                </>
              )}
              {!gpxFile && (
                <>
                  <WarningCircleIcon />
                  <span>Nicht vorhanden</span>
                </>
              )}
            </div>
          )}
        />
      </Section>
    </div>
  );
};

export default OverviewTab;
