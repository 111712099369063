import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import styles from "./SectionElement.module.css";

const onCopy = (text) => {
  toast(
    <span>
      <i>{text}</i> in die Zwischenablage kopiert.
    </span>,
    { type: "info", autoClose: 750, hideProgressBar: true }
  );
};

const SectionElement = ({ label, value, fixedWidth, validation, copyable, renderValue, renderAction, status }) => (
  <div className={styles.element}>
    <div className={styles.label}>
      <div className={styles.content}>{label}</div>
      <div className={styles.separator} />
    </div>
    <div className={`${styles.value} ${status === 'highlight' ? styles.highlight : ''} ${status === 'warning' ? styles.warning : ''} ${status === 'success' ? styles.success : ''} ${status === 'inactive' ? styles.inactive : ''} ${fixedWidth ? styles.fixedWidth : ''} ${validation ? styles.validation : ''}`}>
      {
        renderValue && renderValue()
      }
      {!renderValue && copyable && (
        <CopyToClipboard text={value} onCopy={onCopy}>
          <span className={styles.copyable}>{value}</span>
        </CopyToClipboard>
      )}
      {
        !renderValue && !copyable && value
      }
    </div>
    {
      renderAction && (
        <div className={styles.action}>
          {renderAction()}
        </div>
      )
    }
  </div>
);

export default SectionElement;
