import AxiosRequest from "./AxiosRequest";

class DestinationsApi extends AxiosRequest {
  findCountries = async () => {
    const result = await this.get(`/destination/country`);
    return result;
  };

  findRegions = async (keyword) => {
    if (this.findRegionsAbort) {
      this.findRegionsAbort.abort();
    }
    this.findRegionsAbort = new AbortController();
    const result = await this.get(`/destination/region`, {
      params: {
        ...(keyword && keyword.length ? { keyword } : {}),
      },
      signal: this.findRegionsAbort.signal,
    });
    return result;
  };

  findRegion = async (regionId) => {
    const result = await this.get(`/destination/region/${regionId}`);
    return result;
  };

  updateRegion = async (regionId, model) => {
    const result = await this.put(`/destination/region/${regionId}`, {
      data: model,
    });
    return result;
  };

  findSubRegions = async (regionId) => {
    const result = await this.get(`/destination/region/${regionId}/subregion`);
    return result;
  };

  findRegion = async (regionId) => {
    const result = await this.get(`/destination/region/${regionId}`);
    return result;
  };

  findSubRegion = async (subregionId) => {
    const result = await this.get(`/destination/subregion/${subregionId}`);
    return result;
  };

  updateSubRegion = async (subregionId, model) => {
    const result = await this.put(`/destination/subregion/${subregionId}`, {
      data: model,
    });
    return result;
  };

  addProductToSubRegion = async (subregionId, productId) => {
    const result = await this.post(
      `/destination/subregion/${subregionId}/product/${productId}`
    );
    return result;
  };

  removeProductToSubRegion = async (subregionId, productId) => {
    const result = await this.delete(
      `/destination/subregion/${subregionId}/product/${productId}`
    );
    return result;
  };
}

export default new DestinationsApi();
