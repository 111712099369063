import React, { Component } from "react";
import ClockIcon from "mdi-react/ClockOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";
import WarningIcon from "mdi-react/WarningCircleIcon";
import ProfileImage from "../ProfileImage/ProfileImage";
import styles from "./UserDetailOverlay.module.css";
import moment from "moment";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import { Button, Checkbox, Tab, Tabs } from "react-materialize";
import Platforms from "../Platforms";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import UserHistoryTab from "./UserHistoryTab";
import UserApi from "api/UserApi";
import Section from "components/Core/Section";
import Spinner from "components/Core/Spinner";
import ActionDropdown from "components/Core/ActionDropdown";
import CogOutlineIcon from "mdi-react/CogOutlineIcon";

const now = moment();

const items = [
  {
    key: 'header',
    type: 'header',
    name: 'Aktionen'
  },
  {
    key: 'resendConfirmationMail',
    name: 'Bestätigungsmail erneut senden'
  },
  {
    key: 'resetPassword',
    name: 'Passwort zurücksetzen'
  },
  {
    key: 'deleteAccountSeparator',
    type: 'separator'
  },
  {
    key: 'deleteAccount',
    name: 'Account löschen'
  }
]

class UserDetailOverlay extends Component {
  state = {
    user: null,
    isOpen: false,
    isFetching: false,
    tab: "overview",
    isUpdatingRenewalFailed: false,
  };

  open = async (user) => {
    this.setState({
      user,
      isOpen: true,
      proDays: null,
      isFetching: false,
      subscriptions: null,
      managedBy: null,
      isFetchingSubscriptions: true,
      isUpdatingRenewalFailed: false,
    });
    document.body.style.overflow = "hidden";
    try {
      const {
        subscriptions,
      } = await UserApi.findHistory(user.ssoId);
      this.setState({
        managagedBy: "",
        isFetchingSubscriptions: false,
        subscriptions,
      });
    } catch (error) {
      this.setState({
        historyError: error.message,
        isFetchingSubscriptions: false
      });
    }
  };

  onChangeRenewalFailed = async (e) => {
    const { checked } = e.currentTarget
    this.setState({
      user: {
        ...this.state.user,
        renewalFailed: checked
      }
    })
  }

  onChangeRenewalFailedInfo = (e) => {
    const { value } = e.currentTarget
    this.setState({
      user: {
        ...this.state.user,
        renewalFailedInfo: value
      }
    })
  }

  onSubmitRenewalInfo = async () => {
    if (this.state.isUpdatingRenewalFailed) {
      return
    }
    this.setState({
      isUpdatingRenewalFailed: true
    })
    try {
      await this.props.updateUser(this.state.user.ssoId, {
        renewalFailed: this.state.user.renewalFailed,
        renewalFailedInfo: this.state.user.renewalFailedInfo
      })
      this.setState({
        isUpdatingRenewalFailed: false,
        user: {
          ...this.state.user,
          renewalFailed: this.state.user.renewalFailed,
          renewalFailedInfo: this.state.user.renewalFailedInfo
        }
      })
    } catch (error) {
      this.setState({
        isUpdatingRenewalFailed: false
      })
    }
  }

  close = (event) => {
    if (event) {
      event.stopPropagation();
    }
    document.body.style.overflow = "initial";
    this.setState({
      isOpen: false,
      proDays: null,
      isFetching: false,
    });
  };

  isProDateSet = () => {
    const { user } = this.state;
    return (
      user.premiumTill &&
      moment(user.premiumTill).format("YYYY-MM-DD") !== "2000-01-01"
    );
  };

  isOutdatedPro = () => {
    const { user } = this.state;
    return this.isProDateSet() && moment(user.premiumTill).isBefore(now);
  };

  isActivePro = () => {
    const { user } = this.state;
    return user.premiumTill && !moment(user.premiumTill).isBefore(now);
  };

  getRegisteredVia = () => {
    const {
      user: { registeredVia },
    } = this.state;
    if (!registeredVia) {
      return null;
    }
    let result = "";
    if (registeredVia.indexOf("android") > -1) {
      result = "Android";
    } else if (registeredVia.indexOf("ios") > -1) {
      result = "iOS";
    } else if (registeredVia.indexOf("web") > -1) {
      result = "Website";
    }

    if (registeredVia.indexOf("hkf") > -1) {
      result = `${result} (HKF)`;
    }
    if (registeredVia.indexOf("gaia") > -1) {
      result = `${result} (GAIA)`;
    }
    return result;
  };

  onChangeProDays = (event) => {
    this.setState({
      proDays: event.target.value,
    });
  };

  addDays = async (event) => {
    const { user, proDays, isFetching } = this.state;
    try {
      event.preventDefault();
      const days = Number.parseInt(proDays, 10)
      if (!isNaN(days) && days > 0 && !isFetching) {
        this.setState({
          isFetching: true,
        });
        await this.props.addDays(user.ssoId, days);
        this.setState({
          isFetching: false,
          proDays: "",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isFetching: false,
      });
    }
  };

  onCopy = (text) => {
    toast(
      <span>
        <i>{text}</i> in die Zwischenablage kopiert.
      </span>,
      { type: "info", autoClose: 750, hideProgressBar: true }
    );
  };

  onSelectAction = (key) => {
    switch (key) {
      case 'deleteAccount':
        this.props.onOpenDeleteModal(this.state.user)
        break;
      default:
        alert('Noch nicht implementiert.')
        break;
    }
  }

  render() {
    const { user, isOpen, proDays, isFetching, tab, subscriptions, isFetchingSubscriptions, historyError, isUpdatingRenewalFailed } =
      this.state;
    if (!user || !isOpen) return null;

    const isProDateSet = this.isProDateSet();
    const activePro = isProDateSet && this.isActivePro();
    const outdatedPro = isProDateSet && !activePro && this.isOutdatedPro();
    const registeredVia = this.getRegisteredVia();

    return (
      <div className={styles.backdropContainer}>
        <div className={styles.backdrop} onClick={this.close} />
        <div className={styles.containerBackground} />
        <div className={styles.container}>
          <div className={styles.nav}>
            <div className={styles.closeButton} onClick={this.close}>
              <CloseIcon />
            </div>
            <div className={styles.overlayTitle}>Benutzerdetails</div>
          </div>
          
          <div className={styles.header}>
            <div className={styles.coverContainer}>
              <ProfileImage
                url={user.image}
                large
                className={styles.profileImage}
                isPro={user.isPro}
                warning={user.renewalFailed}
              />
            </div>
            <div style={{ flex: 1}}>
              <div className={styles.username}>{user.userName}</div>
              <div className={styles.ssoId}>{user.email}</div>
            </div>
            <ActionDropdown 
              items={items} 
              payload={user}
              classes={{ dropdownButton: styles.actionDropdownButton, dropdownMenu: styles.actionDropdownMenu }}
              onSelect={this.onSelectAction} renderIcon={() => <CogOutlineIcon />}/>
          </div>
          <div className={styles.body}>
            <Tabs className={styles.tabs}>
              <Tab
                title="Übersicht"
                active={tab === "overview"}
                key="overview"
                className={styles.tab}
              >
                <div className={styles.section}>
                  <div className={styles.sectionHeader}>Account</div>
                  <div className={styles.sectionBody}>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>
                          Benutzername
                        </div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        <CopyToClipboard
                          text={user.userName}
                          onCopy={this.onCopy}
                        >
                          <span className={styles.copyable}>
                            {user.userName}
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>E-Mail</div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        <CopyToClipboard text={user.email} onCopy={this.onCopy}>
                          <span className={styles.copyable}>{user.email}</span>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>SSO ID</div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        <CopyToClipboard
                          text={user.ssoId}
                          onCopy={this.onCopy}
                        >
                          <span className={styles.copyable}>{user.ssoId}</span>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>
                          Registriert am
                        </div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        {moment(user.created).format("DD.MM.YYYY hh:mm:ss")}
                      </div>
                    </div>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>
                          Registriert via
                        </div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        {registeredVia ? (
                          registeredVia
                        ) : (
                          <div style={{ display: "flex" }}>
                            <WarningIcon />
                            <span>Daten nicht verfügbar</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>
                          Plattformen
                        </div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        {(!user.platforms || user.platforms < 1) && (
                          <div style={{ display: "flex" }}>
                            <WarningIcon />
                            <span>Daten nicht verfügbar</span>
                          </div>
                        )}
                        {user.platforms > 0 && (
                          <Platforms platforms={user.platforms} />
                        )}
                      </div>
                    </div>
                    <div
                      className={styles.element}
                      style={{ marginBottom: "0px" }}
                    >
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>
                          Letzte Aktualisierung
                        </div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        {user.lastUpdate &&
                          moment(user.lastUpdate).format(
                            "DD.MM.YYYY HH:mm:ss"
                          )}
                        {!user.lastUpdate && (
                          <div style={{ display: "flex" }}>
                            <WarningIcon />
                            <span>Daten nicht verfügbar</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.section}
                  style={{ marginBottom: "24px" }}
                >
                  <div className={styles.sectionHeader}>PRO Mitgliedschaft</div>
                  <div className={styles.sectionBody}>
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>Status</div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div
                        className={`${styles.elementValue} ${outdatedPro ? styles.warning : ""
                          } ${activePro ? styles.success : ""}`}
                      >
                        {outdatedPro && (
                          <div style={{ display: "flex" }}>
                            <ClockIcon className={styles.checkWarning} />
                            <span>Abgelaufen</span>
                          </div>
                        )}
                        {activePro && (
                          <div style={{ display: "flex" }}>
                            <CheckCircleIcon className={styles.checkYes} />
                            <span>Aktiv</span>
                          </div>
                        )}
                        {!isProDateSet && "Basic User"}
                      </div>
                    </div>
                    {isProDateSet && (
                      <div className={styles.element}>
                        <div className={styles.elementLabel}>
                          <div className={styles.elementLabelContent}>
                            Ablaufdatum
                          </div>
                          <div className={styles.elementLabelSeparator} />
                        </div>
                        <div className={styles.elementValue}>
                          {user.premiumTill ? (
                            moment(user.premiumTill).format(
                              "DD.MM.YYYY HH:mm:ss"
                            )
                          ) : (
                            <span style={{ color: "#ddd" }}>Basic User</span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={styles.element}>
                      <div className={styles.elementLabel}>
                        <div className={styles.elementLabelContent}>
                          Kostenloses Probemonat
                        </div>
                        <div className={styles.elementLabelSeparator} />
                      </div>
                      <div className={styles.elementValue}>
                        {user.freeTrialRedeemed === 1 && (
                          <div style={{ display: "flex" }}>
                            <CheckCircleIcon className={styles.checkYes} />
                            <span>Eingelöst</span>
                          </div>
                        )}
                        {user.freeTrialRedeemed !== 1 && "Nicht eingelöst"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionBody}>
                    <div
                      className={styles.sectionHeader}
                      style={{ paddingTop: "8px" }}
                    >
                      Laufzeit verlängern
                    </div>
                    <div className={styles.proDaysInfo}>
                      Um die PRO-Mitgliedschaft sofort nutzen zu können, sollte
                      sich der/die BenutzerIn nach der Verlängerung auf allen
                      Endgeräten einmal ab- und wieder anmelden.
                    </div>
                    <br />
                    <div className={styles.daysUpdateContainer}>
                      <div className={styles.daysInputContainer}>
                        <form autoComplete="off" onSubmit={this.addDays}>
                          <input
                            type="text"
                            name="proDays"
                            placeholder="z.B. 30"
                            style={{ marginBottom: "0px" }}
                            onChange={this.onChangeProDays}
                            className={styles.inputField}
                            value={proDays}
                            disabled={isFetching}
                          />
                        </form>
                        <span className={styles.daysInputLabel}>Tage</span>
                      </div>
                      <Button
                        onClick={this.addDays}
                        waves="light"
                        className={styles.submitButton}
                        disabled={!proDays || isFetching}
                      >
                        {!isFetching && "Laufzeit verlängern"}
                        {isFetching && "Einen Moment..."}
                      </Button>
                    </div>
                    <br />
                  </div>
                </div>
                <Section title="Renewal Warnung">
                  <div className={styles.proDaysInfo} style={{ paddingBottom: '16px' }}>
                    Gab es bei diesem Benutzer ein Problem bei der automatischen Verlängerung seines Abos? Dann setze hier ein Häkchen. Dies betrifft nur Abos im App Store bzw. Play Store.
                  </div>
                  <div className={styles.renewalCheckbox}>
                    <Checkbox label="Probleme bei Renewals" checked={user.renewalFailed} id="renewalFailed" name="renewalFailed" filledIn onChange={this.onChangeRenewalFailed} />
                  </div>
                  <div className={styles.renewalInfo}>
                    <label>Bemerkung</label>
                    <textarea value={user.renewalFailedInfo} name="renewalFailedInfo" rows="5" onChange={this.onChangeRenewalFailedInfo} />
                  </div>
                  <div className={styles.renewalActions}>
                    <Button waves="light" onClick={this.onSubmitRenewalInfo} disabled={isUpdatingRenewalFailed}>Änderungen speichern</Button>
                    {
                      isUpdatingRenewalFailed && <Spinner size="tiny" style={{ margin: "0 0 0 16px" }} />
                    }
                  </div>
                </Section>
              </Tab>
              <Tab
                title="Abo/Käufe"
                active={tab === "history"}
                key="history"
                className={styles.tab}
              >
                <UserHistoryTab subscriptions={subscriptions} isFetching={isFetchingSubscriptions} error={historyError} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetailOverlay;
