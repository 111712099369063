import DestinationsApi from "api/DestinationsApi";
export const FETCH_REGION = "kompass/crm/region/details/FETCH_REGION";
export const FETCH_REGION_SUCCESS =
  "kompass/crm/region/details/FETCH_REGION_SUCCESS";
export const FETCH_SUBREGIONS = "kompass/crm/region/details/FETCH_SUBREGIONS";
export const FETCH_SUBREGIONS_SUCCESS =
  "kompass/crm/region/details/FETCH_SUBREGIONS_SUCCESS";

export const UPDATE_REGION = "kompass/crm/region/details/UPDATE_REGION";
export const UPDATE_REGION_SUCCESS =
  "kompass/crm/region/details/UPDATE_REGION_SUCCESS";
export const UPDATE_REGION_FAILED =
  "kompass/crm/region/details/UPDATE_REGION_FAILED";

export const prepareNewRegion = () => ({
  type: FETCH_REGION_SUCCESS,
  payload: {
    country: "",
    name: "",
    description: "",
  },
});

const fetchRegionStarted = () => ({
  type: FETCH_REGION,
});

const fetchRegionFinished = (result) => ({
  type: FETCH_REGION_SUCCESS,
  payload: {
    ...result,
  },
});

const fetchSubRegionsStarted = () => ({
  type: FETCH_SUBREGIONS,
});

const fetchSubRegionsFinished = (result) => ({
  type: FETCH_SUBREGIONS_SUCCESS,
  payload: {
    ...result,
  },
});

export const fetchRegion = (id) => {
  return (dispatch) => {
    dispatch(fetchRegionStarted());
    return DestinationsApi.findRegion(id).then((r) => {
      return dispatch(fetchRegionFinished(r));
    });
  };
};

export const fetchSubRegions = (id) => {
  return (dispatch) => {
    dispatch(fetchSubRegionsStarted());
    return DestinationsApi.findSubRegions(id)
      .then((r) => {
        return dispatch(fetchSubRegionsFinished(r));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateRegion = (id, model) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_REGION });
    return DestinationsApi.updateRegion(id, model)
      .then((r) => {
        return dispatch({
          type: UPDATE_REGION_SUCCESS,
          payload: {
            id,
            ...model,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
