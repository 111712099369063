import React, { Component } from 'react';
import Downshift from 'downshift';
import styles from './SelectBox.module.css';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

class SelectBox extends Component {

  onSelect = item => {
    this.props.onSelect(item.id === -1 ? null : item.id, this.props.property)
    this.button.blur();
  };


  render() {
    const { selected, values, label, renderIcon, className } = this.props
    const selectedItem = selected !== null && selected !== undefined ? values.find(v => v.id === selected) : null
    return (
      <Downshift onChange={this.onSelect} itemToString={item => item ? item.id : ''}>
        {({ getToggleButtonProps, getItemProps, isOpen }) => (
          <span style={{ position: 'relative', width: '100%', display: 'block' }}>
            <div className={styles.buttonContainer}>
              <div className={styles.label}>{label}</div>
              <button
                ref={c => this.button = c}
                className={`${styles.dropdownButton} ${isOpen ? styles.open : ''} ${selectedItem ? styles.active : ''} ${className}`}
                type="button"
                {...getToggleButtonProps()}
              >

                {
                  selectedItem && (
                    <>
                      {renderIcon && renderIcon(selectedItem)}
                      <span className={styles.itemLabel}>{selectedItem.label}</span>
                    </>
                  )
                }
                <ChevronDownIcon className={styles.chevron} />
              </button>
            </div>
            {isOpen && (
              <div
                className={`${styles.dropdownMenu}`}
                align={this.props.align}
                offsetTop={this.props.offsetTop}
                offsetLeft={this.props.offsetLeft}
                offsetRight={this.props.offsetRight}
                width={this.props.width}
              >
                {
                  values.map(item => (
                    <a
                      key={item.id}
                      className={`${styles.dropdownItem}`}
                      {...getItemProps({
                        item,
                      })}
                    >
                      {
                        renderIcon && renderIcon(item)
                      }
                      <div className={styles.dropdownItemLabel}>{item.label}</div>
                    </a>
                  ))
                }
              </div>
            )}
          </span>
        )}
      </Downshift>
    );
  }
}

export default SelectBox;
