/* eslint no-unused-vars: 0 */
import React from 'react';
import Readmore from './Readmore';
import ReactQuill from 'react-quill';
import quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.css';

const editorModules = {
    toolbar: {
    container: "#toolbar",
   /*
container: [
            [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', {'list': 'ordered'}, {'list': 'bullet'}, 'blockquote'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            
            ['clean', 'link', 'readmore']
        ],

    */     
        handlers: {
            readmore: function(value) {
                var hrNode = document.querySelector('.text-editor hr.readmore');
                if (!hrNode) {
                    const range = this.quill.getSelection();
                    if (range) {
                        this.quill.insertEmbed(range.index, 'readmore', value, quill.sources.USER);
                    }
                }
                
            }
        }
    }

}

const CustomToolbar = () => (
    <div id="toolbar">
        <span class="ql-formats">
            <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                <option selected></option>
                <option value="1"></option>
                <option value="2"></option>
                <option value="3"></option>
                <option value="4"></option>
            </select>
        </span>
        <span class="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-blockquote"></button>
        </span>
        <span class="ql-formats">
            <button className="ql-align" value=""></button>
            <button className="ql-align" value="center"></button>
            <button className="ql-align" value="right"></button>
            <button className="ql-align" value="justify"></button>
        </span>
        <span class="ql-formats">
            <button className="ql-link"></button>
            <button className="ql-readmore" value="value">Weiterlesen</button>
        </span>
        <span class="ql-formats">
            <button className="ql-clean"></button>
        </span>
    </div>
  )
  

const editorFormats =  [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align',
    'link',
    'readmore'
];

class Editor extends React.Component {

    constructor(props) {
        super(props)
        this.formats = editorFormats;
        this.state = {
            text: props.value || ''
        }
    }
  
    onChange = (value) => {
        this.props.onChange(value);
    }

    render() {
        return (
            <div className="text-editor">
                 <CustomToolbar />
                 <ReactQuill
                    ref={ c => this.editor = c}
                    modules={editorModules}
                    formats={this.formats}
                    value={this.props.value || ''} 
                    onChange={this.onChange} />
            </div>
            
        )
    }
}

export default Editor;