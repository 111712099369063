import ProductFilterBox from 'components/Product/ProductFilter/ProductFilterBox';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import FilterVariantMinusIcon from 'mdi-react/FilterVariantMinusIcon';
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'react-materialize';
import styles from './UserFilter.module.css';

const statusValues = [
  {
    id: 'basic',
    label: 'Basic-User'
  },
  {
    id: 'pro',
    label: 'PRO User'
  },
]

const UserFilter = ({ children, user, filter, onSearch, onResetFilter }) => {
  const [renewalFailed, setRenewalFailed] = useState(
    filter.renewalFailed
  );
  const [currentFilter, setCurrentFilter] = useState({
    ...filter
  })

  useEffect(() => {
    setCurrentFilter(filter);
  }, [filter])

  const onChange = (e) => {
    const { name, checked } = e.currentTarget;
    if (name === "renewalFailedFilter") {
      setRenewalFailed(checked);
    }
    if (onSearch) {
      onSearch({
        renewalFailed,
        [name]: checked,
      });
    }
  };

  const onSelect = (item, propertyName) => {
    if (onSearch) {
      onSearch({
        ...filter,
        [propertyName]: item
      })
    }
    setCurrentFilter({
      ...filter,
      [propertyName]: item
    })
  }

  const onReset = () => {
    setRenewalFailed(false);
    setCurrentFilter({
      ...filter,
      status: null
    })
    onResetFilter()
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Benutzer filtern</div>
        <button className={styles.resetLink} onClick={onReset}>
          <FilterVariantMinusIcon />
        </button>
      </div>
      <ProductFilterBox
        label="Status"
        emptyLabel="Beliebig"
        onSelect={onSelect}
        values={statusValues}
        property="status"
        selected={currentFilter.status}
        user={user}
        renderIcon={(item) => {
          if (item.id === 0) {
            return <CloseCircleIcon />
          }
          if (item.id === 1) {
            return <CheckCircleIcon />
          }
          return null
        }} />
      <div className={styles.filterElement}>
        <Checkbox
          label="Renewal Problem"
          filledIn
          id="renewalFailedFilter"
          name="renewalFailed"
          checked={renewalFailed}
          onChange={onChange}
        />
      </div>
      {
        children
      }
    </div>
  )
}

export default UserFilter;
