import React, { useState } from "react";
import Spinner from "../Spinner";
import styles from "./SearchBox.module.css";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ClearIcon from "mdi-react/BackspaceIcon";

const SearchBox = ({
  className = "",
  query = "",
  placeholder,
  onSubmit,
  autoFocus,
  isFetching,
  submitOnKeyDown,
}) => {
  const [value, setValue] = useState(query);
  const [focus, setFocus] = useState(false);

  const cx = `${styles.container} ${className} ${focus ? styles.hasFocus : ""}`;

  const onChangeValue = (e) => {
    const { value } = e.currentTarget;
    setValue(value);
    if ((!value.length || submitOnKeyDown) && onSubmit) {
      onSubmit(value);
    }
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter" && onSubmit) {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(value);
    }
  };

  const onClear = () => {
    setValue('');
    if (onSubmit) {
      onSubmit('');
    }
  }

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div className={cx}>
      <input
        value={value}
        type="search"
        className={styles.inputField}
        placeholder={placeholder}
        onChange={onChangeValue}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      <MagnifyIcon />
      {isFetching && <Spinner size="small" className={styles.spinner} />}
      {
        !isFetching && value?.length > 0 && (
          <button className={styles.clearButton} onClick={onClear}>
            <ClearIcon />
          </button>
        )
      }

    </div>
  );
};

export default SearchBox;
