import AndroidIcon from "mdi-react/AndroidIcon";
import AppleIcon from "mdi-react/AppleIcon";
import WebIcon from "mdi-react/WebIcon";
import React from "react";

const Platforms = ({ platforms }) => (
  <div style={{ display: "flex" }}>
    <WebIcon
      style={{
        margin: "2px 12px 0 0",
        width: "20px",
        height: "22px",
        color: (platforms & 1 || platforms & 8) ? "#2196F3" : "#ddd",
      }}
      title="Web"
    />
    <AppleIcon
      style={{
        margin: "0 12px 0 0",
        width: "20px",
        color: (platforms & 2 || platforms & 16 || platforms & 64) ? "#324471" : "#ddd",
      }}
      title="iOS"
    />
    <AndroidIcon
      style={{ width: "20px", color:  (platforms & 4 || platforms & 32 || platforms & 128) ? "#8BC34A" : "#ddd" }}
      title="Android"
    />
  </div>
);

export default Platforms;
