/* eslint jsx-a11y/anchor-is-valid:0 */
import CheckIcon from 'mdi-react/CheckIcon';
import ExclamationIcon from 'mdi-react/ExclamationIcon';
import React from 'react';
import styles from './SelectedValue.module.css';

const SelectedValue = ({ title, value, placeholder, isFetching }) => {
  return (
    <div className={styles.selection}>
      <div className={styles.selectionLabel}>
        {title}
      </div>
      <div className={styles.selectionValueContainer}>
        {
          isFetching && (
            <>
              <div className={`${styles.icon} ${styles.iconFetching}`} />
              <div className={`${styles.selectionValue} ${styles.valueFetching}`}>&nbsp;</div>
            </>

          )
        }
        {
          !isFetching && (
            <>
              <div className={`${styles.icon} ${value?.id ? styles.success : styles.warning}`}>
                {value?.id && <CheckIcon />}
                {!value?.id && <ExclamationIcon />}
              </div>
              <div className={styles.selectionValue}>
                {
                  !value?.id && placeholder
                }
                {value?.name}
              </div>
            </>
          )
        }

      </div>


    </div>
  )
}

export default SelectedValue