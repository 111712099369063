import React from 'react';
import './Alert.css';

export default ({ children, type, ...rest }) => {
    const className=`alert ${type || 'info'}`;
    return (
        <div className={className} { ...rest }>
            {children}
        </div>
    )
}