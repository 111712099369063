import ProductImage from "components/Product/ProductImage/ProductImage";
import React from "react";
import styles from "./Header.module.css";

const Header = ({ product }) => (
  <div className={styles.container}>
    <div className={styles.coverContainer}>
      {product.coverUrl && (
        <a href={product.coverUrl} target="_blank" rel="noopener noreferrer">
          <ProductImage url={product.coverUrl} />
        </a>
      )}
      {!product.coverUrl && <ProductImage url={product.coverUrl} />}
    </div>
    <div style={{ marginLeft: '16px'}}>
      <div className={styles.productName}>{product.name}</div>
    </div>
  </div>
);

export default Header;
