/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { Component } from "react";
import { Button } from "react-materialize";
import Modal from "react-modal";
import ProductTable from "../ProductTable";
import "./ProductSelectorModal.css";
import ProductApi from "api/ProductApi";
import { toast } from "react-toastify";

class ProductSelectorModal extends Component {
  state = {
    open: false,
    products: [],
  };

  open = () => {
    this.setState({
      open: true,
    });
  };

  close = () => {
    // Don't close the modal, if we are uploading a file
    if (this.state.isSubmitting) return false;

    this.setState({
      open: false,
    });
  };

  submit = async () => {
    this.setState({ isSubmitting: true });
  };

  onSearch = async ({ keyword }) => {
    let result;
    try {
      if (!keyword) {
        this.setState({ products: [] });
        return;
      }
      this.setState({ isLoading: true });
      result = await ProductApi.find({
        includeUnpublished: true,
        ...(keyword && keyword.length ? { keyword } : {}),
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({
      products: result ? result.products : [],
      isLoading: false,
    });
  };

  onSelectProduct = async (product) => {
    this.setState({ isSubmitting: true });
    try {
      await this.props.addProductToSubregion(product);
      toast("Das Produkt wurde der Subregion zugeordnet.", {
        type: "success",
        autoClose: 2000,
      });
      this.setState({ isSubmitting: false });
      this.close();
    } catch (e) {
      console.log(e);
      const message =
        e.message ||
        "Beim Zuordnen des Produkts zu dieser Subregion ist ein Fehler aufgetreten.";
      toast(message, { type: "error" });
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { open, products, isSubmitting, isLoading } = this.state;

    return (
      <Modal
        isOpen={open}
        className="modal product-selector-modal"
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={!isSubmitting}
        bodyClassName="modal--open"
        contentLabel="Produktauswahl"
      >
        <div class="modal-content">
          <ProductTable
            products={products}
            hideFiles
            selectable
            isLoading={isLoading}
            isLocked={isSubmitting}
            withSearch
            placeholder="Suche nach dem Produkt, das zu hinzufügen möchtest. Klicke dann auf die entsprechende Zeile, um das Produkt der Subregion zuzuordnen"
            onRowClick={this.onSelectProduct}
            onSearch={this.onSearch}
          />
        </div>
        <div class="modal-footer">
          <Button waves="light" onClick={this.close}>
            Schließen
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ProductSelectorModal;
