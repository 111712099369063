/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import styles from "./ProductTypesList.module.css";
import ProductTypeCard from "./ProductTypeCard";
import Loading from "components/Core/Loading";


class ProductTypesList extends React.Component {

  renderEmptyMessage = () => {
    const { productTypes } = this.props;
    return (
      <div className={styles.resultCount}>
        {
          `${productTypes.length === 1 ? 'Ein' : `${productTypes.length}`} Produkttyp${productTypes.length < 2 ? '' : 'en'}`
        }
      </div>
    )
  }

  render() {
    const {
      productTypes,
      user,
      onClick,
      onOpenEditDialog,
      isLoading,
    } = this.props;
    if (!productTypes) {
      return <Loading />
    }
    return (
      <div className={isLoading ? styles.isLoading : ''}>
        {
          productTypes.length > 0 && this.renderEmptyMessage()
        }
        {
          productTypes.map((productType) => (
            <ProductTypeCard 
              key={productType.id} 
              productType={productType} 
              onClick={onClick} 
              onOpenEditDialog={onOpenEditDialog}
              user={user} />
          ))
        }

      </div>
    );
  }
}

export default ProductTypesList;
