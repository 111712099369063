import AxiosRequest from "./AxiosRequest";

class AuthApi extends AxiosRequest {
  login = async (username, password) => {
    const result = await this.post(`/auth/login`, {
      data: {
        username,
        password,
      },
      skipTokenRefresh: true
    });
    if (result.success && result.token) {
      localStorage.setItem("kompass_crm_token", result.token);
      if (result.refreshToken) {
        localStorage.setItem("kompass_crm_rt", result.refreshToken);
      }
    }

    const user = result.user;
    return {
      ...result,
      user,
    };
  };

  logout = () => {
    localStorage.removeItem("kompass_crm_token");
    localStorage.removeItem("kompass_crm_rt");
    return Promise.resolve();
  };


  validateAuthentication = async () => {
    // should also check via backend if the user is still allowed to access this crm
    const token = localStorage.getItem("kompass_crm_token");
    if (!token) {
      throw new Error('tokenExpired')
    }
    const { user } = await this.post(`/auth/validate`);
    return {
      token,
      user,
    };
  };
}

export default new AuthApi();
