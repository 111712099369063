import AuthApi from "api/AuthApi";
import { fetchProductTypes } from '../../productType/actions'

export const SESSION_AUTHENTICATED = "kompass/crm/app/SESSION_AUTHENTICATED";
export const SESSION_NOT_AUTHENTICATED =
  "kompass/crm/app/SESSION_NOT_AUTHENTICATED";
export const LOGIN_SUCCESS = "kompass/crm/app/LOGIN_SUCCESS";
export const LOGIN_ERROR = "kompass/crm/app/LOGIN_ERROR";
export const LOGOUT = "kompass/crm/app/LOGOUT";

export const sessionAuthenticated = (payload) => ({
  type: SESSION_AUTHENTICATED,
  payload,
});

export const sessionNotAuthenticated = () => {
  return {
    type: SESSION_NOT_AUTHENTICATED,
  };
};

export const validateAuthentication = () => {
  return (dispatch) => {
    return AuthApi.validateAuthentication()
      .then((result) => {
        if (result && result.token) {
          return dispatch(sessionAuthenticated(result));
        }
        return dispatch(sessionNotAuthenticated());
      })
      .catch(() => {
        return dispatch(sessionNotAuthenticated());
      });
  };
};

export const onLoginSuccess = (payload) => {
  return (dispatch) => {
    return dispatch(fetchProductTypes()).then(() => {
      return dispatch({
        type: LOGIN_SUCCESS,
        payload,
      });
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.clear()
    return AuthApi.logout().then(() => dispatch({ type: LOGOUT }));
  };
};
