import AxiosRequest from "./AxiosRequest";
import appConfig from "lib/config";

class ProductTypeApi extends AxiosRequest {
  find = async () => {
    if (this.findAbort) {
      this.findAbort.abort();
    }
    this.findAbort = new AbortController();
    const result = await this.get(`/manage/product-type`, {
      signal: this.findAbort.signal,
    });
    return result;
  };

  update = async(id, model, imageFile, blurredFile) => {
    const data = new FormData();
    data.append("file", imageFile);
    data.append("blurredFile", blurredFile);
    Object.keys(model).forEach(key => {
      data.append(key, model[key])
    })
    const result = await this.put(`/manage/product-type/${id}`, {
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return result
  }

  getBaseUrl() {
    return appConfig.coreApiServicelUrl;
  }
}

export default new ProductTypeApi();
