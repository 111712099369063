import React from 'react';
import './Spinner.css';

export default ({ size = 'small', className, ...rest }) => {
    const cx = `preloader-wrapper active ${size} ${className}`
    return (
        <div className={cx} { ...rest }>
            <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="gap-patch">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    )
}