import React from "react";
import Spinner from "../Spinner";
import "./Box.css";

export default ({
  children,
  title,
  withSpinner,
  withTable,
  styles,
  renderSearch,
  renderFilter,
  renderActions,
}) => {
  const className = `box ${renderSearch ? "with-search" : ""} ${
    withTable ? "with-table" : ""
  } ${renderActions ? "with-actions" : ""}`;
  const contentStyles = styles && styles.content;
  return (
    <div className={className}>
      {(title || renderFilter || renderSearch || renderActions) && (
        <div className="box-title">
          {title && !renderFilter && (
            <span>
              {title}
              {withSpinner && (
                <Spinner size="tiny" style={{ margin: "3px 0 0 8px" }} />
              )}
            </span>
          )}

          {renderFilter && renderFilter()}
          {renderSearch && renderSearch()}
          {renderActions && renderActions()}
        </div>
      )}
      {children && (
        <div className="box-content" style={contentStyles}>
          {children}
        </div>
      )}
    </div>
  );
};
