import { connect } from 'react-redux'
import Login from 'components/Login'
import { onLoginSuccess } from 'redux/app/actions';

const mapStateToProps = state => ({
  loginError: state.app.loginError
})

const mapDispatchToProps = dispatch => ({
  onLogin: (result) => dispatch(onLoginSuccess(result))
})
    
export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);