import React from "react";
import styles from "./Section.module.css";

const Section = ({ title, subTitle, renderAction, children }) => (
  <div className={styles.section}>
    <div className={styles.header} style={ renderAction ? { paddingRight: 0 } : {}}>
      <div className={styles.headerLeft}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
      {
        renderAction && (
          <div className={styles.action}>
            {
              renderAction()
            }
          </div>
        )
      }
    </div>

    <div className={styles.body}>{children}</div>
  </div>
);

export default Section;
