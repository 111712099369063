import React from "react";
import { Redirect, Route } from "react-router-dom";

const hasAccess = (user, accessKey) => {
  if (!accessKey) {
    return true;
  }
  return user && user.roles[accessKey];
};

const PrivateRoute = ({
  component: Component,
  render,
  authenticated,
  user,
  access,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!authenticated) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (!hasAccess(user, access)) {
        if (user && user.roles.support) {
          return <Redirect to={{ pathname: "/user" }} />
        }
        if (user && user.roles.products) {
          return <Redirect to={{ pathname: "/product" }} />
        }
        return <Redirect to={{ pathname: "/login" }} />
      }
      if (render) {
        return render(props);
      }
      return <Component {...props} user={user}/>;
    }}
  />
);

export default PrivateRoute;
