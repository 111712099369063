import { toast } from "react-toastify";
import ProductTypeApi from "../../../api/ProductTypeApi";

export const FETCH_PRODUCT_TYPES_STARTED =
  "kompass/crm/productType/FETCH_PRODUCT_TYPES_STARTED";
export const FETCH_PRODUCT_TYPES_FINISHED =
  "kompass/crm/productType/FETCH_PRODUCT_TYPES_FINISHED";
export const FETCH_PRODUCT_TYPES_FAILED =
  "kompass/crm/productType/FETCH_PRODUCT_TYPES_FAILED";
export const UPDATE_FILTER =
  "kompass/crm/productType/UPDATE_FILTER";
export const UPDATE_PRODUCT_TYPE_FINISHED =
  "kompass/crm/productType/UPDATE_PRODUCT_TYPE_FINISHED";

const htmlToString = (string) => {
  return (string + "").replace(/&#\d+;/gm, function (s) {
    return String.fromCharCode(s.match(/\d+/gm)[0]);
  });
};

export const initProductTypes = () => {
  return (dispatch) => {
    dispatch(fetchProductTypesStarted());
    return ProductTypeApi.find()
      .then((r) => {
        const result = r.productTypes.reduce((result, type) => {
          if (!result[type.name]) {
            result[type.name] = type;
          }
          return result;
        }, {});
        const productTypes = Object.keys(result).map(
          (key) => result[key]
        ).sort((a,b) => a.name.localeCompare(b.name))
        return dispatch(
          fetchProductTypesFinished({
            productTypes,
          })
        );
      })
      .catch((err) => {
        if (!ProductTypeApi.isCanceled(err)) {
          toast(
            htmlToString(err.message) ||
              "Ein unerwarter Fehler ist aufgetreten.",
            { type: "error" }
          );
          return dispatch(fetchProductTypesFailed());
        }
      });
  };
}

export const fetchProductTypes = () => {
  return (dispatch) => {
    dispatch(fetchProductTypesStarted());
    return ProductTypeApi.find()
      .then((r) => {
        
        const all = r.productTypes.sort((a,b) => a.name.localeCompare(b.name))
        const notSet = all.find(item => item.id === -1)
        const validated = [
          ...all.filter(item => item.id !== -1),
          notSet
        ]
        return dispatch(
          fetchProductTypesFinished({
            productTypes: validated,
          })
        );
      })
      .catch((err) => {
        if (!ProductTypeApi.isCanceled(err)) {
          toast(
            htmlToString(err.message) ||
              "Ein unerwarter Fehler ist aufgetreten.",
            { type: "error" }
          );
          return dispatch(fetchProductTypesFailed());
        }
      });
  };
};

export const editProductType = (id, model, imageFile, blurredFile) => {
  return (dispatch) => {
    return ProductTypeApi.update(id, model, imageFile, blurredFile)
      .then((result) => dispatch(editProductTypeFinished(result.productType)))
      .then(() => dispatch(fetchProductTypes()))
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

const editProductTypeFinished = (productType) => ({
  type: UPDATE_PRODUCT_TYPE_FINISHED,
  payload: {
    productType
  },
});

export const fetchProductTypesFinished = (result) => ({
  type: FETCH_PRODUCT_TYPES_FINISHED,
  payload: {
    ...result,
  },
});

export const fetchProductTypesStarted = () => ({
  type: FETCH_PRODUCT_TYPES_STARTED,
});

export const fetchProductTypesFailed = () => ({
  type: FETCH_PRODUCT_TYPES_FAILED,
});

export const updateFilter = (filter) => ({
  type: UPDATE_FILTER,
  payload: {
    filter
  }
})
