import ProductImage from 'components/Product/ProductImage/ProductImage';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import MinusCircleIcon from 'mdi-react/MinusCircleOutlineIcon';
import moment from 'moment';
import React from 'react';
import styles from './ProductCard.module.css';
import ActionDropdown from 'components/Core/ActionDropdown';
import WarningCircleOutlineIcon from 'mdi-react/WarningCircleOutlineIcon';
import WarningCircleIcon from 'mdi-react/WarningCircleIcon';
import CalendarCheckIcon from 'mdi-react/CalendarCheckIcon';
import { Badge, Badges, ListCard } from 'components/Core/ListCard';

class ProductCard extends React.Component {

  today = new Date();

  now = moment()

  onClick = () => {
    this.props.onClick(this.props.product)
  }

  onClickShopLink = (e) => {
    e.stopPropagation();
  }

  renderPublicationBadge = (product) => {
    if (this.now.isBefore(moment(product.publicationDate))) {
      return (
        <Badge type="info" className={styles.unpublishedBadge}>
          <CalendarCheckIcon />
          <div>Nicht veröffentlicht</div>
        </Badge>
      )
    }
    return null
  }

  renderGpxDownload = (product) => {
    if (!product.gpxUrl) {
      return (
        <Badge type="warning" className={styles.warningBadge}>
          <WarningCircleOutlineIcon />
          <div>GPX-Tracks</div>
        </Badge>
      )
    }
    return null
  }

  renderGeoData = (product) => {
    if (!product.geoData) {
      return (
        <Badge type="warning" className={styles.warningBadge}>
          <WarningCircleOutlineIcon />
          <div>Blattschnitt</div>
        </Badge>
      )
    }
    return null;
  }

  renderActionDropdown = () => {
    const { product, owner, user } = this.props;
    let items = [
      {
        key: 'uploadHeader',
        type: 'header',
        name: 'Uploads'
      },
      {
        key: 'uploadGeoData',
        name: 'Blattschnitt'
      },
      {
        key: 'uploadGpxTracks',
        name: 'GPX-Tracks'
      },
      {
        key: 'linksHeader',
        type: 'header',
        name: 'Links'
      },
      {
        key: 'linkShop',
        name: `${owner.toUpperCase()} Shop`
      },
      {
        key: 'linkDetails',
        name: `Details...`
      },
    ]

    const isAdmin = user.roles.admin || user.roles.productAdmin
    return (
      <ActionDropdown items={items} payload={product} onSelect={this.onSelectAction} onMainAction={isAdmin && this.onMainAction} />
    )
  }

  onSelectAction = (key, product) => {
    switch (key) {
      case 'uploadGeoData':
        this.props.openUploadModal(product, "blattschnitt")
        break;
      case 'uploadGpxTracks':
        this.props.openUploadModal(product, "gpx")
        break;
      case 'linkShop':
        window.open(product.shopLink, '_blank').focus();
        break;
      case 'linkDetails':
        this.onClick()
        break;

      default:
        break;
    }
  }

  onMainAction = (e) => {
    e.stopPropagation();
    this.props.openEditProductModal(this.props.product)
  }

  render() {
    const { product } = this.props;

    return (
      <ListCard onClick={this.onClick}>
        <div className={styles.left}>
          {product.coverUrl && (
            <a href={product.coverUrl} target="_blank" rel="noopener noreferrer">
              <ProductImage url={product.coverUrl} className={styles.coverImage} />
            </a>
          )}
          {!product.coverUrl && (
            <ProductImage url={product.coverUrl} className={styles.coverImage} />
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <div className={styles.titleLeft}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  {product.name}
                </div>
                <div className={styles.publisherNumber}>{product.key} - {product.typeDescription}</div>
              </div>
              <Badges className={styles.badges} withSeparator>
                {product.publicationDate ? this.renderPublicationBadge(product) : null}
                {product.providesGpxData.value ? this.renderGpxDownload(product) : null}
                {product.providesGeoData.value ? this.renderGeoData(product) : null}
              </Badges>
            </div>
            {this.renderActionDropdown(product)}
          </div>
          <div className={styles.rightBottom}>
            <div className={`${styles.isbnElement}`}>
              <div className={styles.label}>
                ISBN
              </div>
              <div className={styles.valueTwoItems}>
                {
                  product.public.enforced && <WarningCircleIcon className={styles.isPublic} />
                }
                {
                  !product.public.enforced && product.public.default && <CheckCircleIcon className={styles.isPublic} />
                }
                {
                  !product.public.enforced && !product.public.default && <CloseCircleIcon className={styles.isNotPublic} />
                }
                {product.isbn}
              </div>
            </div>
            <div className={`${styles.publicationDateElement}`}>
              <div className={styles.label}>
                Veröffentlichung
              </div>
              <div className={styles.value}>
                {product.publicationDate ? moment(product.publicationDate).format("DD.MM.YYYY") : '---'}
              </div>
            </div>
            <div className={`${styles.editionElement}`}>
              <div className={styles.label}>
                Auflage
              </div>
              <div className={styles.value}>
                {product.edition || '---'}
              </div>
            </div>

            <div className={`${styles.gpxElement}`}>
              <div className={styles.label}>
                Blattschnitt
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    product.providesGeoData.value ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.simpleIcon}`} />
                  }
                  &nbsp;
                  <div>
                    {
                      product.providesGeoData.value ? 'Ja' : 'Nein'
                    }
                  </div>

                </div>
              </div>
            </div>
            <div className={styles.gpxElement}>
              <div className={styles.label}>
                GPX-Download
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    product.providesGpxData.value ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.simpleIcon}`} />
                  }
                  &nbsp;
                  {
                    product.providesGpxData.value ? 'Ja' : 'Nein'
                  }
                </div>
              </div>
            </div>
            <div className={`${styles.gpxElement}`}>
              <div className={styles.label}>
                Anzeige in App
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    product.appVisibility.value ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.simpleIcon}`} />
                  }
                  &nbsp;
                  {
                    product.appVisibility.value ? 'Aktiv' : 'Deaktiviert'
                  }
                </div>
              </div>
            </div>
            <div className={`${styles.downloadTypeElement}`}>
              <div className={styles.label}>
                Freischaltung
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    product.downloadType.value !== 'none' ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.simpleIcon}`} />
                  }
                  &nbsp;
                  {product.downloadType.value === 'map' && 'Blattschnitt'}
                  {product.downloadType.value === 'trails' && 'Touren'}
                  {product.downloadType.value === 'none' && 'Keine'}
                </div>

              </div>
            </div>
          </div>
        </div>
      </ListCard>
    )
  }
}

export default ProductCard;