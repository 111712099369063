import React from "react";
import { Checkbox } from "react-materialize";
import { useState } from "react";
import styles from "./ProductTypesFilter.module.css";
import ProductFilterBox from "components/Product/ProductFilter/ProductFilterBox";
import MapOutlineIcon from "mdi-react/MapOutlineIcon";
import RoutesIcon from "mdi-react/RoutesIcon";
import MinusCircleOutlineIcon from "mdi-react/MinusCircleOutlineIcon";

const downloadTypesValues = [
  {
    id: 1,
    label: 'Karte'
  },
  {
    id: 2,
    label: 'Touren'
  },
  {
    id: 3,
    label: 'Keine Freischaltung'
  }
]


const ProductTypesFilter = ({ filter, onSearch }) => {
  const [appVisibility, setAppVisibility] = useState(
    filter.appVisibility
  );
  const [downloadType, setDownloadType] = useState(filter.downloadType);
  const [providesGpxData, setProvidesGpxData] = useState(filter.providesGpxData);
  
  const onChange = (e) => {
    const { name, checked } = e.currentTarget;
    if (name === "appVisibility") {
      setAppVisibility(checked);
    }
    if (name === "providesGpxData") {
      setProvidesGpxData(checked);
    }
    if (onSearch) {
      onSearch({
        appVisibility,
        [name]: checked,
      });
    }
  };

  const onSelect = (item, propertyName) => {
    if (onSearch) {
      onSearch({
        ...filter,
        [propertyName]: item
      })
    }
    setDownloadType(item)
  }


  return (
    <div className={styles.container}>
      <div className={styles.title}>Produkttypen filtern</div>
      <ProductFilterBox 
        label="Freischalt-Typ" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={downloadTypesValues} 
        property="downloadType" 
        selected={downloadType}
        renderIcon={(item) => {
          if (item.id === 1) {
            return <MapOutlineIcon />
          }
          if (item.id === 2) {
            return <RoutesIcon />
          }
          if (item.id === 3) {
            return <MinusCircleOutlineIcon />
          }
          return null
        }}/>
      <div className={styles.filterElement}>
        <Checkbox
          label="GPX-Download"
          filledIn
          id="providesGpxData"
          name="providesGpxData"
          checked={providesGpxData}
          onChange={onChange}
        />
      </div>
      <div className={styles.filterElement}>
        <Checkbox
          label="Anzeige in der App"
          filledIn
          id="appVisibility"
          name="appVisibility"
          checked={appVisibility}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default ProductTypesFilter;
