import Section from "components/Core/Section";
import React from "react";
import LinkIcon from 'mdi-react/ChevronRightIcon';
import styles from "./ToursTab.module.css";
import Loading from "components/Core/Loading";
import SectionElement from "components/Core/SectionElement";

const onClick = (tour) => {
  window.open(`https://www.kompass.de/touren/detail/${tour.id}`, '_blank')
}

const renderEmptyState = (product, problems) => {
  return (
    <Section title="Zugeordnete Touren">
      {
        !product.channelId && !product.editionId && (
          <div className={styles.warning}>Diesem Produkt sind weder ein Channel noch eine Auflage zugeordnet.</div>
        )
      }
      {
        problems && problems.length > 0 && (
          <>
            <div>
              {problems.map((problem, index) => (
                <div key={problem.id} className={styles.warning} style={{ marginBottom: '16px'}}>
                  {problem.description}
                </div>
              ))}
            </div>
          </>
        )
      }
      {
        product.channelId && product.editionId && (
          <div className={styles.warning}>Diesem Produkt sind im Publication Planner <b>keine</b> Touren zugeordnet.</div>
        )
      }


    </Section >
  )
}

const renderTours = (tours, problems) => {
  return (
    <Section title="Zugeordnete Touren" subTitle={`Dem Produkt sind ${tours.length} Touren zugeordnet`}>
      {
        problems && problems.length > 0 && (
          <>
            <div>
              {problems.map((problem, index) => (
                <div key={problem.id} className={styles.warning} style={{ marginBottom: '16px'}}>
                  {problem.description}
                </div>
              ))}
            </div>
          </>
        )
      }

      <div className={styles.tours}>
        {tours.map((tour, index) => (
          <div key={tour.id} className={styles.tour} onClick={() => onClick(tour)}>
            <div className={styles.number}>{tour.number}</div>
            <div className={styles.title}>{tour.title}</div>
            <div className={styles.link}>
              <LinkIcon />
            </div>
          </div>
        ))}
      </div>

    </Section>
  )
}

const ToursTab = ({ tours, problems, product, isFetching, openVenusModal }) => {
  return (
    <>
      <Section title="Publication Planner" renderAction={() => (
        <button className={styles.venusButton} onClick={() => openVenusModal(product)}>Zuordnung bearbeiten</button>
      )}>
        <SectionElement

          label="Channel"
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {product.channelId ? (product.channelName || product.channelId) : 'Nicht zugeordnet'}
            </div>
          )}
        />
        <SectionElement
          label="Auflage"
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {product.editionId ? (product.editionName || product.editionId) : 'Nicht zugeordnet'}
            </div>
          )}
        />
      </Section>
      {
        isFetching && !tours && (
          <Section title="Zugeordnete Touren">
            <Loading />
          </Section>
        )
      }
      {
        !isFetching && !tours?.length && renderEmptyState(product, problems)
      }
      {
        !isFetching && tours?.length > 0 && renderTours(tours, problems)
      }
    </>
  )
};

export default ToursTab;
