import { connect } from "react-redux";
import { editProductType, updateFilter } from 'redux/productType/actions'
import ProductTypes from "components/ProductTypes";
import { getProductTypes } from "redux/productType/selectors/getProductTypes";

const mapStateToProps = (state) => ({
  productTypes: getProductTypes(state),
  filter: state.productType.filter,
  user: state.app.user,
  isLoading: false
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (filter) => dispatch(updateFilter(filter)),
  editProductType: (id, model, imageFile, blurredFile) => dispatch(editProductType(id, model, imageFile, blurredFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypes);
