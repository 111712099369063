import AxiosRequest from "./AxiosRequest";
import appConfig from "lib/config";

class ProductApi extends AxiosRequest {
  find = async (filter, page, company) => {
    if (this.findAbort) {
      this.findAbort.abort();
    }
    this.findAbort = new AbortController();
    const result = await this.get(`/manage/product`, {
      params: {
        owner: company,
        ...filter,
        page,
      },
      signal: this.findAbort.signal,
    });
    return result;
  };

  uploadProductFile = async(productId, file, type) => {
    const data = new FormData();
    data.append("file", file);
    const result = await this.post(`/manage/product/${productId}/${type}`, {
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return result
  }

  update = async(productId, model) => {
    const result = await this.put(`/manage/product/${productId}`, {
      data: {
        ...model
      },
    });
    return result
  }

  getBaseUrl() {
    return appConfig.coreApiServicelUrl;
  }
}

export default new ProductApi();