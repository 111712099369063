/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { Component } from 'react';
import { Button } from 'react-materialize';
import Modal from 'react-modal';
import styles from './ProductTypeEditModal.module.css';
import { toast } from 'react-toastify';
import SelectBox from 'components/Core/SelectBox';
import { getBlurredImage } from 'lib/ImageBlurring';
import ImageUpload from './ImageUpload';
import Spinner from 'components/Core/Spinner';


class ProductTypeEditModal extends Component {

  state = {
    open: false,
    isSubmitting: false,
    model: {},
    productType: {}
  }

  open = (productType) => {
    const model = {
      ...productType,
      providesGpxData: productType.providesGpxData ? 1 : 0,
      providesGeoData: productType.providesGeoData ? 1 : 0,
      appCategory: productType.appCategory ? 1 : 0,
      isUnlockable: productType.isUnlockable ? 1 : 0,
    }
    this.setState({
      open: true,
      productType,
      model,
      file: null,
      blurredFile: null,
      blurHash: null,
    })
  }

  onUpdateImage = (file, removeExisting) => {
    this.setState({
      file,
      model: {
        ...this.state.model,
        ...(removeExisting ? {
          imageUrl: null,
          blurredImageUrl: null,
          blurHash: null
        } : {})
      }
    })
  }

  openFileDialog = () => {
    if (this.dropZone) this.dropZone.open()
  }

  close = () => {
    // Don't close the modal, while saving
    if (this.state.isSubmitting) return false;

    this.setState({
      open: false
    }, () => {
      this.setState({
        productType: {},
        model: {

        }
      })
    })
  }

  hasChanged = (prop) => {
    const value = this.state.model[prop] === 1
    return this.state.productType[prop] !== value
  }

  submit = async () => {
    this.setState({ isSubmitting: true });
    const { model, productType } = this.state
    const updateModel = {
      ...(this.hasChanged('appCategory') ? { appCategory: model.appCategory === 1 } : {}),
      ...(this.hasChanged('providesGeoData') ? { providesGeoData: model.providesGeoData === 1 } : {}),
      ...(this.hasChanged('providesGpxData') ? { providesGpxData: model.providesGpxData === 1 } : {}),
      ...(this.hasChanged('isUnlockable') ? { isUnlockable: model.isUnlockable === 1 } : {}),
      ...(model.downloadType !== productType.downloadType ? { downloadType: model.downloadType } : {}),
      ...(model.name !== productType.name ? { name: model.name } : {}),
      ...(model.description !== productType.description ? { description: model.description } : {}),
      ...(model.shortDescriptionDe !== productType.shortDescriptionDe ? { shortDescriptionDe: model.shortDescriptionDe } : {}),
      ...(model.shortDescriptionEn !== productType.shortDescriptionEn ? { shortDescriptionEn: model.shortDescriptionEn } : {}),
      ...(model.imageUrl !== productType.imageUrl ? { imageUrl: model.imageUrl } : {}),
    }

    let imageFile = this.state.file || null
    let blurredFile = null
    if (this.state.blurHash) {
      updateModel.blurHash = this.state.blurHash
    }

    if (imageFile) {
      const blurringResult = await getBlurredImage(imageFile)
      blurredFile = blurringResult.blurredFile
      updateModel.blurHash = blurringResult.blurHash
    } else if (this.state.productType.imageUrl && this.state.model.imageUrl === null) {
      updateModel.blurHash = null
      updateModel.imageUrl = null
      updateModel.blurredImageUrl = null
      imageFile = null
      blurredFile = null
    }

    try {
      await this.props.onSubmit(this.state.productType.id, updateModel, imageFile, blurredFile)
      toast('Produkttyp aktualisiert', { type: 'success', autoClose: 2000 })
      this.setState({ isSubmitting: false });
      this.close();

    } catch (e) {
      this.setState({ isSubmitting: false });
      toast(e.message || 'Unerwarteter Fehler', { type: 'error' })
    }

  }

  onSelect = (value, property) => {
    this.setState({
      model: {
        ...this.state.model,
        [property]: value
      }
    })
  }

  onChangeText = (e) => {
    const { name, value } = e.currentTarget;
    this.setState({
      model: {
        ...this.state.model,
        [name]: value
      }
    })
  }

  render() {
    const { open, model, isSubmitting } = this.state;
    return (
      <Modal
        isOpen={open}
        className={`${styles.modal} modal`}
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={!isSubmitting}
        bodyClassName="modal-open"
        contentLabel="Produkttyp bearbeiten">
        <>
          <div className={styles.modalHeader}>
            <div className={styles.content}>
              Produkttyp bearbeiten
            </div>
          </div>
          <div className="modal-content">

            <div className={styles.content}>
              <div className={styles.section}>
                <div className={styles.sectionTitle} style={{ marginBottom: 0 }}>Allgemein</div>
                <div className={styles.sectionInfo}>Bei einer Änderung des Kürzels werden die Verlagsnummern aller Produkte dieses Typs aktualisiert.</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '180px' }}>
                    <label for="name" className={styles.label}>Kürzel</label>
                    <input type="text" value={model.name} name="name" className={styles.textInput} onChange={this.onChangeText} />
                  </div>

                  <div style={{ flex: 1, paddingLeft: '16px' }}>
                    <label for="description" className={styles.label}>Titel</label>
                    <input type="text" value={model.description} name="description" className={styles.textInput} onChange={this.onChangeText} />
                  </div>
                </div>

                <label className={styles.label}>Hintergrundbild</label>
                <div className={styles.imageContainer}>
                  <div className={styles.imageWrapper}>
                    <ImageUpload
                      imageUrl={model.imageUrl}
                      onUpdate={this.onUpdateImage}
                      onRe
                      isSubmitting={isSubmitting} />
                  </div>
                  <div className={styles.imageInfo}>
                    <h5>Empfehlungen</h5>
                    <ul>
                      <li>Bildbreite: 800px bis 1024px</li>
                      <li>Seitenverhältnis: ca 3:2</li>
                      <li>Maximale Bildgröße: 100KB</li>
                      <li>Kein Text, keine Logos</li>
                      <li>Keine zu hellen Fotos (z.B. keine Gletscherfotos)</li>
                    </ul>
                  </div>
                </div>
                <label for="shortDescriptionDe" className={styles.label}>Kurzbeschreibung (DE)</label>
                <textarea value={model.shortDescriptionDe} name="shortDescriptionDe" className={styles.textArea} rows="2" onChange={this.onChangeText} placeholder="Ein order zwei kurze Sätze..." />
                <label for="shortDescriptionDe" className={styles.label}>Kurzbeschreibung (EN)</label>
                <textarea value={model.shortDescriptionEn} name="shortDescriptionEn" className={styles.textArea} rows="2" onChange={this.onChangeText} placeholder="Ein oder zwei kurze Sätze..." />

                <div className={styles.sectionTitle}>Konfiguration</div>
                <div style={{ display: 'flex', marginTop: '12px' }}>
                  <div style={{ width: '180px' }}>
                    <SelectBox
                      label="Bietet GPX-Downloads"
                      onSelect={this.onSelect}
                      values={[{ id: 0, label: 'Nein' }, { id: 1, label: 'Ja' }]}
                      property="providesGpxData"
                      selected={model.providesGpxData} />
                  </div>
                  <div style={{ flex: 1, paddingLeft: '16px' }}>
                    <SelectBox
                      label="Bietet Blattschnitt"
                      onSelect={this.onSelect}
                      values={[{ id: 0, label: 'Nein' }, { id: 1, label: 'Ja' }]}
                      property="providesGeoData"
                      selected={model.providesGeoData} />
                  </div>
                </div>
                <div style={{ display: 'flex'}}>
                  <div style={{ width: '180px' }}>
                    <SelectBox
                      label="Anzeige in der App"
                      onSelect={this.onSelect}
                      values={[{ id: 0, label: 'Deaktiviert' }, { id: 1, label: 'Aktiv' }]}
                      property="appCategory"
                      selected={model.appCategory} />
                  </div>
                  <div style={{ flex: 1, paddingLeft: '16px' }}>
                    <SelectBox
                      label="Freischaltung"
                      onSelect={this.onSelect}
                      values={[{ id: 'none', label: 'Keine Freischaltung' }, { id: 'map', label: 'Blattschnitt' }, { id: 'trails', label: 'Touren' }]}
                      property="downloadType"
                      selected={model.downloadType} />
                  </div>
                </div>

              </div>
            </div>
            {
              isSubmitting && (
                <div className={styles.overlay}>
                  <Spinner size="large" className={styles.overlaySpinner} />
                </div>
              )
            }

          </div>
          <div className={styles.modalFooter}>
            <div className={styles.content}>
              <Button waves="light" onClick={this.submit} disabled={isSubmitting}>Änderungen speichern</Button>
              &nbsp;&nbsp;
              <Button waves="light" flat onClick={this.close} disabled={isSubmitting}>Abbrechen</Button>
            </div>
          </div>
        </>


      </Modal >
    )
  }
}

export default ProductTypeEditModal;