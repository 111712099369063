
import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import Page from 'components/Core/Page';
import Title from 'components/Core/Title';
import BreadCrumbLink from 'components/Core/Title/BreadCrumbLink';
import Content from 'components/Core/Content';
import Tabs from 'react-materialize/lib/Tabs';
import Tab from 'react-materialize/lib/Tab';
import TabGeneral from './tab-general';
import TabSubRegions from './tab-sub-regions';
import Alert from 'components/Core/Alert';
import ScrollToTopOnMount from 'components/Core/ScrollToTop';

class RegionDetails extends React.Component{

    onBackClick = () => {
        this.onClose();
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    openSubRegion = (subRegion) => {
        this.props.history.push(`/region/${this.props.region.id}/${subRegion.id}`)
    }

    onClose = () => {
        if (this.props.history.canGoBack) {
            this.props.history.goBack();
        } else {
            this.props.history.replace('/region')
        }
    }

    renderContent = () => {
        const { editMode, region, subRegions, countries, tab } = this.props;
        return (
            <Content>
                {
                    !editMode && <Alert type="info" style={{ marginBottom: '0px' }}>Du kannst Produkte und Subregionen nach dem Speichern hinzufügen.</Alert>
                }
                {
                    editMode && (
                        <Tabs>
                            <Tab title="Allgemein" active={tab === 'general'}>
                                <TabGeneral 
                                    region={region}
                                    countries={countries}
                                    onSubmit={this.props.onUpdateRegion} 
                                    editMode/>
                            </Tab>
                            <Tab title="Subregionen" active={tab === 'subregions'}>
                                <TabSubRegions 
                                    region={region} 
                                    subRegions={subRegions}
                                    openSubRegion={this.openSubRegion}/>
                            </Tab>
                        </Tabs>
                    )
                }
                {
                    !editMode && (
                        <Fragment>
                            <br />
                            <TabGeneral 
                                region={region} 
                                editMode={editMode} 
                                onSubmit={this.props.onCreateRegion}
                                onClose={this.onClose} />
                        </Fragment>
                    )
                }
            </Content>
        )
    }
    
    render() {
        const { editMode, region, isLoading } = this.props;
        return (
            <Page>
                <ScrollToTopOnMount />
                <Title 
                    onBackClick={this.onBackClick}
                    renderBreadCrumbs={() => {
                        return (
                            <Fragment>
                                <BreadCrumbLink  to="/region">Regionen</BreadCrumbLink>
                            </Fragment>
                        )
                    }}>
                        { !editMode && 'Neue Region erstellen' }
                        {  editMode && !isLoading && region.name }
                        { editMode && isLoading && <span style={{ background: '#eee', width: '200px', height: '20px', display: 'inline-block', borderRadius: '2px'}}></span> }
                </Title>
                { !isLoading && this.renderContent()}
            </Page>
        )
    }
}

export default withRouter(RegionDetails);
