import DestinationsApi from "api/DestinationsApi";
export const FETCH_SUBREGION =
  "kompass/crm/destination/subRegion/FETCH_SUBREGION";
export const FETCH_SUBREGION_SUCCESS =
  "kompass/crm/destination/subRegion/FETCH_SUBREGION_SUCCESS";
export const RESET_SUBREGION =
  "kompass/crm/destination/subRegion/RESET_SUBREGION";

export const UPDATE_SUBREGION = "kompass/crm/region/subRegion/UPDATE_SUBREGION";
export const UPDATE_SUBREGION_SUCCESS =
  "kompass/crm/region/subRegion/UPDATE_SUBREGION_SUCCESS";
export const UPDATE_SUBREGION_FAILED =
  "kompass/crm/region/subRegion/UPDATE_SUBREGION_FAILED";

export const ADD_PRODUCT_TO_SUBREGION =
  "kompass/crm/region/subRegion/ADD_PRODUCT_TO_SUBREGION";
export const ADD_PRODUCT_TO_SUBREGION_SUCCESS =
  "kompass/crm/region/subRegion/ADD_PRODUCT_TO_SUBREGION_SUCCESS";
export const ADD_PRODUCT_TO_SUBREGION_FAILED =
  "kompass/crm/region/subRegion/ADD_PRODUCT_TO_SUBREGION_FAILED";

export const REMOVE_PRODUCT_FROM_SUBREGION =
  "kompass/crm/region/subRegion/REMOVE_PRODUCT_FROM_SUBREGION";
export const REMOVE_PRODUCT_FROM_SUBREGION_SUCCESS =
  "kompass/crm/region/subRegion/REMOVE_PRODUCT_FROM_SUBREGION_SUCCESS";
export const REMOVE_PRODUCT_FROM_SUBREGION_FAILED =
  "kompass/crm/region/subRegion/REMOVE_PRODUCT_FROM_SUBREGION_FAILED";

const fetchSubRegionStarted = () => ({
  type: FETCH_SUBREGION,
});

const fetchSubRegionfinished = (result) => ({
  type: FETCH_SUBREGION_SUCCESS,
  payload: {
    ...result,
  },
});

export const fetchSubRegion = (id) => {
  return (dispatch) => {
    dispatch(fetchSubRegionStarted());
    return DestinationsApi.findSubRegion(id)
      .then((r) => {
        if (r.success) {
          return dispatch(fetchSubRegionfinished(r));
        } else {
          throw new Error(r.message);
        }
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };
};

export const resetSubRegion = () => ({
  type: RESET_SUBREGION,
});

export const updateSubRegion = (id, model) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SUBREGION });
    return DestinationsApi.updateSubRegion(id, model)
      .then((r) => {
        if (r.success) {
          return dispatch({
            type: UPDATE_SUBREGION_SUCCESS,
            payload: {
              id,
              ...model,
            },
          });
        } else {
          throw new Error(r.message);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: UPDATE_SUBREGION_FAILED });
        return Promise.reject(err);
      });
  };
};

export const addProductToSubregion = (subRegion, product) => {
  return (dispatch, getState) => {
    const { products } = getState().destination.subRegion;
    if (products && products.find((p) => p.product_id === product.product_id)) {
      return Promise.reject(
        new Error("Dieses Produkt ist bereits der Subregion zugeordnet.")
      );
    }
    dispatch({ type: ADD_PRODUCT_TO_SUBREGION });
    return DestinationsApi.addProductToSubRegion(
      subRegion.id,
      product.product_id
    )
      .then((r) => {
        if (r.success) {
          return dispatch({
            type: ADD_PRODUCT_TO_SUBREGION_SUCCESS,
            payload: {
              product,
            },
          });
        } else {
          throw new Error(r.message);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ADD_PRODUCT_TO_SUBREGION_FAILED });
        return Promise.reject(err);
      });
  };
};

export const removeProductFromSubregion = (subRegion, product) => {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_PRODUCT_FROM_SUBREGION });
    return DestinationsApi.removeProductToSubRegion(
        subRegion.id,
        product.product_id
      )
      .then((r) => {
        if (r.success) {
          return dispatch({
            type: REMOVE_PRODUCT_FROM_SUBREGION_SUCCESS,
            payload: {
              product,
            },
          });
        } else {
          throw new Error(r.message);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: REMOVE_PRODUCT_FROM_SUBREGION_FAILED });
        return Promise.reject(err);
      });
  };
};
