import React, { Component } from 'react';
import Downshift from 'downshift';
import styles from './ActionDropdown.module.css';
import MoreCircleOutlineIcon from 'mdi-react/MoreVertIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';

class ActionDropdown extends Component {

  onSelect = (e, item) => {
    e.stopPropagation();
    this.props.onSelect(item.key, item.payload || this.props.payload)
    this.button.blur();
  };

  renderIcon = (item) => {
    return null
  }


  render() {
    const { items, classes, onMainAction, renderIcon } = this.props
    return (
      <Downshift itemToString={item => item ? item.id : ''}>
        {({ getToggleButtonProps, getItemProps, isOpen }) => (
          <span style={{ position: 'relative', display: 'block' }} onClick={e => e.stopPropagation()}>
            <div className={styles.buttonContainer}>
              {
                onMainAction && (
                  <button className={styles.mainAction} onClick={onMainAction}>
                    <CogOutlineIcon />
                  </button>
                )
              }

              <button
                ref={c => this.button = c}
                className={`${styles.dropdownButton} ${isOpen ? styles.open : ''} ${classes && classes.dropdownButton ? classes.dropdownButton : ''}`}
                type="button"
                {...getToggleButtonProps({
                  onClick: (e) => e.stopPropagation()
                })}
              >
                {
                  renderIcon && renderIcon()
                }
                {
                  !renderIcon && <MoreCircleOutlineIcon className={styles.chevron} />
                }
                
              </button>
            </div>
            {isOpen && (
              <div
                className={`${styles.dropdownMenu} ${classes?.dropdownMenu || ''}`}
                align={this.props.align}
                offsetTop={this.props.offsetTop}
                offsetLeft={this.props.offsetLeft}
                offsetRight={this.props.offsetRight}
                width={this.props.width}
              >
                {
                  items.map(item => {
                    if (item.type === 'header') {
                      return (
                        <div key={item.key} className={styles.dropdownHeader}>{item.name}</div>
                      )
                    }
                    if (item.type === 'separator') {
                      return (
                        <div key={item.key} className={styles.dropdownSeparator} />
                      )
                    }
                    return (
                      <a
                        key={item.key}
                        className={`${styles.dropdownItem} ${item.icon ? styles.withIcon : ''}`}
                        {...getItemProps({
                          item,
                          onClick: e => this.onSelect(e, item)
                        })}
                      >
                        {
                          item.icon && this.renderIcon(item)
                        }
                        <div className={styles.dropdownItemLabel}>{item.name}</div>
                      </a>
                    )
                  })
                }
              </div>
            )}
          </span>
        )}
      </Downshift>
    );
  }
}

export default ActionDropdown;
