import Section from "components/Core/Section";
import React from "react";
import styles from "./MediaTab.module.css";

const MediaTab = ({ productType }) => {
  return (
    <>
      <Section title="Hintergrundbild">
        <div className={`${styles.container}`}>
          {
            productType.imageUrl && <a href={productType.imageUrl} target="_blank" rel="noopener noreferrer"> <img src={productType.imageUrl} alt="Hintergrundbild"/></a>
          }
          {
            !productType.imageUrl && <div>Nicht vorhanden</div>
          }

        </div>
      </Section>
      <Section title="Hintergrundbild (Blurred)">
        <div className={`${styles.container}`}>
          {
            productType.blurredImageUrl && <a href={productType.blurredImageUrl} target="_blank" rel="noopener noreferrer"> <img src={productType.blurredImageUrl} alt="Hintergrundbild (Blurred)"/></a>
          }
          {
            !productType.blurredImageUrl && <div>Nicht vorhanden</div>
          }
        </div>
      </Section>
      <Section title="BlurHash">
        <div className={`${styles.container}`}>
          {
            productType.blurHash || <div>Nicht vorhanden</div>
          }
        </div>
      </Section>
    </>

  )
};

export default MediaTab;
