import AxiosRequest from "./AxiosRequest";
import appConfig from "lib/config";

class TourApi extends AxiosRequest {
  find = async (channelId, editionId) => {
    if (this.findAbort) {
      this.findAbort.abort();
    }
    this.findAbort = new AbortController();
    const result = await this.get(`/manage/tour`, {
      params: {
        channelId,
        editionId,
        pick: 'number,title,id'
      },
      signal: this.findAbort.signal,
    });
    return result;
  };

  syncEdition = async (channelId, channelName, editionId) => {
    if (this.findAbort) {
      this.findAbort.abort();
    }
    this.findAbort = new AbortController();
    const result = await this.post(`/manage/publication/sync/${channelId}/${editionId}`, {
      data: {
        channelName
      },
      params: {
        pick: 'number,title,id'
      },
      signal: this.findAbort.signal,
    });
    return result;
  }

  getBaseUrl() {
    return appConfig.coreApiServicelUrl;
  }
}

export default new TourApi();