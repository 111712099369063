import React from 'react';
import { Table } from 'react-materialize';
import './Table.css';

export default ({ children, className, selectable, clickable, ...rest }) => {
    const clazzName = `kompass-table ${className || ''} ${selectable ? 'selectable' : ''} ${clickable ? 'clickable' : ''}`;

    return (
        <Table className={clazzName} { ...rest }>
            { children }
        </Table>
    )

}