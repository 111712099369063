import Section from "components/Core/Section";
import SectionElement from "components/Core/SectionElement";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import MinusCircleIcon from "mdi-react/MinusCircleIcon";
import moment from "moment";
import React from "react";
import styles from "./OverviewTab.module.css";

const OverviewTab = ({ productType, openUploadModal }) => {

  return (
    <div>
      <Section title="Auf einen Blick">
        <SectionElement label="Kürzel" value={productType.name} />
        <SectionElement label="Titel" value={productType.description} />
        <SectionElement label="VLB-Code" value={productType.vlbShortcode} copyable />
        <SectionElement label="ID" value={productType.id} copyable />
        <SectionElement
          label="Zuletzt aktualisiert"
          value={moment(productType.updated).format("DD.MM.YYYY HH:mm")}
        />
        <SectionElement
          label="Bietet GPX-Downloads"
          fixedWidth
          status={productType.providesGpxData ? "success" : "inactive"}
          renderValue={() => (
            <>
              {productType.providesGpxData && (
                <div style={{ display: "flex" }}>
                  <CheckCircleIcon />
                  <span>Ja</span>
                </div>
              )}
              {!productType.providesGpxData && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Nein</span>
                </div>
              )}
            </>
          )}
        />
        <SectionElement
          label="Wird in der App angezeigt"
          fixedWidth
          status={productType.appCategory ? "success" : "inactive"}
          renderValue={() => (
            <>
              {productType.appCategory && (
                <div style={{ display: "flex" }}>
                  <CheckCircleIcon />
                  <span>Ja</span>
                </div>
              )}
              {!productType.appCategory && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Nein</span>
                </div>
              )}
            </>
          )}
        />
        <SectionElement
          label="Freischaltung"
          fixedWidth={productType.downloadType === 'none'}
          status={productType.downloadType === 'none' ? 'inactive' : null}
          renderValue={() => (
            <>
              {productType.downloadType === 'none' && (
                <div style={{ display: "flex" }}>
                  <MinusCircleIcon />
                  <span>Nein</span>
                </div>
              )}
              {productType.downloadType === 'map' && <div>Blattschnitt</div>}
              {productType.downloadType === 'trails' && <div>Touren</div>}
            </>
          )}
        />
      </Section>
      <Section title="Beschreibung (DE)">
        <div className={`${styles.container}`}>
          {productType.shortDescriptionDe || 'Nicht vorhanden.'}
        </div>
      </Section>
      <Section title="Beschreibung (EN)">
        <div className={`${styles.container}`}>
          {productType.shortDescriptionEn || 'Nicht vorhanden.'}
        </div>
      </Section>
    </div>
  );
};

export default OverviewTab;
