import moment from "moment";
import {
  SEARCH_USER_STARTED,
  SEARCH_USER_FAILED,
  SEARCH_USER_FINISHED,
  PAGINATE,
  ADD_DAYS_FINISHED,
  UPDATE_USER_FINISHED,
  USER_DELETED,
  RESET
} from "./actions";
import { LOGOUT } from "../app/actions";

const PAGE_SIZE = 30

const buildPagination = (items, total, currentPage) => {
  if (!items) {
    return {
      resultSize: 0,
      page: 0,
      totalPages: 0,
    };
  }

  const totalPages = Math.ceil(total / PAGE_SIZE);
  return {
    resultSize: total,
    page: currentPage,
    totalPages,
  };
};

const defaultState = {
  users: null,
  fetchOnLoad: true,
  filter: {
    q: '',
    page: 0,
    limit: 30,
    renewalFailed: null,
    status: null
  },
  pagination: buildPagination(null),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET:
      return Object.assign({}, state, {
        ...state,
        fetchOnLoad: false,
        isLoading: false,
        users: null,
        pagination: null,
      });
    case SEARCH_USER_STARTED:
      return Object.assign({}, state, {
        ...state,
        fetchOnLoad: false,
        isLoading: true,
        filter: action.filter,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      });
    case PAGINATE:
      return Object.assign({}, state, {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      });
    case SEARCH_USER_FAILED:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        pagination: buildPagination(null),
      });
    case SEARCH_USER_FINISHED: {
      return Object.assign({}, state, {
        ...state,
        fetchOnLoad: false,
        isLoading: false,
        users: action.payload.users,
        pagination: buildPagination(action.payload.users, action.payload.total, state.pagination.page),
      });
    }

    case ADD_DAYS_FINISHED: {
      const users = state.users.map((u) => {
        if (u.ssoId === action.payload.ssoId.toString()) {
          u.premiumTill = new moment(action.payload.endDate);
        }
        return u;
      });

      state = { ...state, users };
      break;
    }
    case USER_DELETED: {
      const users = state.users.filter((u) => u.ssoId !== action.payload.ssoId);
      return Object.assign({}, state, {
        ...state,
        users,
      });
    }
    case UPDATE_USER_FINISHED: {
      const users = state.users.map((u) => {
        if (u.ssoId === action.payload.ssoId.toString()) {
          return action.payload.user
        }
        return u;
      });

      state = { ...state, users };
      break;
    }
    case LOGOUT: {
      state = defaultState;
      break;
    }
    default:
      return state;
  }
  return state;
};
