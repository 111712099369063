import React from 'react';

export default ({ children, style, className = '', width, ...rest }) => {
    const styles = (width) ? {
        ...width ? { width, minWidth: width } : null
    } : null;
    return (
        <th className={className} style={styles} { ...rest }>
            {children}
        </th> 
    )
}