
import React from 'react'
import Box from 'components/Core/Box';
import Flag from 'react-world-flags'
import { Table, TableHeader, TableHeaderItem, TableRow, TableCell } from 'components/Core/Table';

class RegionsTable extends React.Component{

    renderTableRows(){
        const { regions, openRegion } = this.props;
        if (regions && regions.length) {
            return regions.map(region => (
                <TableRow key={region.id} onClick={() => openRegion(region.id)}>
                    <TableCell>
                        <Flag code={ region.country_iso_code } width="24px"/>
                    </TableCell>
                    <TableCell>{ region.name }</TableCell>
                    <TableCell>{ region.key }</TableCell>
                    <TableCell className={region.visible === '1' ? 'light-green-text dark text-darken-2' : 'amber-text dark text-darken-2'}>{ region.visible === '1' ? 'Veröffentlicht' : 'Unsichtbar' }</TableCell>
                </TableRow>
            ))
        }

        return (
            <TableRow>
                <TableCell colSpan="6" empty>Keine Regionen gefunden.</TableCell>
            </TableRow>
        )
        
    }

    onAddRegion = () => {
        this.props.history.push('/region/new');
    }
    
    
    render() {
        return (
            <Box withTable>
                <Table selectable>
                    <TableHeader>
                        <TableHeaderItem width="70px">Land</TableHeaderItem>
                        <TableHeaderItem>Bezeichnung</TableHeaderItem>
                        <TableHeaderItem width="300px">Key</TableHeaderItem>
                        <TableHeaderItem width="120px">Status</TableHeaderItem>
                    </TableHeader>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>       
                </Table>
            </Box>
        )
    }
}

export default RegionsTable;
