import React, { Component } from "react";
import { withRouter, matchPath, Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-materialize";
import UserDropdown from "./UserDropdown";
import "./NavBar.css";
import Logo from "./logo_kompass.png";

class NavBar extends Component {
  isActivePath = (path) => {
    const result = matchPath(this.props.location.pathname, path);
    return !!result;
  };

  isActive = (path) => {
    let paths = Array.isArray(path) ? path : [];
    let result = false;
    let index = 0;
    while (!result && index < paths.length) {
      result = this.isActivePath(paths[index]);
      index++;
    }
    return result;
  };

  hasAccess = () => {};

  render() {
    const { logout, user } = this.props;
    return (
      <Navbar
        brand={
          <Link to="/">
            <img src={Logo} alt="logo" height="28px" width="28px" />
            <span className="title">CRM</span>
          </Link>
        }
        alignLinks="right"
        className="nav-bar"
        fixed
      >
        {
          user.roles.support && (
            <NavLink to="/user" activeClassName="active">
              Benutzer
            </NavLink>
          )
        }
        {
          (user.roles.products || user.roles.productAdmin) && (
            <NavLink to="/product" activeClassName="active">
              Produkte
            </NavLink>
          )
        }
        {
          (user.roles.products || user.roles.productAdmin) && (
            <NavLink to="/product-types" activeClassName="active">
              Produkttypen
            </NavLink>
          )
        }
       
        {
          user.roles.regions && (
            <NavLink to="/region" activeClassName="active">
              Regionen
            </NavLink>
          )
        }
        {
          user.roles.regions && (
            <NavLink to="/apidocs" activeClassName="active">
              Api Docs
            </NavLink>
          )
        }
        <UserDropdown logout={logout} user={user} />
      </Navbar>
    );
  }
}

export default withRouter(NavBar);
