import React from "react";
import { Blurhash } from "react-blurhash";
import { useImage } from "react-image";
import ErrorBoundary from "./ErrorBoundary";
import styles from './ProductTypeImage.module.css'

const ProductTypeImage = ({ url, blurHash, renderName, className = '' }) => {
  const { src, isLoading, error } = useImage({
    srcList: url ? [url] : null,
    useSuspense: false
  });
  return (
    <div className={`${styles.container} ${className}`}>
      {
        isLoading && blurHash && <Blurhash width={180} height={94} hash={blurHash} />
      }
      {
        !isLoading && !error && <img src={src} alt="Cover" title={url ? (error ? 'Nicht gefunden' : 'Cover-Bild') : 'Nicht verfügbar'} />
      }
      {
        !isLoading && !error && <div className={styles.overlay} />
      }
      {
        renderName(isLoading, error)
      }

    </div>
  )
};

export default ({ url, blurHash, renderName, className = '' }) => {
  return (
    <ErrorBoundary>
      <ProductTypeImage url={url} blurHash={blurHash} className={className} renderName={renderName} />
    </ErrorBoundary>
  )
};
