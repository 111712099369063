import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    SESSION_AUTHENTICATED,
    SESSION_NOT_AUTHENTICATED
} from './actions';

const defaultState = {
    authenticated: false,
    initialized: false,
    loginError: ""

}

export default (state=defaultState, action) => {
    switch(action.type){
      case LOGIN_SUCCESS:
        state = {
          ...state,
          authenticated: true,
          ...action.payload
        }
        break;
      case LOGIN_ERROR:
        state = {
          ...state,
          authenticated: false,
          loginError: action.payload.message
        }
        break;
      case SESSION_AUTHENTICATED:
          state = {
            ...state,
            authenticated: true,
            initialized: true,
            ...action.payload
          }
          break;
      case SESSION_NOT_AUTHENTICATED:
          state = {
            ...state,
            authenticated: false,
            initialized: true,
            ...action.payload
          }
          break;
      case LOGOUT:{
        state = {
          authenticated: false,
          initialized: true,
          loginError: ""
        }
        break;
      }
      default:
        return state;
    }
    return state;
}