

import ApiDocs from 'components/ApiDocs'
import {connect} from 'react-redux'
import { searchUser, addDays } from 'redux/support/actions'

const mapStateToProps = state => ({
  kompassUser: state.support.kompassUser,
  searchTerm: state.support.searchTerm,
  isLoading: state.support.isLoading
}) 

const mapDispatchToProps = dispatch => ({
  searchUser: (searchTerm) => dispatch(searchUser(searchTerm)),
  addDays: (ssoId, days) => dispatch(addDays(ssoId, days))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiDocs)
