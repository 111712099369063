import React, { Component } from 'react';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import styles from './ProductTypeFilter.module.css';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import FilterVariantIcon from 'mdi-react/FilterVariantIcon';

class ProductTypeFilter extends Component {
  
  onSelect = productType => {
    this.props.onSelect(productType.id !== 'all_items' ? productType.id : null, this.props.property)
    this.button.blur();
  };

  getCompanySpecificTypes = () => {
    const { productTypes, user } = this.props;
    return productTypes.filter(type => type.owner === user.company || type.id < 0)
  }

  render() {
    const { selected, productTypes, user } = this.props
    if (!productTypes) return null
    const types = this.getCompanySpecificTypes();
    const selectedValue = selected ? types.find(t => t.id === selected) : null
    return (
      <Downshift onChange={this.onSelect} itemToString={item => item ? item.id : ''}>
        {({ getToggleButtonProps, getItemProps, isOpen }) => (
          <span style={{ position: 'relative' }}>
            <div className={styles.buttonContainer}>
              <div className={styles.label}>Produkttyp</div>
              <button
                ref={c => this.button = c}
                className={`${styles.dropdownButton} ${isOpen ? styles.open : ''} ${styles[user.company]}`}
                type="button"
                {...getToggleButtonProps()}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                  selectedValue && (
                    <>
                      <b style={{ marginRight: '8px', background: 'rgb(219 246 244)', color: 'rgb(31 145 135)', padding: '3px 5px', borderRadius: '4px' }}>{selectedValue.name}</b>
                      <span className={styles.itemLabel}>{selectedValue.id < 0 ? 'Keine Zuordnung' : selectedValue.description}</span>
                    </>
                  )
                }

                {
                  !selectedValue && (
                    <>
                      <FilterVariantIcon className={styles.filterIcon}/>
                      <span className={styles.itemEmptyLabel}>Alle Produkttypen</span>
                    </>
                  )
                }
                </div>
                <ChevronDownIcon />
              </button>
            </div>
            {isOpen && (
              <div
                className={`${styles.dropdownMenu} ${styles[user.company]}`}
                align={this.props.align}
                offsetTop={this.props.offsetTop}
                offsetLeft={this.props.offsetLeft}
                offsetRight={this.props.offsetRight}
                width={this.props.width}
              >
                <a
                    key="-1"
                    className={`${styles.dropdownItem} ${styles.allItems}`}
                    {...getItemProps({
                      item: {
                        id: 'all_items'
                      }
                    })}
                  >
                    <div>
                      <div className={styles.navLabel} style={{display: 'flex'}}>
                        <div>Alle Produkttypen</div>
                      </div>
                    </div>
                    
                  </a>
                {
                  types.map(item => (
                    <a
                    key={item.id}
                    className={`${styles.dropdownItem} ${item.id === -1 ? styles.unset : ''}`}
                    {...getItemProps({
                      item,
                    })}
                  >
                    <div>
                      <div className={styles.navLabel} style={{display: 'flex'}}>
                        <div style={{ width: '60px', fontWeight: 'bold'}}>{item.name}</div>
                        { /* <div style={{ width: '90px'}}>{item.vlb_shortcode || '-'}</div> */ }
                        {
                          item.id === -1 && (
                            <div>Keine Zuordnung</div>
                          )
                        }
                        {
                          item.id > -1 && (
                            <div>{item.description && item.description !== '-' ? item.description : 'Keine Angabe'}</div>
                          )
                        }
                      </div>
                    </div>
                    
                  </a>
                  ))
                }
              </div>
            )}
          </span>
        )}
      </Downshift>
    );
  }
}

const mapStateToProps = (state) => ({
  productTypes: state.productType.data
})

export default connect(mapStateToProps)(ProductTypeFilter)
