/* eslint  jsx-a11y/anchor-is-valid:0 */
import React, { Fragment } from 'react';
import TabContent from 'components/Core/TabContent';
import Button from 'react-materialize/lib/Button';
import ProductSelectorModal from 'components/Product/ProductSelectorModal';
import ProductTable from 'components/Product/ProductTable';

class Products extends React.Component{

    openProductSelector = () => {
        this.productSelector.open()
    }

    render() {
        return (
            <TabContent clearMargin>
                <ProductTable
                    title="Zugeordnete Produkte"
                    products={this.props.products}
                    hideFiles
                    hideLinks
                    renderActions={() => {
                        return (
                            <div style={{ }}>
                                <Button waves="light" onClick={this.openProductSelector}>Produkt zuordnen</Button>
                            </div>
                            
                        )
                    }} 
                    renderRowActions={(product) => (
                        <Fragment>
                            <a className="red-text remove-link" onClick={() => this.props.removeProductFromSubregion(product)}>Entfernen</a>
                        </Fragment>
                    )}
                    />
                <ProductSelectorModal
                    ref={c => this.productSelector = c} 
                    addProductToSubregion={this.props.addProductToSubregion}/>
            </TabContent>
        )
    }
}

export default Products;
