import React, { useCallback, useState } from 'react'
import UploadIcon from 'mdi-react/FileUploadOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteOutlineIcon';
import { useDropzone } from 'react-dropzone'
import styles from './ImageUpload.module.css'

function ImageUpload({ imageUrl, onUpdate, isSubmitting }) {
  const [file, setFile] = useState(null)
  const [existingImageRemoved, setExistingImageRemoved] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length) {
      const selectedFile = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      })
      setFile(selectedFile)
      onUpdate(selectedFile)
    }
  }, [onUpdate])

  const removeImage = () => {
    setFile(null)
    onUpdate(null, true)
    if (imageUrl) {
      setExistingImageRemoved(true)
    }
  }
  // onDrop={this.onDrop} noClick noKeyboard ref={c => this.dropZone = c} accept={['image/jpeg', 'image/jpg']} multiple={false} maxSize={1048576}
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: ['image/jpeg', 'image/jpg'],
    maxSize: 1048576,
    multiple: false
  })

  return (
    <div {...getRootProps({
      className: `${styles.fileDropZone} ${isDragReject ? 'drag-reject' : ''} ${(file || (imageUrl && !existingImageRemoved)) ? styles.hasImage : ''}`,
      style: {
        ...(isDragActive ? { backgroundColor: '#e6f4ff', borderColor: 'transparent' } : {}),
        ...(isDragAccept ? { backgroundColor: '#eaffe6', borderColor: '#76a37e' } : {}),
        ...(isDragReject ? { backgroundColor: '#ffd3c8', borderColor: 'transparent' } : {})
      }
    })}>
      <input {...getInputProps()} />
      {
        !file && (imageUrl && !existingImageRemoved) && (
          <img src={imageUrl} alt="Hintergrundbild" />
        )
      }
      {file && (
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          alt="Vorschau"
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      )}
      {((!file && !imageUrl) || (!file && existingImageRemoved)) && (
        <div className={styles.emptyState}>
          <div>
            <UploadIcon className="upload-icon" />

            <p>Ziehe eine Datei hierher oder<br />
              <button className={styles.selectFileButton} onClick={open}>wähle eine aus</button>.</p>
          </div>
        </div>
      )}
      {
        ((file || (imageUrl && !existingImageRemoved)) && (
          <div className={styles.actions}>
            <button className={styles.action} onClick={open} disabled={isSubmitting}>
              <UploadIcon />
            </button>
            <button className={styles.action} onClick={removeImage} disabled={isSubmitting}>
              <DeleteIcon />
            </button>
          </div>
        ))
      }
    </div>
  )
}

export default ImageUpload