import moment from "moment";
import React, { Component } from "react";
import PlusIcon from "mdi-react/PlusCircleIcon";
import styles from "./UserHistoryTab.module.css";

class UserHistoryTab extends Component {
  renderInfo = (subscription) => {
    if (subscription.platform === 'web' && subscription.action === 'payment' && subscription.provider === 'stripe') {
      return <div className={styles.noInfo}>Kauf Website</div>;
    }
    if (!subscription.info?.length) {
      return <div className={styles.noInfo}>Keine Info verfügbar</div>;
    }
    if (
      subscription.info === "free_month" ||
      subscription.info === "free_trial"
    ) {
      return <div className={styles.noInfo}>Free Trial</div>;
    }
    if (
      subscription.info.indexOf("KOMPASSTRIAL") > -1 &&
      subscription.platform === "web"
    ) {
      return <div className={styles.noInfo}>Kauf Website</div>;
    }
    if (subscription.info === "CRM") {
      return <div className={styles.noInfo}>Laufzeit manuell aktualisiert</div>;
    }
    if (
      subscription.info.indexOf("purchasekompasspro") > -1 &&
      subscription.platform === "android"
    ) {
      return <div className={styles.noInfo}>Initialer Kauf</div>;
    }
    if (subscription.info === "2" && subscription.platform === "android") {
      return <div className={styles.noInfo}>Abo verlängert</div>;
    }
    if (subscription.info === "3" && subscription.platform === "android") {
      return <div className={styles.noInfo}>Abo gekündigt</div>;
    }
    if (subscription.info === "5" && subscription.platform === "android") {
      return <div className={styles.noInfo}>Abo gesperrt</div>;
    }
    if (subscription.info === "6" && subscription.platform === "android") {
      return (
        <div className={styles.noInfo}>
          Problem mit Zahlung/in Zahlungsfrist
        </div>
      );
    }
    if (subscription.info === "7" && subscription.platform === "android") {
      return <div className={styles.noInfo}>Abo reaktiviert</div>;
    }
    if (subscription.info === "8" && subscription.platform === "android") {
      return <div className={styles.noInfo}>Preisänderung akzeptiert</div>;
    }
    if (subscription.info === "13" && subscription.platform === "android") {
      return <div className={styles.noInfo}>Abo abgelaufen</div>;
    }
    if (subscription.platform === "android") {
      return <div className={styles.noInfo}>Code {subscription.info}</div>;
    }

    if (
      subscription.info === "INITIAL_BUY" &&
      subscription.platform === "ios"
    ) {
      return <div className={styles.noInfo}>Bestätigung initialer Abokauf</div>;
    }
    if (subscription.info === "purchase" && subscription.platform === "ios") {
      return <div className={styles.noInfo}>Initialer Kauf</div>;
    }
    if (subscription.info === "DID_RENEW" && subscription.platform === "ios") {
      return <div className={styles.noInfo}>Abo automatisch verlängert</div>;
    }
    if (
      subscription.info === "INTERACTIVE_RENEWAL" &&
      subscription.platform === "ios"
    ) {
      return <div className={styles.noInfo}>Abo durch User verlängert</div>;
    }
    if (
      subscription.info === "DID_CHANGE_RENEWAL_STATUS" &&
      subscription.platform === "ios"
    ) {
      return (
        <div className={styles.noInfo}>
          Automat. Aboverlängerung aktiviert/deaktiviert
        </div>
      );
    }
    if (
      subscription.info === "DID_CHANGE_RENEWAL_STATUS_true" &&
      subscription.platform === "ios"
    ) {
      return (
        <div className={styles.noInfo}>Automat. Aboverlängerung aktiviert</div>
      );
    }
    if (
      subscription.info === "DID_CHANGE_RENEWAL_STATUS_false" &&
      subscription.platform === "ios"
    ) {
      return (
        <div className={styles.noInfo}>
          Automat. Aboverlängerung deaktiviert
        </div>
      );
    }
    if (
      subscription.info === "DID_FAIL_TO_RENEW" &&
      subscription.platform === "ios"
    ) {
      return <div className={styles.noInfo}>Fehler bei Aboverlängerung</div>;
    }
    if (
      subscription.info === "DID_RECOVER" &&
      subscription.platform === "ios"
    ) {
      return (
        <div className={styles.noInfo}>
          Automat. Aboverlängerung nach gelöstem Zahlungsproblem
        </div>
      );
    }

    return <div className={styles.noInfo}>{subscription.info}</div>;
  };

  renderPlatform = (subscription) => {
    if (subscription.provider === "stripe") {
      return "STRIPE";
    }
    if (subscription.platform === "ios") {
      return "iOS";
    }
    if (subscription.platform === "android") {
      return "Android";
    }
    if (subscription.platform === "web" && subscription.info === "CRM") {
      return "CRM";
    }
    return subscription.platform.toUpperCase();
  };

  render() {
    const { subscriptions, isFetching } = this.props;
    if (isFetching) {
      return <div>Laden...</div>
    }
    if (!subscriptions?.length) {
      return <div className={styles.card}>Zu diesem User liegen keine Daten zum Abo oder Käufen vor.</div>
    };

    return (
      <div>
        {subscriptions.map((subscription) => (
          <div key={subscription.id} className={styles.card}>
            <div className={styles.top}>
              <div className={styles.created}>
                {moment(subscription.created).format("DD.MM.YYYY HH:mm:ss")}
              </div>
              <div
                className={`${styles.platform} ${
                  subscription.info === "CRM"
                    ? styles.crm
                    : styles[subscription.provider === 'stripe' ? 'stripe' : subscription.platform.toLowerCase()]
                }`}
              >
                {this.renderPlatform(subscription)}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>{this.renderInfo(subscription)}</div>
              {subscription.days > 0 && (
                <div className={styles.days}>
                  <PlusIcon />
                  {subscription.days} Tage&nbsp;<span>→</span>&nbsp;
                </div>
              )}
              <div className={styles.endDate}>
                {subscription.endDate &&
                  moment(subscription.endDate).format("DD.MM.YYYY")}
              </div>
            </div>
            {subscription.info && subscription.info !== "CRM" && (
              <div style={{ lineHeight: "14px", fontSize: "12px", fontFamily: 'monospace', color: '#6a7174'}}>
                {subscription.platform === "android" ? "Android: " : ""}
                {subscription.info}
              </div>
            )}
            <div className={styles.raw}>
              {JSON.stringify(subscription, null, 2)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default UserHistoryTab;
