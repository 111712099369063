import DetailsOverlay from "components/Core/DetailsOverlay";
import React, { Component } from "react";
import { Tab, Tabs } from "react-materialize";
import MediaTab from "./MediaTab";
import Header from "./Header";
import OverviewTab from "./OverviewTab";
import styles from "./ProductTypeDetails.module.css";

class ProductTypeDetails extends Component {
  state = {
    productType: null,
    isOpen: false,
    tab: "overview"
  };

  open = async (productType) => {
    this.setState({
      productType,
      isOpen: true,
    });
    document.body.style.overflow = "hidden";
  };

  close = (event) => {
    event.stopPropagation();
    document.body.style.overflow = "initial";
    this.setState({
      isOpen: false,
      isFetching: false,
    });
  };

  onOpenEditDialog = () => {
    this.props.onOpenEditDialog(this.state.productType)
  }

  render() {
    const { productType, isOpen, tab } = this.state;
    const { openUploadModal, user } = this.props;
    if (!productType || !isOpen) return null;

    return (
      <DetailsOverlay title="Produkttyp" close={this.close}>
        <Header productType={productType} user={user} onOpenEditDialog={this.onOpenEditDialog}/>
        <div className={styles.body}>
          <Tabs className={styles.tabs}>
            <Tab
              title="Übersicht"
              active={tab === "overview"}
              key="overview"
              className={styles.tab}
            >
              <OverviewTab
                productType={productType}
                openUploadModal={openUploadModal}
              />
            </Tab>
            <Tab
              title="Medien"
              active={tab === "media"}
              key="media"
              className={styles.tab}
            >
              <MediaTab productType={productType} />
            </Tab>
          </Tabs>
        </div>
      </DetailsOverlay>
    );
  }
}

export default ProductTypeDetails;
