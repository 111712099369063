import React from 'react';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import FileUploadOutlineIcon from 'mdi-react/FileUploadOutlineIcon';
import './ProductFileUploadLink.css';

export default ({ title, tooltip, hasValue, ...rest }) => {
    return (
        <a className={`product-file-upload-link ${hasValue ? 'has-value' : ''}`} title={tooltip || title} { ...rest }>
            <div className="indicator">
                <FileUploadOutlineIcon className="upload-icon"/>
                { hasValue ? <CheckCircleIcon className="upload-status"/> : <FileUploadOutlineIcon className="upload-status"/> }
            </div>
            
            <div className="title">{ title }</div>
        </a>
    )
}