import React from 'react';
import styles from './IsEmpty.module.css'
import icon from './no_result.svg'

const IsEmpty = ({ message }) => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingContainerInner}>
        <img src={icon} alt=""/>
        <div className={styles.loadingMessage}>{message}</div>
      </div>

    </div>
  )
}

export default IsEmpty;