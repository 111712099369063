/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from "react";
import { Dropdown, NavItem } from "react-materialize";
import AccountCircleIcon from "mdi-react/AccountCircleIcon";

const dropdownOptions = {
  constrainWidth: false,
  coverTrigger: false,
};

class UserDropdown extends Component {
  render() {
    const { logout, options, user, ...rest } = this.props;
    const usedOptions = {
      ...dropdownOptions,
      options,
    };
    delete usedOptions.staticContext;
    return (
      <Dropdown
        id="userDropdown"
        trigger={
          <a
            className="user-trigger"
            data-target="userDropdown"
            style={{ userSelect: "none" }}
          >
            {user && user.picture && <img src={user.picture} alt={user.name} />}
            {(!user || !user.picture) && <AccountCircleIcon size={32} />}
          </a>
        }
        options={usedOptions}
        {...rest}
      >
        <NavItem onClick={logout}>Abmelden</NavItem>
      </Dropdown>
    );
  }
}

export default UserDropdown;
