/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { Component } from 'react';
import { Button } from 'react-materialize';
import Modal from 'react-modal';
import styles from './ProductEditModal.module.css';
import { toast } from 'react-toastify';
import SelectBox from 'components/Core/SelectBox';
import Spinner from 'components/Core/Spinner';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import MinusCircleIcon from 'mdi-react/MinusCircleIcon';
import MapOutlineIcon from 'mdi-react/MapOutlineIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';

const toModelValue = (product, propName) => {
  const value = product[propName].product
  if (value === null) {
    return -1
  } else if (value) {
    return 1
  }
  return 0
}

const toUpdateModelValue = (model, propName) => {
  const value = model[propName]
  if (value === -1) {
    return null
  } else if (value === 1) {
    return true
  }
  return false
}

const toModelPublicEnforcedValue = (product) => {
  const value = product.public.enforced
  if (value === null) {
    return -1
  } else if (value) {
    return 1
  }
  return 0;
}


class ProductEditModal extends Component {

  state = {
    open: false,
    isSubmitting: false,
    model: {},
    product: {}
  }

  open = (product) => {
    console.log('product', product)
    const model = {
      public: toModelPublicEnforcedValue(product),
      appVisibility: toModelValue(product, 'appVisibility'),
      providesGpxData: toModelValue(product, 'providesGpxData'),
      providesGeoData: toModelValue(product, 'providesGeoData'),
      downloadType: product.downloadType.product ? product.downloadType.product : -1,
    }
    this.setState({
      open: true,
      product,
      model,
    })
  }

  close = () => {
    // Don't close the modal, while saving
    if (this.state.isSubmitting) return false;

    this.setState({
      open: false
    }, () => {
      this.setState({
        product: {},
        model: {

        }
      })
    })
  }

  hasChanged = (prop) => {
    const value = this.state.model[prop] === 1
    return this.state.product[prop] !== value
  }

  minimizeUpdateModel = (input, product) => {
    return {
      ...(product.public.enforced !== input.publicEnforced ? { publicEnforced: input.publicEnforced } : {}),
      ...(product.providesGpxData.product !== input.providesGpxData ? { providesGpxData: input.providesGpxData } : {}),
      ...(product.providesGeoData.product !== input.providesGeoData ? { providesGeoData: input.providesGeoData } : {}),
      ...(product.appVisibility.product !== input.appVisibility ? { appVisibility: input.appVisibility } : {}),
      ...(product.downloadType.product !== input.downloadType ? { downloadType: input.downloadType } : {}),
    }
  }

  submit = async () => {
    this.setState({ isSubmitting: true });
    const { model, product } = this.state


    const rawModel = {
      publicEnforced: toUpdateModelValue(model, 'public'),
      providesGpxData: toUpdateModelValue(model, 'providesGpxData'),
      providesGeoData: toUpdateModelValue(model, 'providesGeoData'),
      appVisibility: toUpdateModelValue(model, 'appVisibility'),
      downloadType: model.downloadType === -1 ? null : model.downloadType
    }

    const updateModel = this.minimizeUpdateModel(rawModel, product)
    try {
      await this.props.onSubmit(this.state.product, updateModel)
      toast('Produktkonfiguration aktualisiert', { type: 'success', autoClose: 2000 })
      this.setState({ isSubmitting: false });
      this.close();

    } catch (e) {
      this.setState({ isSubmitting: false });
      toast(e.message || 'Unerwarteter Fehler', { type: 'error' })
    }

  }

  onSelect = (value, property) => {
    console.log(property, value)
    this.setState({
      model: {
        ...this.state.model,
        [property]: value === null ? -1 : value
      }
    })
  }

  onChangeText = (e) => {
    const { name, value } = e.currentTarget;
    this.setState({
      model: {
        ...this.state.model,
        [name]: value
      }
    })
  }

  render() {
    const { open, product, model, isSubmitting } = this.state;
    if (!product || !product.public) {
      return null;
    }
    console.log(model)

    let publicClassname = styles.productInactive
    if (model.public === 1) {
      publicClassname = styles.productOn
    } else if (model.public === 0) {
      publicClassname = styles.productOff
    }

    let providesGpxDataClassname = styles.productInactive
    if (model.providesGpxData === 1) {
      providesGpxDataClassname = styles.productOn
    } else if (model.providesGpxData === 0) {
      providesGpxDataClassname = styles.productOff
    }

    let providesGeoDataClassname = styles.productInactive
    if (model.providesGeoData === 1) {
      providesGeoDataClassname = styles.productOn
    } else if (model.providesGeoData === 0) {
      providesGeoDataClassname = styles.productOff
    }

    let downloadTypeClassname = styles.productInactive
    if (model.downloadType === -1) {
      downloadTypeClassname = styles.productInactive
    } else if (model.downloadType === 'none') {
      downloadTypeClassname = styles.productOff
    } else if (model.downloadType !== 'none') {
      downloadTypeClassname = styles.productOn
    }

    let appVisibilityClassname = styles.productInactive
    if (model.appVisibility === 0) {
      appVisibilityClassname = styles.productOff
    } else if (model.appVisibility === 1) {
      appVisibilityClassname = styles.productOn
    }

    return (
      <Modal
        isOpen={open}
        className={`${styles.modal} modal`}
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={!isSubmitting}
        bodyClassName="modal-open"
        contentLabel="Produktkonfiguration bearbeiten">
        <>
          <div className={styles.modalHeader}>
            <div className={styles.content}>
              <div></div>
              Produktkonfiguration bearbeiten
              <div className={styles.subTitle}>{product.key}・{product.isbn}・{product.name}</div>
            </div>
          </div>
          <div className="modal-content">

            <div className={styles.content}>
              <div className={styles.section}>
                <div className={styles.sectionTitle}>Status</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginTop: '12px' }}>
                    <div className={styles.typeLabel}>Laut VLB</div>
                    <div className={`${styles.typeValue} ${model.public === -1 ? styles.productTypeActive : ''} ${product.public.default ? styles.on : styles.off}`}>
                      {
                        product.public.default ? <CheckCircleIcon /> : <MinusCircleIcon />
                      }
                      {product.public?.default ? 'Aktiv' : 'Inaktiv'}
                    </div>
                  </div>
                  <div className={styles.connector}>
                    <div className={styles.connectorLine} />
                    <div className={styles.connectorDot} />
                    <div className={styles.connectorLine} />
                  </div>
                  <div style={{ display: 'flex', flex: 1, marginTop: '12px' }}>
                    <SelectBox
                      label="Dieses Produkt"
                      className={publicClassname}
                      onSelect={this.onSelect}
                      values={[{ id: -1, label: 'Laut VLB' }, { id: 0, label: 'Inaktiv' }, { id: 1, label: 'Aktiv' }]}
                      property="public"
                      selected={model.public} renderIcon={(item) => {
                        if (item.id === -1) {
                          return null
                        }
                        return item.id === 1 ? <CheckCircleIcon /> : <MinusCircleIcon />
                      }} />
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionTitle}>Bietet GPX-Downloads</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginTop: '12px' }}>
                    <div className={styles.typeLabel}>Produkttyp</div>
                    <div className={`${styles.typeValue} ${model.providesGpxData === -1 ? styles.productTypeActive : ''} ${product.providesGpxData.productType ? styles.on : styles.off}`}>
                      {
                        product.providesGpxData.productType ? <CheckCircleIcon /> : <MinusCircleIcon />
                      }
                      {product.providesGpxData?.productType ? 'Ja' : 'Nein'}
                    </div>
                  </div>
                  <div className={styles.connector}>
                    <div className={styles.connectorLine} />
                    <div className={styles.connectorDot} />
                    <div className={styles.connectorLine} />
                  </div>
                  <div style={{ display: 'flex', flex: 1, marginTop: '12px' }}>
                    <SelectBox
                      label="Dieses Produkt"
                      className={providesGpxDataClassname}
                      onSelect={this.onSelect}
                      values={[{ id: -1, label: 'Laut Produkttyp' }, { id: 0, label: 'Nein' }, { id: 1, label: 'ja' }]}
                      property="providesGpxData"
                      selected={model.providesGpxData} renderIcon={(item) => {
                        if (item.id === -1) {
                          return null
                        }
                        return item.id === 1 ? <CheckCircleIcon /> : <MinusCircleIcon />
                      }} />
                  </div>
                </div>
                <div className={styles.sectionTitle}>Bietet Blattschnitt</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginTop: '12px' }}>
                    <div className={styles.typeLabel}>Produkttyp</div>
                    <div className={`${styles.typeValue} ${model.providesGeoData === -1 ? styles.productTypeActive : ''} ${product.providesGeoData.productType ? styles.on : styles.off}`}>
                      {
                        product.providesGeoData.productType ? <CheckCircleIcon /> : <MinusCircleIcon />
                      }
                      {product.providesGeoData?.productType ? 'Ja' : 'Nein'}
                    </div>
                  </div>
                  <div className={styles.connector}>
                    <div className={styles.connectorLine} />
                    <div className={styles.connectorDot} />
                    <div className={styles.connectorLine} />
                  </div>
                  <div style={{ display: 'flex', flex: 1, marginTop: '12px' }}>
                    <SelectBox
                      label="Dieses Produkt"
                      className={providesGeoDataClassname}
                      onSelect={this.onSelect}
                      values={[{ id: -1, label: 'Laut Produkttyp' }, { id: 0, label: 'Nein' }, { id: 1, label: 'ja' }]}
                      property="providesGeoData"
                      selected={model.providesGeoData} renderIcon={(item) => {
                        if (item.id === -1) {
                          return null
                        }
                        return item.id === 1 ? <CheckCircleIcon /> : <MinusCircleIcon />
                      }} />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionTitle}>Freischaltung</div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginTop: '12px' }}>
                      <div className={styles.typeLabel}>Produkttyp</div>
                      <div className={`${styles.typeValue} ${model.downloadType === -1 ? styles.productTypeActive : ''} ${product.downloadType.productType ? styles.on : styles.off}`}>
                        {
                          product.downloadType.productType !== 'none' ? <CheckCircleIcon /> : <MinusCircleIcon />
                        }
                        {product.downloadType?.productType === 'none' && 'Keine Freischaltung'}
                        {product.downloadType?.productType === 'map' && 'Blattschnitt'}
                        {product.downloadType?.productType === 'trails' && 'Touren'}
                      </div>
                    </div>
                    <div className={styles.connector}>
                      <div className={styles.connectorLine} />
                      <div className={styles.connectorDot} />
                      <div className={styles.connectorLine} />
                    </div>
                    <div style={{ display: 'flex', flex: 1, marginTop: '12px' }}>
                      <SelectBox
                        label="Dieses Produkt"
                        className={downloadTypeClassname}
                        onSelect={this.onSelect}
                        values={[{ id: -1, label: 'Laut Produkttyp' }, { id: 'none', label: 'Keine Freischaltung' }, { id: 'map', label: 'Blattschnitt' }, { id: 'trails', label: 'Touren' }]}
                        property="downloadType"
                        selected={model.downloadType} renderIcon={(item) => {
                          if (item.id === -1) {
                            return null
                          }
                          if (item.id === 'map') {
                            return <MapOutlineIcon />
                          }
                          if (item.id === 'trails') {
                            return <RoutesIcon />
                          }
                          return <MinusCircleIcon />
                        }} />
                    </div>
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionTitle}>Anzeige in der App</div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginTop: '12px' }}>
                      <div className={styles.typeLabel}>Produkttyp</div>
                      <div className={`${styles.typeValue} ${model.appVisibility === -1 ? styles.productTypeActive : ''} ${product.appVisibility.productType ? styles.on : styles.off}`}>
                        {
                          product.appVisibility.productType ? <CheckCircleIcon /> : <MinusCircleIcon />
                        }
                        {product.appVisibility?.productType ? 'Aktiv' : 'Deaktiviert'}
                      </div>
                    </div>
                    <div className={styles.connector}>
                      <div className={styles.connectorLine} />
                      <div className={styles.connectorDot} />
                      <div className={styles.connectorLine} />
                    </div>
                    <div style={{ display: 'flex', flex: 1, marginTop: '12px' }}>
                      <SelectBox
                        label="Dieses Produkt"
                        className={appVisibilityClassname}
                        onSelect={this.onSelect}
                        values={[{ id: -1, label: 'Laut Produkttyp'}, { id: 0, label: 'Deaktiviert' }, { id: 1, label: 'Aktiv' }]}
                        property="appVisibility"
                        selected={model.appVisibility} renderIcon={(item) => {
                          if (item.id === -1) {
                            return null
                          }
                          return item.id === 1 ? <CheckCircleIcon /> : <MinusCircleIcon />
                        }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              isSubmitting && (
                <div className={styles.overlay}>
                  <Spinner size="large" className={styles.overlaySpinner} />
                </div>
              )
            }

          </div>
          <div className={styles.modalFooter}>
            <div className={styles.content}>
              <Button waves="light" onClick={this.submit} disabled={isSubmitting}>Änderungen speichern</Button>
              &nbsp;&nbsp;
              <Button waves="light" flat onClick={this.close} disabled={isSubmitting}>Abbrechen</Button>
            </div>
          </div>
        </>


      </Modal >
    )
  }
}

export default ProductEditModal;