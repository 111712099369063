import React from "react";
import { withRouter } from "react-router-dom";
import Page from "components/Core/Page";
import Title from "components/Core/Title";
import Content from "components/Core/Content";
import SearchBox from "components/Core/SearchBox";
import RegionsTable from "../RegionsTable";

class Regions extends React.Component {
  onAddRegion = () => {
    this.props.history.push("/region/new");
  };

  onOpenRegion = (id) => {
    this.props.history.push(`/region/${id}`);
  };

  componentDidMount = () => {
    if (!this.props.regions || !this.props.regions.length)
      this.props.fetchRegions();
  };

  onSubmitSearch = (keyword) => {
    this.props.fetchRegions(keyword)
  }

  render() {
    const { regions, isLoading, keyword, fetchRegions } = this.props;
    return (
      <Page>
        <Title
          renderSearch={() => (
            <SearchBox
              query={keyword}
              placeholder="Regionen durchsuchen..."
              onSubmit={this.onSubmitSearch}
              autoFocus
              isFetching={isLoading}
            />
          )}
        >
          Regionen
        </Title>
        <Content>
          <RegionsTable
            keyword={keyword}
            isLoading={isLoading}
            onSearch={fetchRegions}
            openRegion={this.onOpenRegion}
            onAddRegion={this.onAddRegion}
            regions={regions}
          />
        </Content>
      </Page>
    );
  }
}

export default withRouter(Regions);
