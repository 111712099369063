import DestinationsApi from "api/DestinationsApi";
export const FETCH_COUNTRIES =
  "kompass/crm/destination/country/FETCH_COUNTRIES";
export const FETCH_COUNTRIES_FINISHED =
  "kompass/crm/destination/country/FETCH_COUNTRIES_FINISHED";

export const fetchCountries = () => {
  return (dispatch, getState) => {
    const { countries } = getState().destination.country;
    if (countries) return Promise.resolve();

    return DestinationsApi.findCountries()
      .then((r) => {
        return dispatch(fetchCountriesFinished(r));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchCountriesFinished = (result) => ({
  type: FETCH_COUNTRIES_FINISHED,
  payload: {
    ...result,
  },
});
