import React from "react";
import Page from "components/Core/Page";
import Content from "components/Core/Content";
import Title from "components/Core/Title";
import SearchBox from "components/Core/SearchBox";
import UserDetailOverlay from "./UserDetailOverlay";
import { UserFilter } from "./UserFilter";
import { UserList } from "./UserList";
import { UserDeleteModal } from "./UserDeleteModal";

class SupportComponent extends React.Component {


  componentDidMount = () => {
    this.props.fetchOnLoad();
  };

  onSubmitSearch = (q) => {
    this.props.searchUser({
      ...this.props.filter,
      q,
    });
  };

  onSearch = (filter) => {
    this.props.searchUser({
      ...this.props.filter,
      ...filter,
    });
  };

  onSelectUser = (user) => {
    this.detailOverlay.open(user)
  }

  openDeleteModal = (user) => {
    this.deleteModal.open(user)
  }

  onUserDeleted = (ssoId) => {
    console.log('onUserDeleted', ssoId)
    this.detailOverlay.close()
    this.props.onUserDeleted(ssoId)
  }

  render() {
    const { filter, users, onResetFilter } = this.props;
    return (
      <Page>
        <Title renderSearch={() => (
          <SearchBox
            query={this.props.filter.q}
            placeholder="Benutzer durchsuchen..."
            onSubmit={this.onSubmitSearch}
            autoFocus
            isFetching={this.props.isLoading}
          />
        )}>Benutzer</Title>
        <Content>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <UserFilter 
              filter={filter} 
              onSearch={this.onSearch}
              onResetFilter={onResetFilter}/>
            <div style={{ paddingLeft: '320px', flex: 1 }}>
              <UserList
                users={users}
                onClick={this.onSelectUser}
                paginate={this.props.paginate}
                pagination={this.props.pagination}
                isLoading={this.props.isLoading} />
            </div>
          </div>
        </Content>
        <UserDeleteModal
          ref={c => this.deleteModal = c} 
          onUserDeleted={this.onUserDeleted}
          user={this.props.user} />
        <UserDetailOverlay 
          ref={c => this.detailOverlay = c} 
          addDays={this.props.addDays} 
          updateUser={this.props.updateUser}
          onOpenDeleteModal={this.openDeleteModal}/>
      </Page>
    );
  }
}

export default SupportComponent;
