/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import './Title.css';
import ArrowLeft from 'mdi-react/ArrowLeftIcon';

export default ({ children, onBackClick, renderBreadCrumbs, renderSearch }) => (
    <div className={`page-title ${onBackClick ? 'with-back-button' : ''} ${renderBreadCrumbs ? 'with-breadcrumbs' : ''} ${renderSearch ? 'with-search' : ''}`}>
        <div className="inner" >
            {
                onBackClick && (
                    <a className="back-button" onClick={onBackClick}>
                        <ArrowLeft />
                    </a>
                )
            }
            { !renderBreadCrumbs && <h5>{children}</h5>}
            {
                renderBreadCrumbs && (
                    
                    <div className="breadcrumbs-container">
                        <div className="breadcrumbs">
                            {
                                renderBreadCrumbs()
                            }
                        </div>
                        <h5>{children}</h5>
                    </div>
                
                    
                )
            }
            {
                renderSearch && renderSearch()
            }
            
        </div>
    </div>
)