import UserApi from "api/UserApi";
import { toast } from "react-toastify";
export const SEARCH_USER_STARTED = "kompass/crm/support/SEARCH_USER_STARTED";
export const SEARCH_USER_FINISHED = "kompass/crm/support/SEARCH_USER_FINISHED";
export const USER_DELETED = "kompass/crm/support/USER_DELETED";
export const SEARCH_USER_FAILED = "kompass/crm/support/SEARCH_USER_FAILED";
export const ADD_DAYS_FINISHED = "kompass/crm/support/ADD_DAYS_FINISHED";
export const UPDATE_USER_FINISHED = "kompass/crm/support/UPDATE_USER_FINISHED";
export const DELETE_USER_FINISHED = "kompass/crm/support/DELETE_USER_FINISHED";
export const PAGINATE = "kompass/crm/support/PAGINATE";
export const RESET = "kompass/crm/user/RESET";

const htmlToString = (string) => {
  return (string + "").replace(/&#\d+;/gm, function (s) {
    return String.fromCharCode(s.match(/\d+/gm)[0]);
  })
}

export const paginate = (page) => {
  return (dispatch, getState) => {
    const { company } = getState().app.user;
    const { filter } = getState().support;
    dispatch(searchUserStarted(filter, page));
    return UserApi.find(filter, page, company)
      .then((r) => {
        return dispatch(searchUserFinished(r));
      })
      .catch((err) => {
        if (!UserApi.isCanceled(err)) {
          toast(htmlToString(err.message) || 'Ein unerwarter Fehler ist aufgetreten.', { type: 'error' })
          return dispatch(searchUserFailed());
        }
      });
  }
};

export const fetchOnLoad = () => {
  return (dispatch, getState) => {
    const { fetchOnLoad, filter } = getState().support;
    if (fetchOnLoad) {
      return dispatch(searchUser(filter))
    }
  }
}

export const resetFilter = () => {
  return (dispatch, getState) => {
    const { company } = getState().app.user;
    const { q } = getState().support.filter;

    const filter = {
      q,
      page: 0,
      limit: 30,
      renewalFailed: null,
      status: null
    }


    dispatch(searchUserStarted(filter, 0));
    return UserApi.find(filter, 0, company)
      .then((r) => {
        return dispatch(searchUserFinished(r));
      })
      .catch((err) => {
        if (!UserApi.isCanceled(err)) {
          toast(htmlToString(err.message) || 'Ein unerwarter Fehler ist aufgetreten.', { type: 'error' })
          return dispatch(searchUserFailed());
        }
      });
  };
}

export const onUserDeleted = (ssoId) => {
  return async (dispatch, getState) => {
    const { filter } = getState().support
    dispatch(userDeleted(ssoId))
    dispatch(searchUserStarted(filter, 0));
    return dispatch(searchUser(filter))
  }
}

export const searchUser = (filter) => {
  return async (dispatch, getState) => {
    const { company } = getState().app.user;
    try {

      dispatch(searchUserStarted(filter, 0))
      const result = await UserApi.find(filter, 0, company);
      return dispatch(searchUserFinished(result));
    } catch (error) {
      if (!UserApi.isCanceled(error)) {
        toast(error.message || 'Bei der Suche ist ein Fehler aufgetreten,', { type: 'error' })
        return dispatch(searchUserFailed())
      }
    }
  };
};

export const searchUserFinished = (result) => ({
  type: SEARCH_USER_FINISHED,
  payload: {
    ...result
  },
});

const userDeleted = (ssoId) => ({
  type: USER_DELETED,
  payload: {
    ssoId
  },
});

export const searchUserStarted = (filter, page) => ({
  type: SEARCH_USER_STARTED,
  filter,
  page,
});

export const searchUserFailed = () => ({
  type: SEARCH_USER_FAILED,
});

export const addDays = (ssoId, days) => {
  return async (dispatch, getState) => {
    const { company } = getState().app.user;
    try {
      const result = await UserApi.addDays(ssoId, days, company);
      toast(`${company === 'hkf' ? 'Premium' : 'PRO'}-Laufzeit verlängert`, {
        type: "success",
        autoClose: 2000,
      });
      return dispatch(addDaysFinished(result.user));
    } catch (error) {
      toast(`${company === 'hkf' ? 'Premium' : 'PRO'}-Laufzeit konnte nicht aktualisiert werden.`, {
        type: "error",
      });
      console.log(error);
    }
  };
};

export const addDaysFinished = (user) => ({
  type: ADD_DAYS_FINISHED,
  payload: user,
});

export const updateUser = (ssoId, model) => {
  return async (dispatch, getState) => {
    try {
      const { company } = getState().app.user;
      const result = await UserApi.updateUser(ssoId, {
        ...model,
        owner: company
      });
      toast("Benutzer aktualisiert", {
        type: "success",
        autoClose: 2000,
      });
      return dispatch(updateUserFinished(ssoId, result.user));
    } catch (error) {
      toast("Benutzer konnte nicht aktualisiert werden.", {
        type: "error",
      });
    }
  };
};

export const updateUserFinished = (ssoId, user) => ({
  type: UPDATE_USER_FINISHED,
  payload: {
    ssoId,
    user,
  },
});

export const deleteUserFinished = (ssoId) => ({
  type: DELETE_USER_FINISHED,
  payload: {
    ssoId
  },
});
