import React, { useEffect, useState } from 'react';
import styles from './ChannelSelection.module.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Spinner from 'components/Core/Spinner';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ReloadIcon from 'mdi-react/ReloadIcon';


const ChannelSelection = ({ label, isFetching, data, hasError, errorMessage, selected, refetch, withRefetch, emptyMessage, onSelect, allowUnselect }) => {
  const [query, setQuery] = useState('')
  const [items, setItems] = useState(data)

  const onChange = (e) => {
    const { value } = e.currentTarget
    setQuery(value)
  }

  useEffect(() => {
    if (!query?.length || !data) {
      setItems(data)
    }

    const results = data && data.filter(item => {
      return item.name.toLowerCase().includes(query.toLowerCase())
    })

    setItems(results || [])
  }, [query, data])

  const onSelectItem = (item) => {
    if (allowUnselect && selected) {
      onSelect(null)
    }
    if (!selected || selected !== item.id) {
      onSelect(item)
    }
  }

  return (
    <div className={styles.container} >

      <div className={styles.inputLabel}>{label}</div>
      <div className={styles.inputContainer}>
        <input name="query" value={query} onChange={onChange} placeholder="Durchsuchen..." className={emptyMessage ? styles.disabledInput : ''} />
        <MagnifyIcon className={styles.searchIcon}/>
        {isFetching && <Spinner size="small" className={styles.spinner} />}
        { !isFetching && withRefetch && (
          <button className={styles.refetchButton} onClick={refetch}>{<ReloadIcon />}</button>
        )}
      </div>

      <div className={`${styles.list} ${emptyMessage ? styles.disabledList: ''}`}>
        <Scrollbars
          autoHide
          style={{
            width: 'calc(100% - 0px)',
            height: 'calc(100% - 0px)',
          }}
        >
          {
            isFetching && (
              <div className={styles.messageContainer}>
                <div className={styles.emptyMessage}>Einen Augenblick...</div>
              </div>
            )
          }
          {
            !isFetching && !hasError && emptyMessage && (
              <div className={styles.messageContainer}>
                <div className={styles.emptyMessage}>{emptyMessage}</div>
              </div>
            )
          }
          {
            !isFetching && hasError && (
              <div className={styles.messageContainer}>
                <div className={styles.emptyMessage}>{errorMessage}</div>
              </div>
            )
          }

          {
            !isFetching && !hasError && items && items.map(channel => (
              <div key={channel.id} className={`${styles.listItem} ${channel.id === selected ? styles.selected : ''} ${channel.id === selected && allowUnselect ? styles.allowUnselect : ''}`} onClick={() => onSelectItem(channel)}>
                {
                  channel.id === selected && <CheckCircleIcon />
                }
                {
                  channel.id !== selected && <CircleOutlineIcon />
                }
                <div className={styles.label}>{channel.name}</div>
                {
                  channel.id === selected && allowUnselect && <DeleteIcon className={styles.deleteIcon} />
                }
              </div>
            ))
          }
        </Scrollbars>

      </div>
    </div>
  )
}

export default ChannelSelection;