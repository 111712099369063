import { FETCH_COUNTRIES_FINISHED } from './actions';
import { LOGOUT } from '../../app/actions';

const _initialState = {

}

const country = (state = _initialState, action) => {
    switch (action.type) {
        case FETCH_COUNTRIES_FINISHED:
            return Object.assign({}, state, {
                ...state,
                countries: action.payload.countries
            })
        case LOGOUT: {
            return _initialState;
        }
        default:
            return state;
    }
}

export default country;