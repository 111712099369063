/* eslint-disable react/jsx-no-target-blank */
import IsEmpty from "components/Core/IsEmpty";
import Loading from "components/Core/Loading";
import React from "react";
import ReactPaginate from "react-paginate";
import ProductCard from "./ProductCard";
import styles from "./ProductList.module.css";

class ProductList extends React.Component {
  today = new Date();

  paginate = ({ selected }) => {
    this.props.paginate(selected);
  };

  render() {
    const {
      onCardClick,
      openEditProductModal,
      isLoading,
      pagination,
      products,
      owner,
      user,
      openUploadModal,
    } = this.props;
    if (!products) {
      return <Loading />
    }
    if (!products.length) {
      return <IsEmpty message="Keine Produkte gefunden" />
    }
    return (
      <div className={isLoading ? styles.isLoading : ''}>
        {
          pagination.resultSize > 0 && (
            <div className={styles.resultCount}>
              {
                `${pagination.resultSize === 1 ? 'Ein' : `${pagination.resultSize}`} Produkt${pagination.resultSize < 2 ? '' : 'e'} gefunden`
              }
            </div>
          )
        }

        {
          products && products.map((product) => (
            <ProductCard 
              key={product.id} 
              user={user}
              product={product} 
              onClick={onCardClick} 
              openEditProductModal={openEditProductModal}
              owner={owner} 
              openUploadModal={openUploadModal}/>
          ))
        }
        {products && products.length > 0 && pagination && pagination.resultSize > 0 && (
          <div className={styles.pagination}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="&#8594;"
              onPageChange={this.paginate}
              pageRangeDisplayed={5}
              pageCount={pagination.totalPages}
              previousLabel="&#8592;"
              forcePage={pagination.page}
              className="pagination"
              renderOnZeroPageCount={null}
            />
            <div className={styles.resultSize}>
              {
                `${pagination.resultSize < 2 ? 'Ein' : `${pagination.resultSize}`} Produkt${pagination.resultSize < 2 ? '' : 'e'} gefunden`
              }
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProductList;
