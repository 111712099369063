import {
  FETCH_PRODUCTS_STARTED,
  FETCH_PRODUCTS_FINISHED,
  UPLOAD_GEOJSON_FINISHED,
  UPLOAD_GPX_FINISHED,
  FETCH_PRODUCTS_FAILED,
  PAGINATE,
  RESET,
  isFilterDirty,
  UPDATE_PRODUCT_FINISHED
} from "./actions";
import { LOGOUT } from "../app/actions";

const STORAGE_KEY = "kompass_crm_products_filter";
const PAGE_SIZE = 30;
const getInitialFilter = () => {
  const item = localStorage.getItem(STORAGE_KEY);
  try {
    const filter = JSON.parse(item);
    if (false) {
      const dirty = isFilterDirty(filter)
      return {
        fetchOnLoad: dirty,
        isLoading: dirty,
        filter: {
          ...filter,
          limit: 501,
        },
      };
    }
  } catch (e) {
    // ignored
  }
  return {
    fetchOnLoad: true,
    isLoading: false,
    filter: {
      query: '',
      isPublic: 1,
      typeId: null,
      appVisibility: null,
      providesGpxData: null,
      providesGeoData: null,
      unlockable: null,
      gpxUrl: null,
      publishedState: null,
      page: 0,
      limit: 30,
      orderBy: 'name_asc',
    },
  };
};

const buildPagination = (items, total, currentPage) => {
  if (!items) {
    return {
      resultSize: 0,
      page: 0,
      totalPages: 0,
    };
  }

  const totalPages = Math.ceil(total / PAGE_SIZE);
  return {
    resultSize: total,
    page: currentPage,
    totalPages,
  };
};

const defaultState = {
  products: null,
  ...getInitialFilter(),
  pagination: buildPagination(null),
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET:
      localStorage.removeItem(STORAGE_KEY);
      return Object.assign({}, state, {
        ...state,
        fetchOnLoad: false,
        isLoading: false,
        products: null,
        pagination: null,
      });
    case FETCH_PRODUCTS_FINISHED:
      if (!state.fetchOnLoad) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state.filter));
      }
      return Object.assign({}, state, {
        ...state,
        fetchOnLoad: false,
        isLoading: false,
        products: action.payload.products,
        pagination: buildPagination(action.payload.products, action.payload.total, state.pagination.page),
      });
    case FETCH_PRODUCTS_FAILED:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        pagination: buildPagination(null),
      });
    case UPLOAD_GEOJSON_FINISHED:
      return Object.assign({}, state, {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.product.id) {
            return action.payload.product
          }
          return product;
        }),
      });
    case UPLOAD_GPX_FINISHED:
      return Object.assign({}, state, {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.product.id) {
            return action.payload.product
          }
          return product;
        }),
      });
    case UPDATE_PRODUCT_FINISHED:
      return Object.assign({}, state, {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.product.id) {
            return action.payload.product
          }
          return product;
        }),
      });
    case FETCH_PRODUCTS_STARTED:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        filter: action.filter,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      });
    case PAGINATE:
      return Object.assign({}, state, {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      });
    case LOGOUT: {
      return defaultState;
    }
    default:
      return state;
  }
};
