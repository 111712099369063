import { 
    FETCH_REGIONS, 
    FETCH_REGIONS_FINISHED
} from './actions';
import { UPDATE_REGION_SUCCESS } from '../details/actions';
import { LOGOUT } from 'redux/app/actions';

const _initialState = {
    regions: [],
    isLoading: false
}

const list = (state = _initialState, action) => {
    switch (action.type) {
        case FETCH_REGIONS_FINISHED:
            return Object.assign({}, state, {
                ...state,
                isLoading: false,
                regions: action.payload.regions
            })
        case FETCH_REGIONS:
            return Object.assign({}, state, {
                ...state,
                isLoading: true,
                keyword: action.keyword
            })
        case UPDATE_REGION_SUCCESS:
                return Object.assign({}, state, {
                    ...state,
                    regions: state.regions.map(region => {
                        if (region.id === action.payload.id) {
                            region = {
                                ...region,
                                ...action.payload
                            }
                        } 
                        return region;
                    })
                })
        case LOGOUT: {
            return _initialState;
        }
        default:
            return state;
    }
}

export default list;