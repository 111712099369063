import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./ProductFilter.module.css";
import ProductTypeFilter from "./ProductTypeFilter";
import ProductFilterBox from "./ProductFilterBox";
import CalendarCheckIcon from "mdi-react/CalendarCheckIcon";
import CalendarRemoveOutlineIcon from "mdi-react/CalendarRemoveOutlineIcon";
import CalendarDayIcon from "mdi-react/CalendarOutlineIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import MapOutlineIcon from "mdi-react/MapOutlineIcon";
import RoutesIcon from "mdi-react/RoutesIcon";
import MinusCircleOutlineIcon from "mdi-react/MinusCircleOutlineIcon";
import WarningCircleIcon from "mdi-react/WarningCircleIcon";
import FilterVariantMinusIcon from "mdi-react/FilterVariantMinusIcon";

const publishedStateValues = [
  {
    id: 1,
    label: 'Veröffentlicht'
  },
  {
    id: 2,
    label: 'In den nächsten 3 Wochen'
  },
  {
    id: 0,
    label: 'Nicht veröffentlicht'
  }
]
const isPublicValues = [
  {
    id: 1,
    label: 'Aktive Produkte'
  },
  {
    id: 2,
    label: 'Erzwungen aktiv'
  },
  {
    id: 0,
    label: 'Inaktive Produkte'
  }
]

const appVisibilityValues = [
  {
    id: 0,
    label: 'Deaktiviert'
  },
  {
    id: 1,
    label: 'Aktiv'
  }
]

const providesGpxDataValues = [
  {
    id: 0,
    label: 'Kein GPX-Download'
  },
  {
    id: 1,
    label: 'Bietet GPX-Download'
  },
  {
    id: 2,
    label: 'GPX-Tracks fehlen'
  },
]
const downloadTypesValues = [
  {
    id: 'map',
    label: 'Blattschnitt'
  },
  {
    id: 'trails',
    label: 'Touren'
  },
  {
    id: 'none',
    label: 'Keine Freischaltung'
  }
]

const geoDataValues = [
  {
    id: 0,
    label: 'Ohne Blattschnitt'
  },
  {
    id: 1,
    label: 'Mit Blattschnitt'
  },
  {
    id: 2,
    label: 'Blattschnitt fehlt'
  },
  
]

const ProductFilter = ({ filter, user, onSearch, onResetFilter }) => {
  
  const [currentFilter, setCurrentFilter] = useState({
    ...filter
  })

  useEffect(() => {
    setCurrentFilter(filter);
}, [filter])

  const onSelect = (item, propertyName) => {
    if (onSearch) {
      onSearch({
        ...filter,
        [propertyName]: item
      })
    }
    setCurrentFilter({
      ...filter,
      [propertyName]: item
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Produkte filtern</div>
        <button className={styles.resetLink} onClick={onResetFilter}>
          <FilterVariantMinusIcon />
        </button>
      </div>
      <ProductFilterBox 
        label="Status" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={isPublicValues} 
        property="isPublic" 
        selected={currentFilter.isPublic} 
        user={user}
        renderIcon={(item) => {
          if (item.id === 0) {
            return <CloseCircleIcon />
          }
          if (item.id === 1) {
            return <CheckCircleIcon />
          }
          if (item.id === 2) {
            return <WarningCircleIcon />
          }
          return null
        }}/>
      <ProductTypeFilter onSelect={onSelect} selected={currentFilter.typeId} user={user} property="typeId"/>
      <ProductFilterBox 
        label="Veröffentlichung" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={publishedStateValues} 
        property="publishedState" 
        selected={currentFilter.publishedState} 
        user={user}
        renderIcon={(item) => {
          if (item.id === 0) {
            return <CalendarRemoveOutlineIcon />
          }
          if (item.id === 1) {
            return <CalendarCheckIcon />
          }
          if (item.id === 2) {
            return <CalendarDayIcon />
          }
          
          return null
        }}/>
      <br/>
      <ProductFilterBox 
        label="Blattschnitt" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={geoDataValues} 
        property="providesGeoData" 
        selected={currentFilter.providesGeoData} 
        user={user}
        renderIcon={(item) => {
          if (item.id === 0) {
            return <MinusCircleOutlineIcon />
          }
          if (item.id === 1) {
            return <CheckCircleIcon />
          }
          if (item.id === 2) {
            return <WarningCircleIcon />
          }
          return null
        }}/>
      <ProductFilterBox 
        label="GPX-Download" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={providesGpxDataValues} 
        property="providesGpxData" 
        selected={currentFilter.providesGpxData} 
        user={user}
        renderIcon={(item) => {
          if (item.id === 0) {
            return <MinusCircleOutlineIcon />
          }
          if (item.id === 1) {
            return <CheckCircleIcon />
          }
          if (item.id === 2) {
            return <WarningCircleIcon />
          }
          return null
        }}/>
      <ProductFilterBox 
        label="Anzeige in App" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={appVisibilityValues} 
        property="appVisibility" 
        selected={currentFilter.appVisibility} 
        user={user}
        renderIcon={(item) => {
          if (item.id === 0) {
            return <MinusCircleOutlineIcon />
          }
          if (item.id === 1) {
            return <CheckCircleIcon />
          }
          return null
        }}/>
      <ProductFilterBox 
        label="Freischaltung" 
        emptyLabel="Beliebig" 
        onSelect={onSelect} 
        values={downloadTypesValues} 
        property="downloadType" 
        selected={currentFilter.downloadType} 
        user={user}
        renderIcon={(item) => {
          if (item.id === 'map') {
            return <MapOutlineIcon />
          }
          if (item.id === 'trails') {
            return <RoutesIcon />
          }
          if (item.id === 'none') {
            return <MinusCircleOutlineIcon />
          }
          return null
        }}/>
    </div>
  );
};

export default ProductFilter;
