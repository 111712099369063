import Section from "components/Core/Section";
import SectionElement from "components/Core/SectionElement";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import WarningCircleIcon from "mdi-react/WarningCircleIcon";
import React from "react";
import styles from './AppTab.module.css';
import MinusCircleIcon from "mdi-react/MinusCircleIcon";


const AppTab = ({ product, validations }) => {
  const { app, isbn, gpx } = validations;
  console.log(gpx)
  return (
    <div>
      <Section title="GPX-Download">
        <SectionElement
          label="Produkt veröffentlicht"
          fixedWidth
          status={gpx.elements.published.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {gpx.elements.published.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
              <span>{gpx.elements.published.message}</span>
            </div>
          )}
        />
        <SectionElement
          label="Produkt bietet GPX-Downloads"
          fixedWidth
          status={gpx.elements.providesGpxData.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {gpx.elements.providesGpxData.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
              <span>{gpx.elements.providesGpxData.value ? 'Ja' : 'Nein'}</span>
            </div>
          )}
        />
        <SectionElement
          label="GPX-Daten vorhanden"
          fixedWidth
          status={gpx.elements.gpxData.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {gpx.elements.gpxData.value && <CheckCircleIcon /> }
              {!gpx.elements.gpxData.value &&  gpx.elements.gpxData.status === 'inactive' &&  <MinusCircleIcon />}
              {!gpx.elements.gpxData.value &&  gpx.elements.gpxData.status === 'warning' &&  <WarningCircleIcon />}
              <span>{gpx.elements.gpxData.value ? 'Ja' : 'Nein'}</span>
            </div>
          )}
        />
        <div className={styles.summaryWrapper}>
          <div className={`${styles.summary} ${gpx.results.downloadable.status}`}>
            {gpx.results.downloadable.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
            {
              gpx.results.downloadable.value && (
                <div>
                  Die GPX-Daten stehen zum Download zur Verfügung.
                </div>
              )
            }
            {
              !gpx.results.downloadable.value && (
                <div>
                  Die GPX-Daten stehen <b>nicht</b> zum Download zur Verfügung.
                </div>
              )
            }
          </div>
        </div>
      </Section>
      <Section title="Anzeige und Freischaltung in Touren → Produkte">

        <SectionElement
          label="Was wird freigeschaltet?"
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {product.downloadType.value === 'none' && 'Keine Freischaltung vorgesehen'}
              {product.downloadType.value === 'map' && 'Blattschnitt'}
              {product.downloadType.value === 'trails' && 'Touren'}
            </div>
          )}
        />
        <SectionElement
          label="Produktstatus aktiv"
          fixedWidth={!product.public.enforced}
          status={app.elements.active.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {app.elements.active.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
              <span>{app.elements.active.message}</span>
            </div>
          )}
        />
        <SectionElement
          label="Produkt veröffentlicht"
          fixedWidth
          status={app.elements.published.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {app.elements.published.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
              <span>{app.elements.published.message}</span>
            </div>
          )}
        />
        <SectionElement
          label="Produkt soll in der App anzeigt werden"
          fixedWidth
          status={app.elements.productVisible.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {app.elements.productVisible.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
              <span>{app.elements.productVisible.message}</span>
            </div>
          )}
        />
        {
          /*
          app.elements.gpx && (
            <SectionElement
              label="GPX-Tracks zum Download verfügbar"
              fixedWidth
              status={app.elements.gpx.status}
              renderValue={() => (
                <div style={{ display: "flex" }}>
                  {app.elements.gpx.value ? <CheckCircleIcon /> : <WarningCircleIcon />}

                  <span>{app.elements.gpx.value ? 'Ja' : 'Nein'}</span>
                </div>
              )}
            />
          )
          */
        }
        {
          app.elements.tours && (
            <SectionElement
              label="Touren im Publication Planner vorhanden"
              fixedWidth
              status={app.elements.tours.status}
              renderValue={() => (
                <div style={{ display: "flex" }}>
                  {app.elements.tours.value ? <CheckCircleIcon /> : <WarningCircleIcon />}

                  <span>{app.elements.tours.message}</span>
                </div>
              )}
            />
          )
        }

        {
          app.elements.geoData && (
            <SectionElement
              label="Blattschnitt vorhanden"
              fixedWidth
              status={app.elements.geoData.status}
              renderValue={() => (
                <div style={{ display: "flex" }}>
                  {app.elements.geoData.value ? <CheckCircleIcon /> : <WarningCircleIcon />}

                  <span>{app.elements.geoData.value ? 'Ja' : 'Nein'}</span>
                </div>
              )}
            />
          )
        }
        <div className={styles.summaryWrapper}>
          <div className={`${styles.summary} ${app.results.visible.status}`}>
            {app.results.visible.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
            {
              app.results.visible.value && (
                <div>
                  Das Produkt wird in den Produktkategorien angezeigt.
                </div>
              )
            }
            {
              !app.results.visible.value && (
                <div>
                  Das Produkt wird in den Produktkategorien <b>nicht</b> angezeigt.
                </div>
              )
            }
          </div>
          {
            app.results.visible.value && (
              <div className={`${styles.summary} ${app.results.unlockable.status}`}>
                {app.results.unlockable.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
                {
                  app.results.unlockable.value && app.results.unlockable.status === 'success' && (
                    <div>
                      Das Produkt kann freigeschaltet werden.
                    </div>
                  )
                }
                {
                  app.results.unlockable.value && app.results.unlockable.status === 'warning' && (
                    <div>
                      Das Produkt kann freigeschaltet werden, aber die GPX-Tracks stehen nicht zum Download zur Verfügung.
                    </div>
                  )
                }
                {
                  !app.results.unlockable.value && (
                    <div>
                      Das Produkt kann <b>nicht</b> freigeschaltet werden
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </Section>
      <Section title="Freischaltung via ISBN" subTitle="ISBN-Eingabe oder Barcode-/QR-Codescan">

        <SectionElement
          label="Was wird freigeschaltet?"
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {product.downloadType.value === 'none' && 'Keine Freischaltung vorgesehen'}
              {product.downloadType.value === 'map' && 'Blattschnitt'}
              {product.downloadType.value === 'trails' && 'Touren'}
            </div>
          )}
        />
        <SectionElement
          label="Produkt veröffentlicht"
          fixedWidth
          status={isbn.elements.published.status}
          renderValue={() => (
            <div style={{ display: "flex" }}>
              {isbn.elements.published.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
              <span>{isbn.elements.published.message}</span>
            </div>
          )}
        />
        {
          /*
          isbn.elements.gpx && (
            <SectionElement
              label="GPX-Tracks zum Download verfügbar"
              fixedWidth
              status={isbn.elements.gpx.status}
              renderValue={() => (
                <div style={{ display: "flex" }}>
                  {isbn.elements.gpx.value ? <CheckCircleIcon /> : <WarningCircleIcon />}

                  <span>{isbn.elements.gpx.value ? 'Ja' : 'Nein'}</span>
                </div>
              )}
            />
          )
          */
        }
        {
          isbn.elements.tours && (
            <SectionElement
              label="Touren im Publication Planner vorhanden"
              fixedWidth
              status={isbn.elements.tours.status}
              renderValue={() => (
                <div style={{ display: "flex" }}>
                  {isbn.elements.tours.value ? <CheckCircleIcon /> : <WarningCircleIcon />}

                  <span>{isbn.elements.tours.message}</span>
                </div>
              )}
            />
          )
        }

        {
          isbn.elements.geoData && (
            <SectionElement
              label="Blattschnitt vorhanden"
              fixedWidth
              status={isbn.elements.geoData.status}
              renderValue={() => (
                <div style={{ display: "flex" }}>
                  {isbn.elements.geoData.value ? <CheckCircleIcon /> : <WarningCircleIcon />}

                  <span>{isbn.elements.geoData.value ? 'Ja' : 'Nein'}</span>
                </div>
              )}
            />
          )
        }

        <div className={styles.summaryWrapper}>
          <div className={`${styles.summary} ${isbn.results.unlockable.status}`}>
            {isbn.results.unlockable.value ? <CheckCircleIcon /> : <MinusCircleIcon />}
            {
              isbn.results.unlockable.value && (
                <div>
                  Das Produkt kann freigeschaltet werden.
                </div>
              )
            }
            {
              !isbn.results.unlockable.value && (
                <div>
                  Das Produkt kann <b>nicht</b> freigeschaltet werden.
                </div>
              )
            }
          </div>
        </div>

      </Section>
    </div>
  );
};

export default AppTab;
