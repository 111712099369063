import CloseIcon from "mdi-react/CloseIcon";
import React from "react";
import styles from "./DetailsOverlay.module.css";

const DetailsOverlay = ({ title, close, children }) => (
  <div className={styles.backdropContainer}>
    <div className={styles.backdrop} onClick={close} />
    <div className={styles.containerBackground} />
    <div className={styles.container}>
      <div className={styles.nav}>
        <div className={styles.closeButton} onClick={close}>
          <CloseIcon />
        </div>
        <div className={styles.overlayTitle}>{title}</div>
      </div>
      {children}
    </div>
  </div>
);

export default DetailsOverlay;
