import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, render, authenticated, user, ...rest }) => (
    <Route
        { ...rest }
        render={ (props) => {
            if (authenticated) {
                return <Redirect to="/" />
            }
            if (render) {
                return render(props);
            }
            return <Component { ...props } />;
        }
        }
    />
)

export default PublicRoute;
