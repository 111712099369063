
import React from 'react';
import TabContent from 'components/Core/TabContent';
import SubRegionsTable from '../SubRegionsTable';

class SubRegions extends React.Component{

    render() {
        const { subRegions, openSubRegion } = this.props;
        return (
            <TabContent clearMargin>
                <SubRegionsTable subRegions={subRegions} openSubRegion={openSubRegion}/>
            </TabContent>
        )
    }
}

export default SubRegions;
