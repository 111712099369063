const getGeoDataAvailable = (product) => {
  if (product.downloadType.value !== 'map') {
    return null
  }
  const hasData = product.geoData !== null
  const geoData = {
    value: hasData,
    message: hasData ? 'Ja' : 'Nein'
  }
  return geoData
}

const doesProvideGpxData = (product) => {
  const value = product.providesGpxData.value
  const providesGpxData = {
    value: value,
    status: value ? 'success' : 'inactive',
    message: value ? 'Ja' : 'Nein'
  }
  return providesGpxData
}

const getGpxDataVailable = (product) => {
  const hasData = product.gpxUrl !==null && product.gpxUrl.length > 0;
  const geoData = {
    value: hasData,
    message: hasData ? 'Ja' : 'Nein'
  }
  return geoData
}

const getToursAvailable = (product) => {
  if (product.downloadType.value !== 'trails') {
    return null
  }
  const tours = {
    isFetching: true,
    value: false,
    message: 'Nein'
  }
  return tours
}

const getDataAvailability = (product) => {
  let dataAvailable = {
    
  }

  if (!product.downloadType || product.downloadType === '') {
    dataAvailable = {
      value: false,
      message: 'Produkttyp nicht konfiguriert'
    }
  } else if (product.downloadType.value === 'trails') {
    const hasData = product.gpxUrl !==null && product.gpxUrl.length > 0;
    dataAvailable = {
      value: hasData,
      message: hasData ? 'Ja: GPX-Tracks' : 'Nein: GPX-Tracks'
    }
  } else if (product.downloadType.value === 'map') {
    const hasData = product.geoData !== null
    dataAvailable = {
      value: hasData,
      message: hasData ? 'Ja: Blattschnitt' : 'Nein: Blattschnitt'
    }
  }

  return dataAvailable
}

const getActive = (product) => {
  const active = {
    value: product.public.value,
    status: product.public.value ? 'success' : 'inactive',
    message: product.public ? 'Ja' : 'Nein'
  }
  if (product.public.enforced) {
    active.message = 'Ja - erzwungen'
  }
  return active
}

const getPublished = (product) => {
  const today = new Date();
  const publicationDate = product.publicationDate ? new Date(product.publicationDate) : null;
  const isPublished = publicationDate && publicationDate <= today;
  const published = {
    value: isPublished,
    status: isPublished ? 'success' : 'inactive',
    message: isPublished ? 'Ja' : 'Nein'
  }
  return published
}

const isTypeVisible = (product) => {
  const typeVisible = {
    value: product.appVisibility.productType,
    status: product.appVisibility.productType ? 'success' : 'inactive',
    message: product.appVisibility.productType ? 'Ja' : 'Nein'
  }
  return typeVisible
}

const isProductVisible = (product) => {
  const productVisible = {
    value: product.appVisibility.value,
    status: product.appVisibility.value ? 'success' : 'inactive',
    message: product.appVisibility.value ? 'Ja' : 'Nein'
  }
  return productVisible
}

const isUnlockable = (product) => {
  const value = product.downloadType.value !== 'none'
  const unlockable = {
    value,
    status: value ? 'success' : 'inactive',
    message: value ? 'Ja' : 'Nein'
  }

  return unlockable
}

const getAppElements = (active, published, productVisible, geoData, gpx, tours) => {
  const app = {
    active: {
      ...active
    },
    published: {
      ...published
    },
    productVisible: {
      ...productVisible
    },
    ...(geoData ? { geoData: { ...geoData } } : {}),
    ...(gpx ? { gpx: { ...gpx } } : {}),
    ...(tours ? { tours: { ...tours} } : {}),

  }
  

  if (geoData && !geoData.value) {
    app.geoData.status = active.value && published.value && productVisible.value ? 'warning' : 'inactive'
  } else if (geoData && geoData.value) {
    app.geoData.status = active.value && published.value && productVisible.value ? 'success' : 'inactive'
  }

  if (gpx && !gpx.value) {
    app.gpx.status = active.value && published.value && productVisible.value ? 'warning' : 'inactive'
  } else if (gpx && gpx.value) {
    app.gpx.status = active.value && published.value && productVisible.value ? 'success' : 'inactive'
  }

  if (tours && !tours.value) {
    app.tours.status = active.value && published.value && productVisible.value ? 'warning' : 'inactive'
  } else if (tours && tours.value) {
    app.tours.status = active.value && published.value && productVisible.value ? 'success' : 'inactive'
  }

  return app
}

const getWebMapElements = (dataAvailable, published, unlockable) => {
  const webMap = {
    published: {
      ...published
    },
    unlockable: {
      ...unlockable
    },
    dataAvailable: {
      ...dataAvailable
    }
  }

  if (!dataAvailable.value) {
    webMap.dataAvailable.status = published.value && unlockable.value ? 'warning' : 'inactive'
  } else {
    webMap.dataAvailable.status = published.value && unlockable.value ? 'success' : 'inactive'
  }
  return webMap
}

const getGpxElements = (providesGpxData, gpxData, published) => {
  const gpx = {
    published: {
      ...published
    },
    providesGpxData: {
      ...providesGpxData
    },
    gpxData: {
      ...gpxData
    }
  }

  if (!gpxData.value) {
    gpx.gpxData.status = providesGpxData.value ? 'warning' : 'inactive'
  } else {
    gpx.gpxData.status = published.value && providesGpxData.value ? 'success' : 'inactive'
  }
  return gpx
}

const getIsbnElements = (dataAvailable, published, unlockable, geoData, gpx, tours) => {
  const isbn = {
    published: {
      ...published
    },
    dataAvailable: {
      ...dataAvailable
    },
    ...(geoData ? { geoData: { ...geoData } } : {}),
    ...(gpx ? { gpx: { ...gpx } } : {}),
    ...(tours ? { tours: { ...tours} } : {}),
  }



  if (geoData && !geoData.value) {
    isbn.geoData.status = published.value && unlockable.value ? 'warning' : 'inactive'
  } else if (geoData && geoData.value) {
    isbn.geoData.status = published.value && unlockable.value ? 'success' : 'inactive'
  }

  if (gpx && !gpx.value) {
    isbn.gpx.status = published.value && unlockable.value ? 'warning' : 'inactive'
  } else if (gpx && gpx.value) {
    isbn.gpx.status = published.value && unlockable.value ? 'success' : 'inactive'
  }

  if (tours && !tours.value) {
    isbn.tours.status = published.value && unlockable.value ? 'warning' : 'inactive'
  } else if (tours && tours.value) {
    isbn.tours.status = published.value && unlockable.value ? 'success' : 'inactive'
  }

  if (!dataAvailable.value) {
    isbn.dataAvailable.status = published.value && unlockable.value ? 'warning' : 'inactive'
  } else {
    isbn.dataAvailable.status = published.value && unlockable.value ? 'success' : 'inactive'
  }
  return isbn
}

const getAppResults = (product, app) => {
  const visibility = app.active.value && app.published.value && app.productVisible.value
  
  const unlockable = {
    value: visibility,
  }
  if (product.downloadType.value === 'trails') {
    if (visibility) {
      unlockable.status = 'success'
    } else {
      unlockable.status = app.tours.value ? 'inactive' : 'inactive'
    }
  } else if (product.downloadType.value === 'map') {
    unlockable.value = app.geoData.value
    if (visibility) {
      unlockable.status = app.geoData.value ? 'success' : 'warning'
    } else {
      unlockable.status = app.geoData.value ? 'inactive' : 'inactive'
    }
  } else {
    unlockable.value = false
    unlockable.status = visibility ? 'warning' : 'inactive'
  }
  
  const results = {
    visible: {
      value: visibility,
      status: visibility ? 'success' : 'inactive'
    },
    unlockable
  }
  return results;
}

const getIsbnResults = (product, isbn) => {
  const value = isbn.published.value && product.downloadType.value !== 'none'
  
  const unlockable = {
    value,
  }

  if (product.downloadType.value === 'trails') {
    if (value) {
      unlockable.status = isbn.dataAvailable.value ? 'success' : 'warning'
    } else {
      unlockable.status = isbn.dataAvailable.value ? 'inactive' : 'inactive'
    }
  } else if (product.downloadType.value === 'map') {
    unlockable.value = isbn.dataAvailable.value
    if (value) {
      unlockable.status = isbn.dataAvailable.value ? 'success' : 'warning'
    } else {
      unlockable.status = 'inactive'
    }
  } else {
    unlockable.value = false
    unlockable.status = value ? 'warning' : 'inactive'
  }
  
  const results = {
    unlockable
  }
  return results;
}

const getWebMapResults = (product, webMap) => {
  const value = webMap.published.value && webMap.unlockable.value
  
  const unlockable = {
    value,
  }

  if (product.downloadType.value === 'trails') {
    if (value) {
      unlockable.status = webMap.dataAvailable.value ? 'success' : 'warning'
    } else {
      unlockable.status = webMap.dataAvailable.value ? 'inactive' : 'inactive'
    }
  } else if (product.downloadType.value === 'map') {
    unlockable.value = webMap.dataAvailable.value
    if (value) {
      unlockable.status = webMap.dataAvailable.value ? 'success' : 'warning'
    } else {
      unlockable.status = 'inactive'
    }
  } else {
    unlockable.value = false
    unlockable.status = value ? 'warning' : 'inactive'
  }
  
  const results = {
    unlockable
  }
  return results;
}

const getGpxResults = (product, gpx) => {
  const value = gpx.gpxData.value && product.providesGpxData.value
  
  const downloadable = {
    value,
  }

  if (value) {
    downloadable.status = gpx.published.value && product.providesGpxData.value ? 'success' : 'inactive'
  } else {
    downloadable.status = product.providesGpxData.value ? 'warning' : 'inactive'
  }

  const results = {
    downloadable
  }
  return results;
}


export const validateProduct = (product) => {
  // elements
  const tours = getToursAvailable(product)
  const geoData = getGeoDataAvailable(product)
  const gpx = getGpxDataVailable(product)
  const dataAvailable = getDataAvailability(product)
  const active = getActive(product)
  const published = getPublished(product)
  const typeVisible = isTypeVisible(product)
  const productVisible = isProductVisible(product)
  const unlockable = isUnlockable(product)
  const providesGpxData = doesProvideGpxData(product)

  // app
  const appElements = getAppElements(active, published, productVisible, geoData, gpx, tours)
  const isbnElements = getIsbnElements(dataAvailable, published, unlockable, geoData, gpx, tours)
  const webMapElements = getWebMapElements(dataAvailable, published, unlockable)
  const gpxElements = getGpxElements(providesGpxData, gpx, published)

  const appResults = getAppResults(product, appElements)
  const isbnResults = getIsbnResults(product, isbnElements)
  const webMapResults = getWebMapResults(product, webMapElements)
  const gpxResults = getGpxResults(product, gpxElements)

  return {
    raw: {
      active,
      published,
      typeVisible,
      productVisible,
      unlockable,
      tours,
      geoData,
      gpx
    },
    app: {
      elements: appElements,
      results: appResults
    },
    isbn: {
      elements: isbnElements,
      results: isbnResults
    },
    webMap: {
      elements: webMapElements,
      results: webMapResults
    },
    gpx: {
      elements: gpxElements,
      results: gpxResults
    }
  }
}

export const updateValidations = (product, tours, validations) => {
  const result = {
    ...validations
  }

  const { raw } = validations
  
  const value = tours && tours.length > 0;
  const toursValidation = {
    value,
    message: value ? 'Ja' : 'nein'
  }

  result.app.elements.tours = {
    ...toursValidation
  };
  result.isbn.elements.tours = {
    ...toursValidation
  };

  
  if (value) {
    result.app.elements.tours.status = raw.active.value && raw.published.value && raw.typeVisible.value && raw.productVisible.value ? 'success' : 'inactive'
    result.isbn.elements.tours.status = raw.published.value && raw.unlockable.value ? 'success' : 'inactive'
  } else {
    result.app.elements.tours.status = raw.active.value && raw.published.value && raw.typeVisible.value && raw.productVisible.value ? 'warning' : 'inactive'
    result.isbn.elements.tours.status = raw.published.value && raw.unlockable.value ? 'warning' : 'inactive'
  }

  return result;
}