import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ToastContainer, Flip } from "react-toastify";
import { withRouter } from "react-router-dom";
import NavBar from "components/Core/NavBar";
import { validateAuthentication, logout, SESSION_AUTHENTICATED } from "redux/app/actions";
import { fetchProductTypes } from "redux/productType/actions";
import Routes from "./routes";
import { Preloader } from "react-materialize";

class App extends Component {
  componentDidMount = async () => {
    const result = await this.props.validateAuthentication()
    if (result.type === SESSION_AUTHENTICATED) {
      await this.props.fetchProductTypes()
    }
  };

  logout = () => {
    this.props.logout();
    this.props.history.replace("/login");
  };

  render() {
    const { initialized, authenticated, location, user } = this.props;
    if (!initialized)
      return (
        <div className="loading-app">
          <div className="background">
            <div>
              <Preloader className="init-spinner" size="large" />
              <span>Einen Augenblick bitte...</span>
            </div>
          </div>
        </div>
      );
    return (
      <Fragment>
        {authenticated && <NavBar logout={this.logout} user={user} />}
        <Routes authenticated={authenticated} user={user} location={location} />
        <ToastContainer position="bottom-right" transition={Flip} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialized: state.app.initialized,
  authenticated: state.app.authenticated,
  user: state.app.user,
  location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  validateAuthentication: () => dispatch(validateAuthentication()),
  fetchProductTypes: () => dispatch(fetchProductTypes()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
