import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import MinusCircleIcon from 'mdi-react/MinusCircleIcon';
import React from 'react';
import styles from './ProductTypeCard.module.css';
import ProductTypeImage from 'components/Product/ProductTypeImage/ProductTypeImage';
import WarningCircleIcon from 'mdi-react/WarningCircleIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { Badges, Badge, ListCard } from 'components/Core/ListCard';

class ProductTypeCard extends React.Component {

  today = new Date();

  onClick = () => {
    this.props.onClick(this.props.productType)
  }

  onOpenEditDialog = (e) => {
    e.stopPropagation();
    this.props.onOpenEditDialog(this.props.productType)
  }

  onClickShopLink = (e) => {
    e.stopPropagation();
  }

  render() {
    const { productType, user } = this.props;

    return (
      <ListCard onClick={this.onClick}>

        <div className={styles.left}>
          <div className={styles.coverImage}>
            <ProductTypeImage
              className={styles.typeImage}
              blurHash={productType.blurHash}
              url={productType.imageUrl}
              renderName={(isLoading, error) => {
                if (isLoading) {
                  return (
                    <div className={`${styles.name} ${styles.isLoadingImage}`}>
                      {productType.name}
                    </div>
                  )
                }
                if (error) {
                  return (
                    <div className={`${styles.name} ${styles.isErrorImage}`}>
                      {productType.name}
                    </div>
                  )
                }
                return (
                  <div className={`${styles.name}`}>
                    {productType.name}
                  </div>
                )
              }} />
          </div>

        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  <b>{productType.name}</b> ・ {productType.description}
                </div>
              </div>
              <Badges className={styles.badges} withSeparator={user.roles.admin || user.roles.productAdmin}>
                {
                  productType.appCategory && !(productType.shortDescriptionDe || productType.shortDescriptionEn) && (
                    <Badge type="warning">
                      <WarningCircleIcon />
                      <div>Beschreibung</div>
                    </Badge>
                  )
                }
                {
                  productType.appCategory && !productType.imageUrl && (
                    <Badge type="warning">
                      <WarningCircleIcon />
                      <div>Bild</div>
                    </Badge>
                  )
                }
                {
                  productType.appCategory && productType.downloadType === 'none' && (
                    <Badge type="warning">
                      <WarningCircleIcon />
                      <div>Freischaltung</div>
                    </Badge>
                  )
                }
              </Badges>
              {
                (user.roles.admin || user.roles.productAdmin) && (
                  <button onClick={this.onOpenEditDialog} className={styles.editButton}>
                    <PencilIcon className={styles.pencilIcon} />
                    <div>Bearbeiten</div>
                  </button>
                )
              }
            </div>
          </div>
          <div className={styles.rightBottom}>
            <div className={`${styles.idElement}`}>
              <div className={styles.label}>
                ID
              </div>
              <div className={styles.valueTwoItems}>
                {productType.id}
              </div>
            </div>
            <div className={`${styles.isbnElement}`}>
              <div className={styles.label}>
                VLB-Code
              </div>
              <div className={styles.valueTwoItems}>
                {productType.vlbShortcode}
              </div>
            </div>
            <div className={`${styles.dataElement}`}>
              <div className={styles.label}>
                Blattschnitt
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    productType.providesGeoData ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.typeIcon}`} />
                  }
                  &nbsp;
                  <div>
                    {
                      productType.providesGeoData ? 'Ja' : 'Nein'
                    }
                  </div>

                </div>
              </div>
            </div>
            <div className={`${styles.dataElement}`}>
              <div className={styles.label}>
                GPX-Download
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    productType.providesGpxData ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.typeIcon}`} />
                  }
                  &nbsp;
                  <div>
                    {
                      productType.providesGpxData ? 'Ja' : 'Nein'
                    }
                  </div>

                </div>
              </div>
            </div>
            <div className={`${styles.dataElement}`}>
              <div className={styles.label}>
                Anzeige in der App
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    productType.appCategory ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.typeIcon}`} />
                  }
                  &nbsp;
                  <div>
                    {
                      productType.appCategory ? 'Aktiv' : 'Deaktiviert'
                    }
                  </div>

                </div>
              </div>
            </div>
            <div className={`${styles.dataElement}`}>
              <div className={styles.label}>
                Freischaltung
              </div>
              <div className={styles.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    productType.downloadType !== 'none' ? <CheckCircleIcon className={`${styles.okIcon}`} /> : <MinusCircleIcon className={`${styles.typeIcon}`} />
                  }
                  &nbsp;
                  <div>
                    {productType.downloadType === 'none' && 'Keine'}
                    {productType.downloadType === 'map' && 'Blattschnitt'}
                    {productType.downloadType === 'trails' && 'Touren'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ListCard>
    )
  }
}

export default ProductTypeCard;