import {connect} from 'react-redux';
import Regions from 'components/Destinations/Regions';
import { fetchRegions } from 'redux/destination/region/list/actions';

const mapStateToProps = state => ({
  regions: state.destination.region.list.regions,
  keyword: state.destination.region.list.keyword,
  isLoading: state.destination.region.list.isLoading
}) 

const mapDispatchToProps = dispatch => ({
  fetchRegions: (keyword) => dispatch(fetchRegions(keyword))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Regions)
