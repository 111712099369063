import React from "react";
import Page from "components/Core/Page";
import Title from "components/Core/Title";
import Content from "components/Core/Content";
import SearchBox from "components/Core/SearchBox";
import ProductTypesFilter from "./ProductTypesFilter";
import ProductTypesList from "./ProductTypesList";
import ProductTypeEditModal from "./ProductTypeEditModal";
import ProductTypeDetails from "./ProductTypeDetails";

class ProductTypes extends React.Component {
  componentDidMount = () => {
    //this.props.fetchOnLoad();
  };

  onSubmitSearch = (query) => {
    this.props.updateFilter({
      ...this.props.filter,
      query,
    });
  };

  onSearch = (filter) => {
    this.props.updateFilter({
      ...this.props.filter,
      ...filter,
    });
  };

  onClickProductType = (productType) => {
    this.detailsOverlay.open(productType)
    // this.editModal.open(productType)
  };

  onOpenEditDialog = (productType) => {
  this.editModal.open(productType)
  }

  render() {
    const { productTypes, user } = this.props;
    return (
      <Page>
        <Title
          renderSearch={() => (
            <SearchBox
              query={this.props.filter.keyword}
              placeholder="Produkttypen durchsuchen..."
              onSubmit={this.onSubmitSearch}
              autoFocus
              submitOnKeyDown
              isFetching={this.props.isLoading}
            />
          )}
        >
          Produkttypen
        </Title>
        <Content>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ProductTypesFilter filter={this.props.filter} onSearch={this.onSearch} />
            <div style={{ paddingLeft: '320px', flex: 1 }}>
              <ProductTypesList 
                productTypes={productTypes} 
                onClick={this.onClickProductType} 
                onOpenEditDialog={this.onOpenEditDialog}
                user={user} />
            </div>
          </div>
        </Content>
        <ProductTypeDetails
          user={user}
          onOpenEditDialog={this.onOpenEditDialog}
          ref={(c) => (this.detailsOverlay = c)}
        />
        <ProductTypeEditModal 
          ref={c => this.editModal = c} 
          onSubmit={this.props.editProductType}/>
      </Page>
    );
  }
}

export default ProductTypes;
