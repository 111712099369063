import React from 'react';

export default ({ children, style, className = '', width, empty, withIcon, ...rest }) => {
    const clazzName = `${className} ${empty ? 'empty' : ''}  ${withIcon ? 'with-icon' : ''}`;
    const styles = (style || width) ? {
        ...style,
        ...width ? { width, minWidth: width } : null
    } : null;
    return (
        <td className={clazzName} style={styles} { ...rest }>
            {children}
        </td> 
    )
}