import React from 'react';
import Box from 'components/Core/Box';
import TabContent from 'components/Core/TabContent';
import TextInput from 'react-materialize/lib/TextInput';
import Row from 'react-materialize/lib/Row';
import Editor from 'components/Core/Editor';
import Button from 'react-materialize/lib/Button';
import { Switch } from 'react-materialize';
import { toast } from 'react-toastify';

const subRegionToState = (subRegion) => {
    return {
        ...subRegion,
        visible: subRegion.visible && subRegion.visible === '1'
    }
}

class General extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            subRegion: subRegionToState(props.subRegion)
        }
    }

    onChange = (event) => {
        this.setState({
            subRegion: {
                ...this.state.subRegion,
                [event.currentTarget.name]: event.currentTarget.value
            }
        });
    }

    onChangeEditor = (description) => {
        this.setState({
            subRegion: {
                ...this.state.subRegion,
                description
            }
        });
    }

    onChangeVisibility = (event) => {
        this.setState({
            subRegion: {
                ...this.state.subRegion,
               visible: event.currentTarget.checked
            }
        });
    }

    validate = () => {

    }

    onSubmit = () => {
        const { subRegion } = this.state;
        const { editMode } = this.props;
        this.props.onSubmit(subRegion.id, {
            name: subRegion.name,
            region_id: subRegion.region_id,
            key: subRegion.key,
            description: subRegion.description,
            visible: subRegion.visible ? '1' : '0'
        })
        .then(() => {
            if (editMode) {
                toast("Die Subregion wurde aktualisiert", { type: "success", autoClose: 2000 });
            } else {
                toast("Die Subregion wurde erstellt", { type: "success", autoClose: 2000 });
            }
        })
        .catch(err => {
            console.log(err);
            toast(`Beim ${editMode ? 'Aktualisieren' : 'Erstellen'} der Subregion ist ein Fehler aufgetreten`, { type: "error" });
        });
    }

    onCancel = () => {
        if (this.props.editMode) {
            this.setState({
                subRegion: this.subRegionToState(this.props.subRegion)
            })
        } else {
            this.props.onClose()
        }
    }


    render() {
        const { subRegion } = this.state;
        const { editMode } = this.props;
        return (
            <TabContent clearMargin={editMode}>
                <Box title="Region" renderActions={() => (
                    <div style={{ width: '143px'}}>
                        <Switch onLabel="" l={12} name="visible" onChange={this.onChangeVisibility} checked={subRegion.visible} offLabel={subRegion.visible ? 'Veröffentlicht' : 'Unsichtbar'}/>
                    </div>
                )}>
                    <Row>
                        <TextInput label="Eindeutiger Key" l={3} name="key" value={subRegion.key} onChange={this.onChange }/>
                        <TextInput label="Name" l={9} name="name" value={subRegion.name} placeholder="Bezeichnung der Subregion" autoComplete="off" onChange={this.onChange }/>
                    </Row>
                </Box>
                <br />
                <Box title="Beschreibung" styles={{ content: { padding: '0px'}}}>
                    <Editor value={subRegion.description} onChange={this.onChangeEditor }/>
                </Box>
                <br />
                <Button waves="light" onClick={this.onSubmit}>Änderungen speichern</Button>
                &nbsp;&nbsp;
                <Button waves="light" flat onClick={this.onCancel}>{ editMode ? 'Zurücksetzen' : 'Abbrechen'}</Button>
            </TabContent>
        )
    }
}

export default General;
