import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubRegionDetails from 'components/Destinations/SubRegionDetails';
import { fetchSubRegion, resetSubRegion, updateSubRegion, addProductToSubregion, removeProductFromSubregion } from 'redux/destination/subRegion/actions';

class SubRegionPage extends Component {
    
    componentDidMount = () => {
        this.props.fetchSubRegion(this.props.match.params.subRegionId);
    }

    render() {
        return <SubRegionDetails { ... this.props } />
    }
}

const mapStateToProps = (state, ownProps) => ({
    editMode: ownProps.match.params.subRegionId !== 'new',
    isLoading: state.destination.subRegion.isLoading,
    subRegion: state.destination.subRegion.subRegion || {},
    region: state.destination.region.details.region || {},
    isLoadingRegion: state.destination.region.details.isLoading,
    products: state.destination.subRegion.products
}) 

const mapDispatchToProps = dispatch => ({
  fetchSubRegion: (id) => dispatch(fetchSubRegion(id)),
  onUpdateSubRegion: (id, model) => dispatch(updateSubRegion(id, model)),
  reset: () => dispatch(resetSubRegion()),
  addProductToSubregion: (subRegion, product) => dispatch(addProductToSubregion(subRegion, product)),
  removeProductFromSubregion: (subRegion, product) => dispatch(removeProductFromSubregion(subRegion, product))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubRegionPage)
