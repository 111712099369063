import DestinationsApi from "api/DestinationsApi";
export const FETCH_REGIONS = "kompass/crm/region/FETCH_REGIONS";
export const FETCH_REGIONS_FINISHED =
  "kompass/crm/region/FETCH_REGIONS_FINISHED";

export const fetchRegions = (keyword) => {
  return (dispatch) => {
    dispatch(fetchRegionsStarted(keyword));
    return DestinationsApi.findRegions(keyword)
      .then((r) => {
        return dispatch(fetchRegionsFinished(r));
      })
      .catch((err) => {
        if (!DestinationsApi.isCanceled(err)) {
          console.log(err);
        }
      });
  };
};

export const fetchRegionsStarted = (keyword) => ({
  type: FETCH_REGIONS,
  keyword,
});

export const fetchRegionsFinished = (result) => ({
  type: FETCH_REGIONS_FINISHED,
  payload: {
    ...result,
  },
});
