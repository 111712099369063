/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import styles from "./UserList.module.css";
import Loading from "components/Core/Loading";
import { UserCard } from "./UserCard";
import IsEmpty from "components/Core/IsEmpty";
import ReactPaginate from "react-paginate";


class ProductTypesList extends React.Component {

  renderEmptyMessage = () => {
    const { users } = this.props;
    return (
      <div className={styles.resultCount}>
        {
          `${users.length === 1 ? 'Ein' : `${users.length}`} Benutzer`
        }
      </div>
    )
  }

  paginate = ({ selected }) => {
    this.props.paginate(selected);
  };

  render() {
    const {
      users,
      onClick,
      pagination,
      onOpenEditDialog,
      isLoading,
    } = this.props;
    if (!users) {
      return <Loading />
    }
    if (!users.length) {
      return <IsEmpty message="Keine Benutzer gefunden" />
    }
    return (
      <div className={isLoading ? styles.isLoading : ''}>
        {
          pagination.resultSize > 0 && (
            <div className={styles.resultCount}>
              {
                `${pagination.resultSize === 1 ? 'Ein' : `${pagination.resultSize}`} Benutzer gefunden`
              }
            </div>
          )
        }
        {
          users && users.map((user) => (
            <UserCard 
              key={user.ssoId} 
              user={user} 
              onClick={onClick} 
              onOpenEditDialog={onOpenEditDialog} />
          ))
        }
        {users && users.length > 0 && pagination && pagination.resultSize > 0 && (
          <div className={styles.pagination}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="&#8594;"
              onPageChange={this.paginate}
              pageRangeDisplayed={5}
              pageCount={pagination.totalPages}
              previousLabel="&#8592;"
              forcePage={pagination.page}
              className="pagination"
              renderOnZeroPageCount={null}
            />
            <div className={styles.resultSize}>
              {
                `${pagination.resultSize < 2 ? 'Ein' : `${pagination.resultSize}`} Benutzer gefunden`
              }
            </div>
          </div>
        )}

      </div>
    );
  }
}

export default ProductTypesList;
