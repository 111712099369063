import {
    FETCH_REGION,
    FETCH_REGION_SUCCESS,
    UPDATE_REGION,
    UPDATE_REGION_SUCCESS,
    UPDATE_REGION_FAILED
} from './actions';
import { UPDATE_SUBREGION_SUCCESS } from 'redux/destination/subRegion/actions';

const _initialState = {
}

const details = (state = _initialState, action) => {
    switch (action.type) {
        case FETCH_REGION:
            return Object.assign({}, state, {
                isLoading: true
            })
        case FETCH_REGION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                ...action.payload
            })
        case UPDATE_REGION:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: true
            });
        case UPDATE_REGION_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false,
                region: {
                    ...state.region,
                    ...action.payload
                }
            });
        
        case UPDATE_SUBREGION_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                subRegions: state.subRegions.map(subRegion => {
                    if (subRegion.id === action.payload.id) {
                        subRegion = {
                            ...subRegion,
                            ...action.payload
                        }
                    } 
                    return subRegion
                })
            })
        
        case UPDATE_REGION_FAILED:
            return Object.assign({}, state, {
                ...state,
                isSubmitting: false
            });
        
        default:
            return state;
    }
}

export default details;