import React from "react";
import { useImage } from "react-image";
import ErrorBoundary from "./ErrorBoundary";
import styles from './ProductImage.module.css'
import coverImageUnavailable from './cover_image_unavailable.svg'
import coverImageLoading from './cover_image_loading.svg'
import coverImageNotSet from './cover_image_not_set.svg'

const ProductImage = ({ url, className = '' }) => {
  const { src, isLoading, error } = useImage({
    srcList: url ? [url, coverImageUnavailable] : coverImageNotSet,
    useSuspense: false
  });
  return (
    <div className={`${styles.container} ${className}`}>
      {
        isLoading && <img src={coverImageLoading} alt="Cover" />
      }
      {
        !isLoading && <img src={src} alt="Cover" title={url ? (error ? 'Nicht gefunden' : 'Cover-Bild') : 'Nicht verfügbar'} />
      }
    </div>
  )
};

export default ({ url, className = '' }) => {
  return (
    <ErrorBoundary>
      <ProductImage url={url} className={className}/>
    </ErrorBoundary>
  )
};
