/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { Component } from 'react';
import { Button } from 'react-materialize';
import Modal from 'react-modal';
import styles from './ProductVenusModal.module.css';
import { toast } from 'react-toastify';
import VenusApi from 'api/VenusApi';
import ChannelSelection from './ChannelSelection';
import SelectedValue from './SelectedValue';
import { connect } from 'react-redux';
import { fetchChannels } from 'redux/venus/actions';
import ArrowDown from 'mdi-react/ArrowDownBoldOutlineIcon'
import TourApi from 'api/TourApi';


class ProductVenusModal extends Component {

  state = {
    open: false,
    isSubmitting: false,
    model: {
      channel: {},
      edition: {}
    },
    product: {},
    editions: null,
    editionsError: false
  }

  open = async (product) => {

    const model = {
      channel: {
        id: product.channelId,
        name: product.channelName
      },
      edition: {
        id: product.editionId,
        name: product.editionName
      },
    }



    this.setState({
      open: true,
      product,
      model,
      isFetchingEditions: !!product.channelId
    })

    const hasChannelsFetched = this.props.channels?.length && !this.props.channelsHadError

    const result = await Promise.all([
      product.channelId && !this.props.channelsHadError ? this.callFetchEditionsApi(product.channelId) : {},
      hasChannelsFetched ? Promise.resolve(true) : this.props.fetchChannels(),
    ])

    const editions = result[0].editions || []
    const editionsError = result[0].hasError
    this.setState({
      editions,
      editionsError,
      isFetchingEditions: false,
      model: {
        ...this.state.model,
        ...(editionsError || this.props.channelsHadError ? { edition: { id: null, name: ''}} : {}),
        ...(this.props.channelsHadError  ? { channel: { id: null, name: ''}} : {})
      }
    })
  }

  refetchChannels = () => {
    this.props.fetchChannels(true)
  }

  callFetchEditionsApi = async (channelId) => {
    try {
      const { editions } = await VenusApi.findEditions(channelId)
      return {
        editions,
        hasError: false
      }
    } catch (error) {
      const msg = error.message || 'Die Auflagen des ausgewählten Channels konnten nicht geladen werden.'
      toast(msg, { type: 'error', autoClose: 2000 })
      // ignore
    }
    return {
      editions: [],
      hasError: true
    }
  }

  refetchEditions = () => {
    if (this.state.model.channel?.id) {
      this.setState({
        isFetchingEditions: true,
        editionsError: false
      }, async () => {
        const { editions, hasError } = await this.callFetchEditionsApi(this.state.model.channel.id)
        this.setState({
          editions,
          editionsError: hasError,
          isFetchingEditions: false
        })
      })

    }
  }

  close = () => {
    // Don't close the modal, while saving
    if (this.state.isSubmitting) return false;

    this.setState({
      open: false,
    }, () => {
      this.setState({
        product: {},
        model: {
          channel: {},
          edition: {}
        },
      })
    })
  }

  submit = async () => {
    this.setState({ isSubmitting: true });

    const updateModel = {
      channelId: this.state.model.channel?.id || null,
      channelName: this.state.model.channel?.name || null,
      editionId: this.state.model.edition?.id || null,
      editionName: this.state.model.edition?.name || null,
    }

    try {
      await this.props.onSubmit(this.state.product, updateModel)
      if (updateModel.channelId && updateModel.editionId) {
        await TourApi.syncEdition(updateModel.channelId, updateModel.channelName, updateModel.editionId)
      }
      toast('Produkt aktualisiert', { type: 'success', autoClose: 2000 })
      this.props.onUpdateProduct(updateModel)
      this.setState({
        isSubmitting: false
      })
      this.close();

    } catch (e) {
      this.setState({ isSubmitting: false });
      toast(e.message || 'Unerwarteter Fehler', { type: 'error' })
    }
  }

  onSelectChannel = async (channel) => {
    this.setState({
      model: {
        ...this.state.model,
        channel: channel || {},
        edition: {

        }
      },
      editions: [],
      isFetchingEditions: !!channel
    }, async () => {
      if (channel) {
        const { editions, hasError } = await this.callFetchEditionsApi(channel.id)
        let edition = {
          id: null,
          name: ''
        }
        if (!hasError && editions.length === 1) {
          edition = editions[0]
        }
        this.setState({
          editions,
          editionsError: hasError,
          isFetchingEditions: false,
          model: {
            ...this.state.model,
            edition
          }
        })
      }
    })

  }

  onSelectEdition = (edition) => {
    this.setState({
      model: {
        ...this.state.model,
        edition: {
          ...edition
        }
      }
    }, () => {
      console.log(this.state.model)
    })

  }



  render() {
    const { open, product, model, isSubmitting, editions, editionsError, isFetchingEditions } = this.state;
    const { isFetchingChannels, channels, channelsHadError } = this.props;
    let emptyEditionMessage = !model.channel.id ? 'Zuerst Channel auswählen' : null
    if (model.channel.id && !isFetchingEditions && editions && editions.length < 1) {
      emptyEditionMessage = 'Dieser Channel enthält keine Auflagen.'
    }
    const disabledSaveButton = isSubmitting || !model.channel?.id || !model.edition?.id
    return (
      <Modal
        isOpen={open}
        className={`${styles.modal} modal`}
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={!isSubmitting}
        bodyClassName="modal--open"
        contentLabel="Publication Planner Zuordnung">
        <>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>Publication Planner Zuordnung</div>
            <div className={styles.modalSubTitle}>{ product?.key }・{ product?.isbn }・{ product?.name }</div>
          </div>
          <div class="modal-content">
            <div className={styles.rows}>
              <div>
                <ChannelSelection
                  label="Channel"
                  data={channels}
                  selected={model.channel.id}
                  isFetching={isFetchingChannels}
                  hasError={channelsHadError}
                  errorMessage="Die Channels konnten nicht geladen werden."
                  onSelect={this.onSelectChannel}
                  refetch={this.refetchChannels}
                  withRefetch
                  allowUnselect
                  placeholder="Channels durchsuchen..." />
                <ArrowDown className={styles.arrowDownIcon} />
                <SelectedValue 
                  title="Channel" 
                  value={model.channel} 
                  isFetching={isFetchingChannels}
                  placeholder="Kein Channel zugeordnet" />
              </div>

              <div>
                <ChannelSelection
                  label="Auflagen"
                  data={editions}
                  selected={model.edition.id}
                  isFetching={isFetchingChannels || isFetchingEditions}
                  hasError={editionsError}
                  errorMessage="Die Auflagen des ausgewählten Channels konnten nicht geladen werden."
                  refetch={this.refetchEditions}
                  withRefetch={model.channel?.id}
                  onSelect={this.onSelectEdition}
                  placeholder="Auflagen durchsuchen..."
                  emptyMessage={emptyEditionMessage} />
                <ArrowDown className={styles.arrowDownIcon} />
                <SelectedValue 
                  title="Auflage" 
                  value={model.edition} 
                  isFetching={isFetchingChannels || isFetchingEditions}
                  placeholder="Keine Auflage zugeordnet" />
              </div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <Button waves="light" onClick={this.submit} disabled={disabledSaveButton}>Änderungen speichern</Button>
            &nbsp;&nbsp;
            <Button waves="light" flat onClick={this.close}>Abbrechen</Button>
          </div>
        </>


      </Modal >
    )
  }
}

const mapStateToProps = (state) => ({
  channels: state.venus.channels,
  channelsHadError: state.venus.hasError,
  isFetchingChannels: state.venus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchChannels: (forceRefetch) => dispatch(fetchChannels(forceRefetch)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ProductVenusModal);