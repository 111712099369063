
import { combineReducers } from 'redux'
import app from './app';
import support from './support';
import product from './product';
import productType from './productType';
import destination from './destination';
import venus from './venus';

export default combineReducers({
  app,
  destination,
  product,
  productType,
  support,
  venus,
})
