import TourApi from "api/TourApi";
import DetailsOverlay from "components/Core/DetailsOverlay";
import { updateValidations, validateProduct } from "lib/validateProduct";
import React, { Component } from "react";
import { Tab, Tabs } from "react-materialize";
import AppTab from "./AppTab";
import GeoDataTab from "./GeoDataTab";
import Header from "./Header";
import OverviewTab from "./OverviewTab";
import styles from "./ProductDetails.module.css";
import ToursTab from "./ToursTab";

class ProductDetails extends Component {
  state = {
    product: null,
    isOpen: false,
    isFetching: false,
    tab: "overview",
    isFetchingTours: false,
    validations: null,
    tours: null,
    problems: null
  };

  onUpdateProduct = (updateModel) => {
    this.setState({
      product: {
        ...this.state.product,
        ...updateModel
      }
    })
  }

  open = async (product) => {
    const validations = validateProduct(product);
    this.setState({
      product,
      validations,
      isOpen: true,
      isFetching: false,
      isFetchingTours: product.downloadType.value === 'trails',
    });
    console.log('product', product)
    console.log('validateion', validations)
    document.body.style.overflow = "hidden";
    try {
      if (product.downloadType.value === 'trails') {
        this.props.fetchVenusChannels()
        let tours = []
        let problems = []
        if (product.channelId && product.editionId) {
          const result = await TourApi.find(product.channelId, product.editionId)
          tours = result.tours;
          problems = result.problems;
        }

        const updatedValidations = updateValidations(product, tours, validations)
        this.setState({
          tours,
          problems,
          validations: updatedValidations,
          isFetching: false,
          isFetchingTours: false,
        })
      }
    } catch (e) {
      console.log(e)
      this.setState({
        tours: [],
        isFetching: false,
        isFetchingTours: false,
      })
    }

  };

  close = (event) => {
    event.stopPropagation();
    document.body.style.overflow = "initial";
    this.setState({
      isOpen: false,
      isFetching: false,
    });
  };

  render() {
    const { product, isOpen, tab, validations, tours, problems, isFetchingTours } = this.state;
    const { openUploadModal } = this.props;
    if (!product || !isOpen) return null;

    return (
      <DetailsOverlay title="Produkt" close={this.close}>
        <Header product={product} />
        <div className={styles.body}>
          <Tabs className={styles.tabs}>
            <Tab
              title="Übersicht"
              active={tab === "overview"}
              key="overview"
              className={styles.tab}
            >
              <OverviewTab
                product={product}
                openUploadModal={openUploadModal}
              />
            </Tab>
            <Tab
              title="Blattschnitt"
              active={tab === "geoData"}
              key="geoData"
              className={styles.tab}
            >
              <GeoDataTab product={product} />
            </Tab>
            <Tab
              title="Validierung"
              active={tab === "validations"}
              key="validations"
              className={styles.tab}
            >
              <AppTab product={product} validations={validations} />
            </Tab>
            <Tab
              title="Touren"
              active={tab === "tours"}
              key="tours"
              disabled={product.downloadType.value !== 'trails'}
              className={styles.tab}
            >
              <ToursTab product={product} tours={tours} problems={problems} isFetching={isFetchingTours} openVenusModal={this.props.openVenusModal} />
            </Tab>


          </Tabs>
        </div>
      </DetailsOverlay>
    );
  }
}

export default ProductDetails;
