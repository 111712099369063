import axios from "axios";
import appConfig from "lib/config";

export default class AxiosRequest {
  constructor() {
    this.axios = axios.create({
      baseURL: this.getBaseUrl(),
    });

    this.axios.interceptors.request.use((config) => {
      const token = localStorage.getItem("kompass_crm_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.params = {
        ...(config.params ? config.params : {}),
        key: config.params && config.params.key ? config.params.key : appConfig.clientKey,
        apiKey: appConfig.apiKey
      }
      return config;
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (this.isCanceled(error)) {
          return Promise.reject(error);
        }
        const originalRequest = error.config;
        if (
          !originalRequest.skipTokenRefresh &&
          error.response &&
          error.response.status === 403
        ) {
          document.location = "/login";
          originalRequest._retry = true;
          return Promise.reject(error);
        }
        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.skipTokenRefresh
        ) {
          originalRequest._retry = true;
          try {
            await this.refreshToken();
          } catch (e) {
            return Promise.reject(error);
          }

          if (!localStorage.getItem("kompass_crm_token"))
            return Promise.reject(error);

          return this.axios(originalRequest);
        }
        return Promise.reject(error);
      }
    );
  }

  isCanceled(thrown) {
    return axios.isCancel(thrown);
  }

  async refreshToken() {
    const currentRefreshToken = localStorage.get('kompass_crm_rt')
    const { token, refreshToken: newRefreshToken } = await this.post("/auth/refresh", {
      skipTokenRefresh: true,
      baseURL: appConfig.backendUrl,
      ...(currentRefreshToken ? {
        data: {
          refreshToken: currentRefreshToken
        }
      } : {})
    });
    if (token) {
      localStorage.setItem("kompass_crm_token", token);
    } else {
      localStorage.removeItem("kompass_crm_token");
    }
    if (newRefreshToken) {
      localStorage.setItem("kompass_crm_rt", newRefreshToken);
    } else {
      localStorage.removeItem("kompass_crm_rt");
    }
  }

  async _doRequest(url, method, options = {}) {
    try {
      const response = await this.axios(url, {
        method,
        ...options,
      });
      return response.data;
    } catch (error) {
      console.log('the error', error)
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          throw new Error(error.response.data.message);
        } else {
          throw error.response.data;
        }
      } else {
        throw error;
      }
    }
  }

  async get(url, options) {
    return this._doRequest(url, "get", options);
  }

  async post(url, options) {
    return this._doRequest(url, "post", options);
  }

  async put(url, options) {
    return this._doRequest(url, "put", options);
  }

  async delete(url, options) {
    return this._doRequest(url, "delete", options);
  }

  getBaseUrl() {
    return appConfig.backendUrl;
  }
}
