/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { Component } from 'react';
import { Button } from 'react-materialize';
import Modal from 'react-modal';
//Modal.setAppElement('#root')
import ModalTitle from 'components/Core/ModalTitle';
import Dropzone from 'react-dropzone'
import './ProductFileUploadModal.css';
import UploadIcon from 'mdi-react/FileDownloadOutlineIcon';
import FileCheckIcon from 'mdi-react/FileCheckOutlineIcon';
import Spinner from 'components/Core/Spinner';
import { toast } from 'react-toastify';

const Config = {
    blattschnitt: {
        title: 'Blattschnitt hochladen',
        //accept: '.txt, .json, .geoson',
        extensions: ['geojson', 'txt', 'json'],
        allowedFileTypes: 'Erlaubte Dateitypen: *.geojson, *.txt, *.json'
    },
    gpx: {
        title: 'GPX-Tracks hochladen',
        //accept: 'application/zip',
        extensions: ['zip'],
        allowedFileTypes: 'Erlaubte Dateitypen: *.zip (maximal 15MB)',
    }
}

class ProductFileUploadModal extends Component {

    state = {
        open: false,
        isSubmitting: false,
        uploadType: 'blattschnitt'
    }



    open = (product, uploadType = 'blattschnitt') => {
        this.setState({
            open: true,
            product,
            uploadType
        })
    }

    close = () => {
        // Don't close the modal, if we are uploading a file
        if (this.state.isSubmitting) return false;

        this.setState({
            open: false
        }, () => {
            this.setState({
                product: null,
                uploadType: 'blattschnitt',
                file: null
            })
        })
    }

    submit = async () => {
        this.setState({ isSubmitting: true });
        try {
            await this.props.onSubmit(this.state.file, this.state.product, this.state.uploadType)
            toast('Produkt aktualisiert', { type: 'success', autoClose: 2000 })
            this.setState({ isSubmitting: false });
            this.close();

        } catch (e) {
            this.setState({ isSubmitting: false });
            toast(e.message || 'Unerwarteter Fehler', { type: 'error' })
        }

    }

    onDrop = (files) => {
        if (files && files.length) {
            this.setState({
                file: files[0]
            })
        }
    }

    openFileDialog = () => {
        if (this.dropZone) this.dropZone.open()
    }


    render() {
        const { open, product, file, uploadType, isSubmitting } = this.state;

        return (
            <Modal
                isOpen={open}
                className="modal"
                onRequestClose={this.close}
                shouldCloseOnOverlayClick={!isSubmitting}
                bodyClassName="modal--open"
                contentLabel="Dateiupload">
                <div class="modal-content">
                    <ModalTitle>
                        {Config[uploadType].title}
                        {product && <small>{product.name}</small>}
                    </ModalTitle>
                    <Dropzone
                        onDrop={this.onDrop}
                        noClick
                        noKeyboard
                        ref={c => this.dropZone = c}
                        accept={Config[uploadType].accept}
                        multiple={false}
                        {
                        ...(uploadType !== 'blattschnitt' ? { minSize: 1000 } : {})
                        }>
                        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                            <section>
                                <div {...getRootProps({
                                    className: `file-drop-zone ${isDragReject ? 'drag-reject' : ''} ${isSubmitting ? 'is-submitting' : ''}`,
                                    style: {
                                        ...(isDragActive ? { borderColor: 'blue' } : {}),
                                        ...(isDragAccept ? { borderColor: '#008275' } : {}),
                                        ...(isDragReject ? { borderColor: '#f44336' } : {})
                                    }
                                })}>
                                    <input {...getInputProps()} />
                                    {!file && <UploadIcon className="upload-icon" />}
                                    {file && <FileCheckIcon className="file-selected-icon" />}
                                    <div className={`file-info ${!file ? 'empty' : ''}`}>
                                        {(file && file.name) || 'Keine Datei ausgewählt'}
                                    </div>
                                    <p>Ziehe eine Datei hierher oder&nbsp;
                                        <a onClick={this.openFileDialog}>wähle eine aus</a>.</p>
                                    <p className="allowed-file-types">{Config[uploadType].allowedFileTypes}</p>
                                    <div className="not-allowed">Ungültiger Dateityp</div>
                                    {
                                        isSubmitting && (
                                            <div className="spinner-container valign-wrapper">
                                                <div>
                                                    <Spinner size={48} borderSize={8} />
                                                    <p>
                                                        Wird hochgeladen...
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <div class="modal-footer">
                    <Button waves="light" onClick={this.submit} disabled={!file || isSubmitting}>{Config[uploadType].title}</Button>
                    &nbsp;&nbsp;
                    <Button waves="light" flat onClick={this.close}>Abbrechen</Button>
                </div>
            </Modal>
        )
    }
}

export default ProductFileUploadModal;