import { encode, decode } from "blurhash";
import * as StackBlur from 'stackblur-canvas';

const readFromFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (...args) => reject(args);
      img.src = e.target.result;
    }
    // you have to declare the file loading
    reader.readAsDataURL(file);
  });
}

const getImageData = image => {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext("2d");
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
};

export const blurHashToImage = (blurHash, width, height) => {
  const pixels = decode(blurHash, width, height);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const imageData = ctx.createImageData(width, height);
  imageData.data.set(pixels);
  ctx.putImageData(imageData, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      const file = new File([blob], "blurredImage.jpg");
      resolve(file)
    }, 'image/jpeg');
  })
}

const getGaussianBlurredFile = (image, width, height) => {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  StackBlur.image(image, canvas, 48, false);
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      const file = new File([blob], "blurredImage.jpg");
      resolve(file)
    }, 'image/jpeg');
  })
  
}

export const getBlurredImage = async (file, callback) => {
  const image = await readFromFile(file);
  const imageData = getImageData(image);
  const blurHash = encode(imageData.data, imageData.width, imageData.height, 4, 4);
  const blurredFile = await getGaussianBlurredFile(image, imageData.width, imageData.height)
  if (callback) {
    callback({
      blurHash,
      blurredFile
    })
    return
  }
  return {
    blurHash,
    blurredFile
  }
}