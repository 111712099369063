import { connect } from "react-redux";
import Product from "components/Product";
import {
  fetchOnLoad,
  fetchProducts,
  paginate,
  resetFilter,
  uploadGeoJson,
  uploadGpx,
  editProduct,
} from "redux/product/actions";
import { fetchChannels } from "redux/venus/actions";

const mapStateToProps = (state) => ({
  products: state.product.products,
  pagination: state.product.pagination,
  filter: state.product.filter,
  isLoading: state.product.isLoading,
  user: state.app.user
});

const mapDispatchToProps = (dispatch) => ({
  search: (filter) => dispatch(fetchProducts(filter)),
  paginate: (page) => dispatch(paginate(page)),
  fetchOnLoad: () => dispatch(fetchOnLoad()),
  uploadGeoJson: (productId, file) => dispatch(uploadGeoJson(productId, file)),
  uploadGpx: (productId, file) => dispatch(uploadGpx(productId, file)),
  editProduct: (productId, model) => dispatch(editProduct(productId, model)),
  onResetFilter: () => dispatch(resetFilter()),
  fetchVenusChannels: (forceRefetch) => dispatch(fetchChannels(forceRefetch))
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
