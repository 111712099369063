

import {connect} from 'react-redux'
import Support from 'components/Support'
import { searchUser, fetchOnLoad, addDays, updateUser, resetFilter, paginate, onUserDeleted } from 'redux/support/actions'

const mapStateToProps = state => ({
  users: state.support.users,
  pagination: state.support.pagination,
  filter: state.support.filter,
  isLoading: state.support.isLoading,
}) 

const mapDispatchToProps = dispatch => ({
  fetchOnLoad: () => dispatch(fetchOnLoad()),
  searchUser: (filter) => dispatch(searchUser(filter)),
  addDays: (ssoId, days) => dispatch(addDays(ssoId, days)),
  updateUser: (ssoId, model) => dispatch(updateUser(ssoId, model)),
  onResetFilter: () => dispatch(resetFilter()),
  paginate: (page) => dispatch(paginate(page)),
  onUserDeleted: (ssoId) => dispatch(onUserDeleted(ssoId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Support)
