import React from 'react';
import { Preloader } from 'react-materialize';
import styles from './Loading.module.css'

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingContainerInner}>
        <Preloader className="init-spinner" style={{ margin: '0 auto' }} />
        <div className={styles.loadingMessage}>Einen Augenblick bitte...</div>
      </div>

    </div>
  )
}

export default Loading;