import React from "react";
import "leaflet";
import "leaflet/dist/leaflet.css";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
import styles from "./GeoDataTab.module.css";
import bbox from "@turf/bbox";

const GeoDataTab = ({ product }) => {
  if (!product.geoData) {
    return (
      <div className={`${styles.container} ${styles.noGeoData}`}>
        Zu diesem Produkt wurde noch kein Blattschnitt hochgeladen
      </div>
    )
  }
  const boundingBox = bbox(product.geoData);

  const bounds = [
    [boundingBox[1], boundingBox[0]],
    [boundingBox[3], boundingBox[2]],
  ];
  return (
    <div className={styles.container}>
      <Map zoom={13} bounds={bounds}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <GeoJSON data={product.geoData} color="#008275" />
      </Map>
    </div>
  );
};

export default GeoDataTab;
