import React from 'react';

export default ({ children }) => {
    return (
        <thead>
            <tr>
                {children}
            </tr> 
        </thead>
        
    )
}