import React from "react";
import "./Login.css";
import { Button, Preloader } from "react-materialize";
import Logo from "./logo.svg";
import AuthApi from "api/AuthApi";

export default class LoginComponent extends React.Component {
  state = {
    email: "",
    password: "",
    isFetching: false,
    errorMessage: "",
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: "",
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        isFetching: true,
        errorMessage: "",
      });
      const result = await AuthApi.login(this.state.email, this.state.password);
      if (result.success) {
        await this.props.onLogin(result);
        this.setState({
          isFetching: false,
          errorMessage: "",
        });
        this.props.history.replace("/");
      } else {
        this.setState({
          isFetching: false,
          errorMessage: "",
        });
      }
    } catch (error) {
      this.setState({
        isFetching: false,
        errorMessage: error.message,
      });
    }
  };

  render() {
    const { isFetching, errorMessage } = this.state;
    return (
      <>
        <div className="loading-app">
          <div className="background"></div>
        </div>
        <div className="login-wrapper">
          <div className="login-container">
            <div className="logo-wrapper">
              <div className="logo-wrapper-inner">
                <div className="center">
                  <img src={Logo} alt="logo" height="28px" width="28px" />
                  <span className="title">CRM</span>
                </div>
              </div>
            </div>
            <form className="login-form" onSubmit={this.onSubmit}>
              <h5>Login</h5>
              <label>E-Mail</label>
              <input
                type="text"
                name="email"
                required
                autoFocus
                value={this.state.email}
                onChange={this.onChange}
              ></input>
              <label>Passwort</label>
              <input
                type="password"
                name="password"
                required
                value={this.state.password}
                onChange={this.onChange}
              ></input>
              {errorMessage && <p className="login-error">{errorMessage}</p>}
              <div className="login-footer">
                <Button type="submit" waves="light" disabled={isFetching}>
                  Anmelden
                </Button>
                {isFetching && (
                  <>
                    <Preloader className="spinner" />
                    <div className="is-fetching">Einen Augenblick bitte...</div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
