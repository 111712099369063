import React from 'react';
import Box from 'components/Core/Box';
import TabContent from 'components/Core/TabContent';
import TextInput from 'react-materialize/lib/TextInput';
import Select from 'react-materialize/lib/Select';
import Row from 'react-materialize/lib/Row';
import Editor from 'components/Core/Editor';
import Button from 'react-materialize/lib/Button';
import { Switch } from 'react-materialize';
import { toast } from 'react-toastify';

const regionToState = (region) => {
    return {
        ...region,
        visible: region.visible && region.visible === '1'
    }
}

class General extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            region: regionToState(props.region)
        }
    }

    onChange = (event) => {
        this.setState({
            region: {
                ...this.state.region,
                [event.currentTarget.name]: event.currentTarget.value
            }
        });
    }

    onChangeEditor = (description) => {
        this.setState({
            region: {
                ...this.state.region,
                description
            }
        });
    }

    onChangeVisibility = (event) => {
        this.setState({
            region: {
                ...this.state.region,
               visible: event.currentTarget.checked
            }
        });
    }

    onSubmit = () => {
        const { region } = this.state;
        const { editMode } = this.props;
        this.props.onSubmit(region.id, {
            name: region.name,
            country_id: region.country_id,
            description: region.description,
            key: region.key,
            visible: region.visible ? '1' : '0'
        })
        .then(() => {
            if (editMode) {
                toast('Die Region wurde aktualisiert', { type: 'success', autoClose: 2000 })
            } else {
                toast('Die Region wurde erstellt', { type: 'success', autoClose: 2000 })
            }
        })
        .catch(err => {
            console.log(err);
            toast(`Beim ${editMode ? 'Aktualisieren' : 'Erstellen'} der Region ist ein Fehler aufgetreten.`, { type: 'error' })
        });
    }

    onCancel = () => {
        if (this.props.editMode) {
            this.setState({
                region: regionToState(this.props.region)
            })
        } else {
            this.props.onClose()
        }
    }


    render() {
        const { region } = this.state;
        const { editMode, countries } = this.props;
        return (
            <TabContent clearMargin={editMode}>
                <Box title="Region" renderActions={() => {
                    return (
                        <div style={{ width: '143'}}>
                            <Switch onLabel="" l={12} name="visible" onChange={this.onChangeVisibility} checked={region.visible} offLabel={region.visible ? 'Veröffentlicht' : 'Unsichtbar'}/>
                        </div>
                    )
                }}>
                    <Row>
                        <TextInput label="Eindeutiger Key" l={3} name="key" value={region.key} autoComplete="new-password" onChange={this.onChange }/>
                    </Row>
                    <Row>
                    
                        <Select value={region.country_id} name="country_id"  l={3} label="Land" placeholder="Bitte auswählen" onChange={this.onChange} disabled={!countries}>
                        {
                            countries && countries.map(country => {
                                return (
                                    <option value={country.country_id} key={country.country_id}>
                                        { country.name }
                                    </option>
                                )   
                            })
                        }
                        </Select>
                        <TextInput label="Name" l={9} name="name" value={region.name} placeholder="Bezeichnung der Region" autoComplete="off" onChange={this.onChange }/>
                    </Row>
                </Box>
                <br />
                <Box title="Beschreibung" styles={{ content: { padding: '0px'}}}>
                    <Editor value={region.description} onChange={this.onChangeEditor }/>
                </Box>
                <br />
                <Button waves="light" onClick={this.onSubmit}>Änderungen speichern</Button>
                &nbsp;&nbsp;
                <Button waves="light" flat onClick={this.onCancel}>{ editMode ? 'Zurücksetzen' : 'Abbrechen'}</Button>
            </TabContent>
        )
    }
}

export default General;
