
import React from 'react'
import Box from 'components/Core/Box';
import { Table, TableHeader, TableHeaderItem, TableRow, TableCell } from 'components/Core/Table';

class SubRegionsTable extends React.Component{

    renderTableRows(){
        const { subRegions, openSubRegion } = this.props;
        if (subRegions && subRegions.length) {
            return subRegions.map(subRegion => (
                <TableRow key={subRegion.id} onClick={() => openSubRegion(subRegion)}>
                    <TableCell>{ subRegion.name }</TableCell>
                    <TableCell>{ subRegion.key }</TableCell>
                    <TableCell className={subRegion.visible === '1' ? 'light-green-text dark text-darken-2' : 'amber-text dark text-darken-2'}>{ subRegion.visible === '1' ? 'Veröffentlicht' : 'Unsichtbar' }</TableCell>
                </TableRow>
            ))
        }

        return (
            <TableRow>
                <TableCell colSpan="6" empty>Dieser Region sind keine Subregionen zugeordnet</TableCell>
            </TableRow>
        )
        
    }

    onAddSubRegion = () => {
        this.props.history.push('/region/new');
    }
    
    
    render() {
        return (
            <Box 
                title="Subregionen" 
                withTable >
                <Table selectable>
                    <TableHeader>
                        <TableHeaderItem>Bezeichnung</TableHeaderItem>
                        <TableHeaderItem width="300px">Key</TableHeaderItem>
                        <TableHeaderItem width="120px">Status</TableHeaderItem>
                    </TableHeader>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>       
                </Table>
            </Box>
        )
    }
}

export default SubRegionsTable;
